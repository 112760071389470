import { Modal, Button, connectModal } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { commentFormSchema } from './comment-modal-form-schema';
import { generateCommentModalForm } from './comment-modal-form';

export function CommentModal({
    onSubmit,
    onClose,
    values,
    option,
}) {
    const [commentFormContent, defaultValues] = generateCommentModalForm({
        comment: values.comment
    })

    const handleSubmit = async (data) => {
        onSubmit(data);
        onClose();
    };

    const closeModal = () => {
        onClose()
    }

    return (
        <Modal
            variant="medium"
            onClose={closeModal}
            actions={
                <div>
                    <Button
                        variant="unstyled"
                        onClick={closeModal}
                        label="Cancel"
                    />
                    <Button
                        variant="primary"
                        label="Submit"
                        type="submit"
                        form="modal-form" // important to target the form inside the modal
                    />
                </div>
            }
        >
            <div>
                <h2>Add comment to your selection</h2>
                
                <div className='bg-primary-lighter font-body-xs padding-2 margin-bottom-3 text-bold return-req-text text-uppercase'>
                    {option.equipmentCode.code}-{option.equipmentCode.title}
                </div>
            </div>

            <FormGenerator
                id="modal-form" // important to give the form an id so that the submit button can target it
                schema={commentFormSchema}
                content={commentFormContent}
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
            />
        </Modal>
    );
}

export default connectModal(CommentModal);