import { gql } from '@apollo/client';
import { sortBy } from 'lodash';
import {
  GET_CONTRACT_RENEWAL_YEAR,
  GET_REQUISITION_SINS_TYPEAHEAD_FOR_YEAR,
  GET_ORDER_GUIDE_FOR_SIN_AND_YEAR,
  GET_CONTRACT_EC_FOR_GUIDE,
  UPDATE_LEASING_AVAILABILITY,
  UPDATE_AFV_BASE,
  UPDATE_LEASING_AVAILABILITY_EC
} from '../services/data-layer';

import {
  addContractRenewalYear,
  setSinListforYear,
  setContractsforSinAndYear,
  setOrderGuideLoading,
  setContractECLoading,
  setContractECs,
  setErrorUpdating
} from '../reducers/ordering-guide';

export function getContractRenewalYears() {
  return (dispatch, getState, { client }) => {
    client.query({ query: GET_CONTRACT_RENEWAL_YEAR }).then((response) => {
      dispatch(
        addContractRenewalYear(
          response.data.getContractRenewalYears?.filter((item) => item.renewalYear)?.map((item) => ({
            label: item.renewalYear.toString(),
            value: item.renewalYear.toString(),
          })),
        ),
      );
    });
  };
}

export function getSinsTypeahead(contractYear) {
  return (dispatch, getState, { client }) => {
    client
      .query({
        query: GET_REQUISITION_SINS_TYPEAHEAD_FOR_YEAR,
        variables: {
          contractYear
        },
      })
      .then((response) => {
        dispatch(
          setSinListforYear(
            response.data.getRequisitionSinsTypeaheadForYear,
          ),
        );
      });
  };
}

export function getContractsForSinAndYear(query = {}) {
  return (dispatch, getState, { client }) => {
    dispatch(setOrderGuideLoading(true));
    client
      .query({
        query: GET_ORDER_GUIDE_FOR_SIN_AND_YEAR,
        fetchPolicy: 'no-cache',
        variables: {
          offset: query.offset,
          limit: query.limit,
          contractYear: query.contractYear,
          sin: query.selectedSIN,
          order: query.orderDirection,
          orderBy: query.orderBy
        },
      })
      .then((response) => {
        dispatch(
          setContractsforSinAndYear({rows: response.data.getContractForSINandYear?.rows, count: response.data.getContractForSINandYear?.count}
            
          ),
        );
        dispatch(setOrderGuideLoading(false));
      });
  };
}

export function getContractLineECbyId(contractLineId, ecCode, orderBy, orderDirection) {
  return (dispatch, getState, { client }) => {
    setContractECs([]);
    dispatch(setContractECLoading(true));
    client
      .query({
        query: GET_CONTRACT_EC_FOR_GUIDE,
        fetchPolicy: 'no-cache',
        variables: {
          contractLineId,
          ecCode,
          orderBy,
          orderDirection
        },
      })
      .then((response) => {
        dispatch(
          setContractECs(response.data.getContractLineECsForGuide),
        );
        dispatch(setContractECLoading(false));
      });
  };
}

export function updateContractLinebyId(variables) {
  return (dispatch, getState, { client }) => {
    client
      .mutate({
        mutation: UPDATE_LEASING_AVAILABILITY,
        variables: {...variables},
      })
      .then((response) => {
        if(response?.data){
          console.log(response?.data)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function updateContractLineAFVbyId(variables) {
  return (dispatch, getState, { client }) => {
    client
      .mutate({
        mutation: UPDATE_AFV_BASE,
        variables: {...variables},
      })
      .then((response) => {
        if(response?.data){
          console.log(response?.data)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function updateContractLineECbyId(variables) {
  return (dispatch, getState, { client }) => {
    client
      .mutate({
        mutation: UPDATE_LEASING_AVAILABILITY_EC,
        variables: {contractLineEcArgs: variables},
      })
      .then((response) => {
        if(response?.data){
          setErrorUpdating(!response?.data?.updateLeasingAvailabilityForEC)
        }
      })
      .catch((error) => {
        setErrorUpdating(true)
      });
  };
}