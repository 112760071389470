import {
    connectModal,
    Modal,
    Button,
    Alert,
    Spinner
} from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useMemo, useRef } from 'react';
import { z } from 'zod';
import AttachmentDownload from '@/pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import { useGetDistinctStandardItemsOptions } from '../../../../../requests/vehicle-referral';

export const OverrideSinModal = connectModal(function OverrideSinModal({
    handleClose,
    handleSubmit,
    selectedFile,
    error,
    saving,
}) {
    const { loading, data: sins } = useGetDistinctStandardItemsOptions();

    const formRef = useRef(null);

    const defaultValues = useMemo(() => ({
        file: selectedFile ? selectedFile : null,
        comment: '',
    }), []);

    const schema = z.object({
        file: z.any().refine(value => {
            if (selectedFile) return true;
            return value instanceof File && value !== null;
        }, 'Please select a file'),
        
        comment: z.string().min(1, { message: 'Please provide comment' }).refine(value => value !== '-1', {
            message: 'Please provide comment',
        }),

        sins: z.coerce.string().refine((value) => value !== '-1', {
            message: 'Please select a SIN',
        }),
      
    })

    const formContent = {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        sections: [
            {
                fields: [
                    {
                        id: 'comment',
                        label: 'Provide comment',
                        type: 'textarea',
                        required: true,
                        element: {
                            control: {
                                maxLength: 500,
                                countdown: true,
                            }
                        }
                    },
                    {
                        id: 'sins',
                        label: 'New SIN',
                        type: 'select',
                        required: true,
                        options: sins,
                      },
                    {
                        id: 'file',
                        label: 'Upload override SIN documentation',
                        type: selectedFile ? 'readonly' : 'file-upload',
                        required: !selectedFile,
                        element: {
                            control: {
                                modifier: (value) => {
                                    return <AttachmentDownload
                                        name={value?.name}
                                        metadataId={value?.metadataId || ''}
                                    />
                                },
                                rawModifierValue: true,
                                acceptableFilesLabel: 'Accepts .doc, .docx, .xls, .xlsx, .pdf, .jpg, .gif, .png',
                                acceptableFiles: [
                                    'application/msword', // doc
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
                                    'application/vnd.ms-excel', // xls
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
                                    'application/pdf',
                                    'image/jpeg',
                                    'image/gif',
                                    'image/png',
                                ],
                                fileSizeLimit: 10, // 10MB
                            }
                        }
                    },
                ],
            },
        ],
    }

    return (
        <Modal
            title={
                <h2 style={{ fontSize: '24px', marginBottom: '30px' }}>
                    Override customer selected SIN
                </h2>
            }
            onClose={handleClose}
            actions={
                <>
                    <Button
                        type="button"
                        variant="unstyled"
                        onClick={handleClose}
                        label="Cancel"
                        disabled={saving}
                    />
                    <Button
                        type="button"
                        variant="default"
                        onClick={() => {
                            formRef.current.submit();
                        }}
                        label='Override SIN'
                        disabled={saving}
                    />
                </>
            }
        >
            {error && <Alert className="margin-bottom-2" type='error'>{error}</Alert>}

            {loading ? <Spinner size='large' /> : (
                <FormGenerator
                    ref={formRef}
                    content={formContent}
                    onSubmit={handleSubmit}
                    onError={console.log}
                    schema={schema}
                    defaultValues={defaultValues}
                    useFormElement={false}
                />
            )}
        </Modal>
    )
});