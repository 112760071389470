import { useMemo } from "react";

export function generateCommentModalForm({
    comment
} = {
    comment: ""
}) {
    const defaultValues = useMemo(() => ({
        comment
    }), []);

    const form = {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        sections: [
            {
                fields: [
                    {
                        id: "comment",
                        type: "textarea",
                        element: {
                            control: {
                                countdown: true,
                                maxLength: 75
                            }
                        }
                    },
                ]
            }
        ]
    }

    return [form, defaultValues];
}