import {
    connectModal,
    Modal,
    Button,
    Alert,
} from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useMemo, useRef } from 'react';
import { z } from 'zod';
import AttachmentDownload from '@/pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

export const UploadDocumentationModal = connectModal(function UploadDocumentationModal({
    handleClose,
    handleSubmit,
    selectedFile,
    error,
    saving,
    attachmentTypes,
}) {
    const formRef = useRef(null);

    const defaultValues = useMemo(() => ({
        file: selectedFile ? selectedFile : null,
        category: selectedFile?.replacementAttachmentTypeId || '-1',
        note: selectedFile?.description || '',
    }), []);

    const schema = z.object({
        file: z.any().refine(value => {
            if (selectedFile) return true;
            return value instanceof File && value !== null;
        }, 'Please select a file'),
        
        category: z.string().min(1, { message: 'Please select a category' }).refine(value => value !== '-1', {
            message: 'Please select a category',
        }),
        note: z.string()
    })

    const formContent = {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        sections: [
            {
                fields: [
                    {
                        id: 'file',
                        label: selectedFile ? 'Uploaded file' : 'Supporting file',
                        type: selectedFile ? 'readonly' : 'file-upload',
                        required: !selectedFile,
                        element: {
                            control: {
                                modifier: (value) => {
                                    return <AttachmentDownload
                                        name={value?.name}
                                        metadataId={value?.metadataId || ''}
                                    />
                                },
                                rawModifierValue: true,
                                acceptableFilesLabel: '.doc, .docx, .xls, .xlsx, .pdf, .jpg, .gif, .png',
                                acceptableFiles: [
                                    'application/msword', // doc
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
                                    'application/vnd.ms-excel', // xls
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
                                    'application/pdf',
                                    'image/jpeg',
                                    'image/gif',
                                    'image/png',
                                ],
                                fileSizeLimit: 10, // 10MB
                            }
                        }
                    },
                    {
                        component: () => (
                            <Alert type='warning' className='margin-bottom-2'>
                                Help prevent a privacy incident by ensuring that any supporting document uploaded here does not contain personally identifiable information (PII).
                            </Alert>
                        )
                    },
                    {
                        id: 'category',
                        label: 'Category',
                        required: true,
                        type: 'select',
                        options: [
                            { value: '-1', label: '-select-' },
                            ...attachmentTypes,
                        ]
                    },
                    {
                        id: 'note',
                        label: 'Note',
                        help: {
                            before: 'A few words to help identify this document',
                        },
                        type: 'textarea',
                        element: {
                            control: {
                                maxLength: 100,
                                countdown: true,
                            }
                        }
                    }
                ],
            },
        ],
    }

    return (
        <Modal
            title={
                <h2 style={{ fontSize: '24px', marginBottom: '30px' }}>
                    {selectedFile ? 'Edit file documentation' : 'Add file documentation'}
                </h2>
            }
            onClose={handleClose}
            actions={
                <>
                    <Button
                        type="button"
                        variant="unstyled"
                        onClick={handleClose}
                        label="Cancel"
                        disabled={saving}
                    />
                    <Button
                        type="button"
                        variant="default"
                        onClick={() => {
                            formRef.current.submit();
                        }}
                        label='Save'
                        disabled={saving}
                    />
                </>
            }
        >
            {error && <Alert className="margin-bottom-2" type='error'>{error}</Alert>}

            <FormGenerator
                ref={formRef}
                content={formContent}
                onSubmit={handleSubmit}
                onError={console.log}
                schema={schema}
                defaultValues={defaultValues}
                useFormElement={false}
            />
        </Modal>
    )
});