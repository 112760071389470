import { z } from 'zod';

export const supplementaryInformationSchema = z
  .object({
    requireMakeModel: z.string(),
    makeModel: z.string(),
    files: z.array(
      z.object({
        name: z.string(),
        metadataId: z.string(),
        replacementAttachmentTypeId: z.coerce
          .number()
          .refine((val) => val > 0, 'Please select a category'),
        description: z.string().nullable(),
      }),
    ),
    specialInstructions: z.string().optional(),
    acknowledge: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.requireMakeModel === 'yes' && data.makeModel === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Make and model is required',
        path: ['makeModel'],
      });
    }

    if (
      data.requireMakeModel === 'yes' &&
      !data.files.some((f) => f.replacementAttachmentTypeId === 2)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'At least one file is required',
        path: ['files'],
      });
    }

    if (data.acknowledge === false) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'You must acknowledge the statement below',
        path: ['acknowledge'],
      });
    }
  });
