import { useState } from 'react';
import { TextInput, RadioButton, Label } from '@gsa/afp-component-library';
import { OPTIONS } from '../../utils/vehicle-referral-util';
import './options-radio-button.scss';

export default function OptionsRadioButton({
  options,
  section,
  defaultOption = null,
  handleOptions,
  handleComment,
  comment,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  let lableTitle = '';
  let lableSubTitle = '';

  const getTitle = (section) => {
    if (section === OPTIONS.TURNIN) {
      lableTitle = 'Provide reason for turn-in';
      lableSubTitle = '';
      return 'Turn in vehicle';
    } else if (section === OPTIONS.REPLACE_VEHICLE) {
      lableTitle = 'Provide reason for replace vehicle';
      lableSubTitle = '';
      return 'Replace vehicle';
    } else {
      lableTitle = 'Provide reason for not replacing vehicle';
      lableSubTitle =
        'The option to retain a vehicle, without replacement, is subject to approval by GSA Fleet and is not guaranteed.';
      return 'Do not replace vehicle';
    }
  };

  return (
    <>
      <div className="optionsContainer">
        <h2 className="title">{getTitle(section)}:</h2>
        <div>{lableSubTitle}</div>
        <Label>{lableTitle} (select one)*</Label>
        <div>
          {options.map((option) => (
            <RadioButton
              key={`${option.value}-button`}
              name={`option-${option.value}`}
              label={option.label}
              value={option.value}
              checked={option.value === selectedOption?.value}
              onChange={() => {
                setSelectedOption(option);
                handleOptions(option);
              }}
            />
          ))}
        </div>

        <div>
          <Label>Provide additional information for the reason selected:</Label>
          <TextInput
            type="textarea"
            maxLength={500}
            errorMessage=""
            defaultValue={comment || ''}
            onBlur={({ target }) => {
              handleComment(target.value);
            }}
          />
          <div>500 characters allowed</div>
        </div>
      </div>
    </>
  );
}
