import { TabSet, Accordion, Spinner, useModal, Alert } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import OptionsPerVehicle from '@/components/ReviewComponents/OptionsPerVehicle/OptionsPerVehicle.jsx';
import PaintAndGraphics from '@/components/ReviewComponents/PaintAndGraphics/PaintAndGraphics.jsx';
import TaggedOptionDetails from '@/components/ReviewComponents/TaggedOptionDetails/TaggedOptionDetails.jsx';
import CostSummary from '@/components/ReviewComponents/CostSummary/CostSummary.jsx';
import VehicleInformation from '../../components/vehicle-information/vehicle-information.jsx';
import React, { useEffect, useState } from 'react';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '@/components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown.jsx';
import { useMutation } from '@apollo/client';
import { CREATE_REQUISITION_COMMENT } from '@/services/data-layer.jsx';
import { useGeneratePDF } from '@/requests/leasing.jsx';
import { useSelector } from 'react-redux';
import CommentModal from '@/components/CommentModal/CommentModal.jsx';
import useLeasingNavigation from '@/hooks/useLeasingNavigation.jsx';
import { LeasingSteps } from '@/pages/leasing/leasing-consts.js';
import LeasingNavigation from '@/pages/leasing/components/navigation.jsx';
import groupOptionsPerCatalog from '@/pages/VehicleRequisition/utils/VehicleRequisitionUtils.jsx';
import { filterOptions } from '@/components/ReviewComponents/ReviewOptions/reviewOptionsUtil.jsx';
import RequisitionLayout from '../../components/requisition-layout/requisition-layout.jsx';
import { selectedPVOptions } from '../../leasing-utils.jsx';
import RequisitionActivitiesTab from '../../../ReviewDetails/tabs/RequisitionActivitiesTab.jsx';
import LeasingAttachmentsTable from './leasing-attachments-tab.jsx';
import ReviewReplacementVehicles from './review-replacement-vehicles.jsx';
import {
  useGetRequisitionAttachments,
  useGetAttachmentTypes
} from '../../../../requests/leasing.jsx';
import ReviewDeliveryAddress from './review-delivery-address.jsx';
import ReviewOrderingInfo from './ordering-information.jsx';
import './review-and-submit.scss';
import { RequisitionViewMode } from '../../leasing-consts.js';
import ReturnRequisitionModal from './return-requisition-modal/return-requisition-modal.jsx';
import SubmitRequisitionModal from './submit-requisition-modal/submit-requisition-modal.jsx';
import { RequisitionStatus } from '../../../ReviewDetails/RequisitionDetailsUtils.jsx';
import useSubmitLeasingRequisition from '../../../../hooks/useSubmitLeasingRequisition.jsx';
import ReqReturnedBanner from "../../../../components/ReqReturnedBanner/ReqReturnedBanner.jsx";
import ReferralReplacementLink from '../../components/referral-replacement-link.jsx';

const ReviewAndSubmit = () => {
  useTitle('Review and Submit - Summary | Leasing');

  const returnRequisitionModal = useModal();
  const submitRequisitionModal = useModal();

  const [tabIdx, setTabIdx] = useState(0);
  const { handleLeasingNavigation } = useLeasingNavigation();
  const [submitRequisitionComment] = useMutation(CREATE_REQUISITION_COMMENT);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  let items = [];

  const {
    selectedStandardItem,
    paintAndGraphics,
    compareSelect,
    requisitionId,
    vehicleQuantity,
    attachmentTypes,
    attachments,
    account,
    billingInformation,
    dealershipDeliveryAddress,
    marshallingAddress,
    viewMode,
    requisitionStatus,
  } = useSelector((state) => state.leasingReducer);

  const requisition = {
      quantity: vehicleQuantity,
      vehicles: []
  }
  const {
    handleLeasingRequisitionSubmit,
    leasingRequisitionReturnMethod,
    leasingRequisitionSubmitMethod,
  } = useSubmitLeasingRequisition({
    submitRequisitionModal,
  });

  const {
    selectedContract,
    calculatedPriceBreakdown,
    addOptions,
    selectedDeliveryOption,
    selectedEngine,
  } = compareSelect;
  const { selectedColors, paintAndGraphicsDescription } = paintAndGraphics;
  const checkAddressType =
    selectedContract?.makeCode &&
    !selectedDeliveryOption[0]?.optionCode?.startsWith('CNS') &&
    selectedDeliveryOption[0]?.optionCode?.startsWith('DDR');
  const addressInfo = {
    ...marshallingAddress,
    ...dealershipDeliveryAddress,
    addressType: checkAddressType ? 'DEALERSHIP' : 'MARSHALLING',
  };

  useGetRequisitionAttachments({ requisitionId });
  useGetAttachmentTypes();

  useEffect(() => {
    if (!addOptions || !selectedContract) return;
    const optionalOptions = { getOptionalOptions: [...addOptions] };

    const groupedPerVehicleOptions = groupOptionsPerCatalog(
      optionalOptions,
      selectedContract.perVehicleOptions || [],
    );

    const myOptions = filterOptions(
      groupedPerVehicleOptions,
      paintAndGraphicsDescription,
      [],
    );

    setOptions(myOptions);
  }, [addOptions, selectedContract]);

  const handlePreviousNavigation = () => {
    handleLeasingNavigation(LeasingSteps.DELIVERY_ADDRESS);
  };
  const handleAddComment = async (comment) => {
    const { appURLs } = window.AFP_CONFIG;
    await submitRequisitionComment({
      variables: {
        requisitionId,
        comment,
        baseUrl: appURLs.store,
      },
    });
    setShowAddCommentModal(false);
  };
  const [getPDFUrl] = useGeneratePDF();
  //TODO: Get Data from state

  const printPDF = () => {
    const firstActivity = {};
    const lastActivity = {};
    let agencyName = '';
    let bureauName = '';
    const reqType = sessionStorage.getItem('requisitionType');
    if (reqType === 'on-behalf') {
      agencyName = sessionStorage.getItem('receivingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('receivingBureau')?.split('-')[1];
    } else {
      agencyName = sessionStorage.getItem('orderingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('orderingBureau')?.split('-')[1];
    }
    const agencyInfo = {
      name: agencyName,
    };
    const bureauInfo = {
      name: bureauName,
    };
    setIsLoading(true);

    getPDFUrl({
      variables: {
        contentModel: 'new_requisition',
        recordId: requisition?.requisitionId,
        friendlyName: 'test',
        sections: 'all',
        vehicleContract: JSON.stringify({
          clarifications:
            selectedContract?.clarifications ?? [],
          offerLineArtifact: selectedContract?.offerLineArtifact ?? [],
          baseEngineRating: selectedContract?.baseEngineRating ?? {},
          vendorName: selectedContract?.vendorName ?? '',
          modelName: selectedContract?.modelName ?? '',
          modelYear: selectedContract?.modelYear ?? '',
          comment: selectedContract?.comment ?? '',
        }),
        requisitionStandardItem: JSON.stringify(selectedStandardItem),
        calculatedPriceInfoState: JSON.stringify(selectedContract),
        selectedContractAgencyInformation: JSON.stringify({}),
        agencyInfo: JSON.stringify(agencyInfo),
        bureauInfo: JSON.stringify(bureauInfo),
        firstActivity: JSON.stringify(firstActivity),
        lastActivity: JSON.stringify(lastActivity),
        perVehicleOptions: JSON.stringify(
          selectedContract?.perVehicleOptions ?? [],
        ),
        selectedColor: '',
        paintAndGraphicsOptions: JSON.stringify(paintAndGraphics),
        taggedOptions: JSON.stringify([]),
        requisitionAddress: JSON.stringify({}),
        mailingAddress: JSON.stringify({}),
        deliveryAddress: JSON.stringify({}),
        nonLowBidJustification: JSON.stringify({}),
        areqList: JSON.stringify([]),
      },
    }).then((response) => {
      waitingPDFLoop(response.data.signedUrl);
    });
  };
  const waitingPDFLoop = (url) => {
    const maxTry = 15; // 30 seconds wait in total
    const timer = setTimeout(() => {
      const httpReq = new XMLHttpRequest();
      httpReq.open('GET', url, true);
      httpReq.send();
      httpReq.onload = (response) => {
        if (response?.target && response?.target?.status === 200) {
          // open the PDF
          window.open(url, '_blank');
          setIsLoading(false);
        } else if (
          response?.target &&
          response?.target?.status !== 200 &&
          waitingPDFLoop.tryCount < maxTry
        ) {
          waitingPDFLoop.tryCount += 1;
          waitingPDFLoop(url);
          setIsLoading(false);
        } else {
          // throw error
          setIsLoading(false);
        }
      };
      httpReq.onerror = () => {
        setIsLoading(false);
      };
      clearTimeout(timer);
    }, 3000);
  };
  waitingPDFLoop.tryCount = 0;

  const editPage = (step) => {
    handleLeasingNavigation(step, null, true);
  };

  items.push({
    id: 'Replacement vehicles',
    title: 'Replacement vehicles',
    content: <ReviewReplacementVehicles onEdit={editPage} />,
    expanded: true,
  });
  items.push({
    id: 'Options per vehicle',
    title: 'Options per vehicle',
    content: (
      <OptionsPerVehicle
        selectedContractCostBreakdown={selectedContract}
        isEditable={viewMode === RequisitionViewMode.EDIT || viewMode === RequisitionViewMode.REVIEW_EDIT}
        options={options}
        onEdit={editPage}
        transactionType={'VEHICLE_LEASE'}
      />
    ),
    expanded: true,
  });
  items.push({
    id: 'Paint and graphics',
    title: 'Paint and graphics',
    content: (
      <PaintAndGraphics
        vehicleColors={
          !paintAndGraphicsDescription?.length ? selectedColors : []
        }
        taggedOptions={[]}
        paintAndGraphicsOptions={paintAndGraphicsDescription}
        requisition={requisition}
        isEditable={viewMode === RequisitionViewMode.EDIT || viewMode === RequisitionViewMode.REVIEW_EDIT}
        requisitionAttachmentTypes={attachmentTypes}
        onEdit={editPage}
        transactionType={'VEHICLE_LEASE'}
        attachments={attachments}
      />
    ),
    expanded: true,
  });
  items.push({
    id: 'Options details',
    title: 'Options details',
    content: (
      <TaggedOptionDetails
        taggedOptions={[]}
        draftRequisition={requisition}
        isEditable={viewMode === RequisitionViewMode.EDIT || viewMode === RequisitionViewMode.REVIEW_EDIT}
        requisitionAttachmentTypes={attachmentTypes}
        onEdit={editPage}
      />
    ),
    expanded: true,
  });
  items.push({
    id: 'Cost summary',
    title: 'Cost summary',
    content: (
      <CostSummary
        calculatedPriceData={selectedContract}
        isEditable={viewMode === RequisitionViewMode.EDIT || viewMode === RequisitionViewMode.REVIEW_EDIT}
        requisition={requisition}
        isAreq={false}
        selectedContractCostBreakdown={selectedContract}
        transactionType={'VEHICLE_LEASE'}
        onEdit={editPage}
      />
    ),
    expanded: true,
  });
  items.push({
    id: 'Ordering Information',
    title: 'Ordering Information',
    content: (
      <ReviewOrderingInfo account={{ ...account, ...billingInformation }} />
    ),
    expanded: true,
  });
  items.push({
    id: 'Delivery address',
    title: 'Delivery address',
    content: (
      <ReviewDeliveryAddress
        addressInfo={addressInfo}
        isEditable={viewMode === RequisitionViewMode.EDIT || viewMode === RequisitionViewMode.REVIEW_EDIT}
        onEdit={editPage}
      />
    ),
    expanded: true,
  });

  const [showingFundingError, showFundingError] = useState(false);
  const handleSubmit = (...args) => {
    showFundingError(false);
    handleLeasingRequisitionSubmit(...args).catch(({ error }) => {
      if (error.name === 'SubmitRequisitionFundingError') {
        showFundingError(true);
      }
    })
  }

  const renderReviewContent = () => {
    return (
      <>
        {isLoading && (
          <div className="afp-modal-overlay requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        )}

        {showingFundingError && (
          <Alert type='error' heading='Funding cannot be verified' focused={true}>
            The required funding cannot be verified.  Please contact your Zonal Acquisition Coordinator and the Ordering Management Branch to obtain additional information.
          </Alert>
        )}

        {requisitionStatus === RequisitionStatus.RETURNED && (
          <ReqReturnedBanner requisitionId={requisitionId} />
        )}
        <div
          tabIndex="0"
          role="tab"
          className="margin-bottom-205 review-vehicle-build-title"
        >
          <h2 className="usa-h2">Review requisition summary and submit</h2>
          <p>
            Please review the following vehicle build details and proceed to the
            next step.
          </p>
        </div>
        <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
          <ReferralReplacementLink />
          <span style={{flex: 1}}></span>
          <RequisitionActionsButtonDropdown
            className="requisition-action-button"
            menuItems={{
            //Disable print button - AFP-148390
           /* [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
                onClick: () => {
                  printPDF();
                },
              },*/
              [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
                onClick: () => {
                  setShowAddCommentModal(true);
                },
              },
            }}
          />
        </div>

        <div className="review-detail">
          <Accordion items={items} />
        </div>
        <div className="top-padding">
          <LeasingNavigation
            onSubmit={handleSubmit}
            onPrevious={handlePreviousNavigation}
            onReject={() => returnRequisitionModal.openModal()}
            hide={[
              viewMode !== RequisitionViewMode.EDIT && 'previous',
              (
                viewMode === RequisitionViewMode.READONLY ||
                requisitionStatus === RequisitionStatus.DRAFT ||
                requisitionStatus === RequisitionStatus.RETURNED
              ) && 'reject',
              viewMode === RequisitionViewMode.READONLY && 'submit',
              (viewMode !== RequisitionViewMode.EDIT && requisitionStatus !== RequisitionStatus.RETURNED) && 'cancel',
              'next',
            ]}
          />
        </div>

        <ReturnRequisitionModal
          isOpen={returnRequisitionModal.isOpen}
          onClose={returnRequisitionModal.closeModal}
          method={leasingRequisitionReturnMethod}
        />

        <SubmitRequisitionModal
          isOpen={submitRequisitionModal.isOpen}
          onClose={submitRequisitionModal.closeModal}
          method={leasingRequisitionSubmitMethod}
        />

        <CommentModal
          data-testid="add-comment-modal-urg-req"
          isOpen={showAddCommentModal}
          onSubmit={handleAddComment}
          onCancel={() => setShowAddCommentModal(false)}
          title="Post a comment"
          body="Your comment will be added to this timeline and sent to your GSA service rep."
          isRequired
        />
      </>
    );
  };

  return (
    <>
      <TabSet
        tabs={[
          {
            heading: 'Review summary',
            tabSelectedWhenOpen: tabIdx === 0,
            content: null,
          },
          {
            heading: 'Activity',
            tabSelectedWhenOpen: tabIdx === 1,
            content: null,
          },
          {
            heading: 'Attachments',
            tabSelectedWhenOpen: tabIdx === 2,
            content: null,
          },
        ]}
        onSelect={(idx) => {
          setTabIdx(idx);
        }}
      />
      <RequisitionLayout
        left={
          <VehicleInformation
            contract={selectedContract}
            options={addOptions}
            quantity={vehicleQuantity}
            standardItem={selectedStandardItem}
            selectedContractCostBreakdown={calculatedPriceBreakdown?.find(modelCost => modelCost.contractLineId === selectedContract?.contractLineId)}
            engine={selectedEngine}
          />
        }
        right={
          <>
            {tabIdx === 0 && renderReviewContent()}
            {tabIdx === 1 && (
              <RequisitionActivitiesTab requisitionId={requisitionId} />
            )}
            {tabIdx === 2 && <LeasingAttachmentsTable />}
          </>
        }
      />
    </>
  );
};
export default ReviewAndSubmit;
