import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { isEqual, sortBy } from 'lodash';
import { FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_REQUISITIONS_STATUSES } from '../queries';
import { GET_ACTIVE_VEHICLE_GROUPS } from '../../../services/data-layer/standards';
import {
  selectedAgenciesAtom,
  selectedBureauAtom,
  selectedOfficesAtom,
  selectedRequisitionStatusAtom,
  selectedVehicleTypesAtom,
  selectedRequisitionTypeAtom,
  selectedRequisitionTransactionTypeAtom,
  requisitionNumberAtom, closeOutDateAtom,
  selectedLeasingAgenciesAtom,
  selectedLeasingBureauAtom,
  selectedLeasingOfficesAtom,
  selectedZoneAtom,
  selectedFMCAtom,
  selectedFSRAtom,
  selectedBOACAtom,
} from '../storeHelper';
import AgencyFilterItem from './AgencyFilterItem';
import BureauFilterItem from './BureauFilterItem';
import OfficeFilterItem from './OfficeFilterItem';
import AssigneeFilterItem from './AssigneeFilterItem';
import {
  AGENCY_AND_BUREAU_AND_OFFICE,
  AGENCY_FILTER,
  BUREAU_FILTER,
  OFFICE_FILTER,
} from './filter-item-constants';
import { requisitionTypeDescriptor } from '../../non-standard-purchase/constants';
import { VEHICLE_GROUP_TAG_STORE } from '../../../constants/constants';
import {
  formatRequisitionStatusLabel,
  formatRequisitionTypeLabel,
} from '../../my-orders/Filters/OrderFilterHelpers';
import {
  FMC,
  LEASING_AGENCY_BUREAU_AND_OFFICE, LEASING_AGENCY_FILTER, LEASING_BUREAU_FILTER, LEASING_OFFICE_FILTER,
  ZONE,
  ZONE_AND_FMC
} from "../../my-orders/Filters/filter-item-constants.jsx";
import ZonesFilterItem from "../../my-orders/Filters/ZonesFilterItem.jsx";
import FMCFilterItem from "../../my-orders/Filters/FMCFilterItem.jsx";
import LeasingAgencyFilterItem from "../../my-orders/Filters/LeasingAgencyFilterItem.jsx";
import LeasingBureauFilterItem from "../../my-orders/Filters/LeasingBureauFilterItem.jsx";
import LeasingOfficeFilterItem from "../../my-orders/Filters/LeasingOfficeFilterItem.jsx";
import {UserTypes} from "../../../constants/index.jsx";
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';

const { FilterPanel: ComponentFilterPanel } = FilterPanel;

const MyRequisitionsFilters = () => {
  const { currentUser } = useCurrentUser();
  const internalUser = currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE;
  const RequisitionStatusOption = {
    key: 'requisitionStatusFilter',
    title: 'Requisition status',
  };
  const VehicleTypeOption = {
    label: 'title',
    value: 'code',
    key: 'vehicleTypeFilter',
    title: 'Vehicle type',
  };
  const AgencyOption = {
    label: 'name',
    value: 'id',
    key: AGENCY_FILTER,
    title: 'Agency Item',
  };
  const BureausOption = {
    label: 'name',
    value: 'id',
    key: BUREAU_FILTER,
    title: 'Bureau Item',
  };
  const OfficesOption = {
    label: 'officeName',
    value: '',
    key: OFFICE_FILTER,
    title: 'Group Item',
  };
  const RequisitionTypeOption = {
    key: 'requisitionTypeFilter',
    title: 'Requisition type',
  };
  const AssigneeOption = {
    key: 'assigneeTypeFilter',
    title: 'Assignee',
  };
  const RequisitiionTransactionTypeOption = {
    key: 'requisitionTransactionTypeFilter',
    title: 'Transaction type',
  };
  const CloseoutDate = {
    key: 'order_end_date',
    title: 'Closeout date',
  };


  const [getData, { data: vehicleTypesData }] = useLazyQuery(
    GET_ACTIVE_VEHICLE_GROUPS,
    {
      fetchPolicy: 'c',
      variables: {
        childTag: VEHICLE_GROUP_TAG_STORE,
      },
    },
  );

  const [getRequisitionStatues, { data: requisitionsStatusData }] =
    useLazyQuery(GET_REQUISITIONS_STATUSES, {
      fetchPolicy: 'c',
    });

  // filters
  const [allRequisitionStatuses, setAllRequisitionStatuses] = useState([]);
  const [allVehicleTypes, setAllVehicleTypes] = useState([]);
  const [allRequisitionTypes, setAllRequisitionTypes] = useState([]);

  const purchasingFilters = [
    {
      key: 'requisitionNumber',
      title: 'Requisition number',
      type: 'text',
      operator: '$like',
      value: '',
    },
    {
      key: RequisitionStatusOption.key,
      title: RequisitionStatusOption.title,
      type: 'multiselect',
      value: [],
      options: allRequisitionStatuses.map((status) => ({
        label: formatRequisitionStatusLabel(status),
        value: status,
      })),
      operator: '$in',
      hideClear: true,
    },
    {
      key: RequisitionTypeOption.key,
      title: RequisitionTypeOption.title,
      type: 'multiselect',
      value: [],
      options: allRequisitionTypes.map((type) => ({
        label: formatRequisitionTypeLabel(type[1]),
        value: type[0],
      })),
      operator: '$in',
      hideClear: true,
    },
    {
      key: RequisitiionTransactionTypeOption.key,
      title: RequisitiionTransactionTypeOption.title,
      type: 'multiselect',
      value: [],
      options: [
        { label: 'Purchase', value: 'VEHICLE_SALE' },
        { label: 'Lease', value: 'VEHICLE_LEASE' },
      ],
      operator: '$in',
      hideClear: true,
    },
    {
      key: AssigneeOption.key,
      title: AssigneeOption.title,
      type: 'multiselect',
      value: [],
      component: AssigneeFilterItem,
      operator: '$in',
      hideClear: true,
    },
    {
      key: VehicleTypeOption.key,
      title: VehicleTypeOption.title,
      type: 'multiselect',
      value: [],
      options: allVehicleTypes.map((vehicleType) => ({
        label: vehicleType[VehicleTypeOption.label],
        value: vehicleType[VehicleTypeOption.value],
      })),
      operator: '$in',
      hideClear: true,
    },
    {
      title: 'Agency',
      key: AGENCY_AND_BUREAU_AND_OFFICE,
      filters: [
        {
          key: AgencyOption.key,
          title: AgencyOption.title,
          component: AgencyFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
        },
        {
          key: BureausOption.key,
          title: BureausOption.title,
          component: BureauFilterItem,
          permanent: false,
          operator: '$in',
          placeholder: '- Select bureau -',
        },
        {
          key: OfficesOption.key,
          title: OfficesOption.title,
          component: OfficeFilterItem,
          permanent: false,
          operator: '$in',
          value: '',
          placeholder: '- Select group -',
        }
      ],
    },
    {
      title: CloseoutDate.title,
      key: 'orderEndDate',
      id: 'filter-order-by-date',
      type: 'daterange',
      operator: '$between',
      options: {
        startDate: {
          key: 'beginning',
          label: 'Start Date',
          hint: 'mm/dd/yyyy',
          maxDate: new Date('12/31/9999').toISOString(),
        },
        endDate: {
          key: 'end',
          label: 'End Date',
          hint: 'mm/dd/yyyy',
          maxDate: new Date('12/31/9999').toISOString(),
        },
      },
      value: {
        beginning: '',
        end: '',
      },
      hideClear: true,
    }
  ];

  const leasingFilters = [
    {
      title: 'Leasing Details',
      type: 'label',
    },
    {
      title: 'Zone and FMC',
      key: ZONE_AND_FMC,
      filters: [
        {
          key: ZONE,
          label: 'Zone',
          component: ZonesFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
          placeholder: '- Select -',
        },
        {
          key: FMC,
          label: 'FMC Name',
          component: FMCFilterItem,
          permanent: false,
          operator: '$exact',
          placeholder: '- Select -',
        },
      ],
    },
    {
      title: 'FSR',
      key: 'fsr_user_email_address',
      type: 'text',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 4,
      showSearchButton: true,
      permanent: false,
      position: 'top',
    },
    {
      title: 'Customer BOAC',
      key: 'boac',
      type: 'text',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 4,
      showSearchButton: true,
      permanent: false,
      position: 'top',
    },
    {
      title: 'Leasing Organization',
      key: LEASING_AGENCY_BUREAU_AND_OFFICE,
      filters: [
        {
          key: LEASING_AGENCY_FILTER,
          title: 'Leasing Agency',
          component: LeasingAgencyFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
          placeholder: '- Select agency -',
        },
        {
          key: LEASING_BUREAU_FILTER,
          title: 'Leasing bureau',
          component: LeasingBureauFilterItem,
          permanent: false,
          operator: '$in',
          placeholder: '- Select bureau -',
        },
        {
          key: LEASING_OFFICE_FILTER,
          title: 'Leasing office',
          component: LeasingOfficeFilterItem,
          permanent: false,
          operator: '$in',
          value: '',
          placeholder: '- Select group -',
        },
      ],
    },
  ];

  // update requisitions statuses
  useEffect(() => {
    if (!requisitionsStatusData) {
      return;
    }
    const newStatuses = requisitionsStatusData?.getRequisitionStatuses || [];

    setAllRequisitionStatuses(newStatuses);
  }, [requisitionsStatusData]);

  // update vehicle types
  useEffect(() => {
    const vehicleTypes =
      (vehicleTypesData && vehicleTypesData.getActiveVehicleGroups) || [];
    if (vehicleTypes) {
      sessionStorage.setItem('VEHICLE_TYPE_MAP', JSON.stringify(vehicleTypes));
    } else {
      return;
    }

    // const newVehicleTypes =
    //   vehicleTypes?.searchStandardItems?.filterValues
    //     ?.standardItemSubCategories || [];
    const sortedVehicleTypes = [...vehicleTypes];

    sortedVehicleTypes.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title < b.title) {
        return 1;
      }
      return 0;
    });

    setAllVehicleTypes(sortedVehicleTypes);
  }, [vehicleTypesData?.getActiveVehicleGroups]);

  // initial fetching...
  useEffect(() => {
    setAllRequisitionTypes(
      Object.entries(requisitionTypeDescriptor).filter((x) => x[0] !== '32'),
    );
    getData({
      variables: {
        childTag: VEHICLE_GROUP_TAG_STORE,
      },
    });
    getRequisitionStatues();
  }, []);

  const [requisitionStatuses, setRequisitionStatuses] = useRecoilState(
    selectedRequisitionStatusAtom,
  );

  const [requisitionTypes, setRequisitionTypes] = useRecoilState(
    selectedRequisitionTypeAtom,
  );
  const [requisitionTransactionTypes, setRequisitionTransactionType] =
    useRecoilState(selectedRequisitionTransactionTypeAtom);

  const [vehicleTypes, setVehicleTypes] = useRecoilState(
    selectedVehicleTypesAtom,
  );
  const [agencies, setAgencies] = useRecoilState(selectedAgenciesAtom);
  const [bureaus, setBureaus] = useRecoilState(selectedBureauAtom);
  const [offices, setOffices] = useRecoilState(selectedOfficesAtom);
  const [leasingAgencies, setLeasingAgencies] = useRecoilState(selectedLeasingAgenciesAtom);
  const [leasingBureaus, setLeasingBureaus] = useRecoilState(selectedLeasingBureauAtom);
  const [leasingOffices, setLeasingOffices] = useRecoilState(selectedLeasingOfficesAtom);
  const [requisitionNumber, setRequisitionNumber] = useRecoilState(
    requisitionNumberAtom,
  );
  const [zone, setZone] = useRecoilState(
    selectedZoneAtom,
  );
  const [fmc, setFmc] = useRecoilState(
    selectedFMCAtom,
  );
  const [fsr, setFsr] = useRecoilState(
    selectedFSRAtom,
  );
  const [boac, setBoac] = useRecoilState(
    selectedBOACAtom,
  );

  const [closeOutDate, setCloseOutDate] = useRecoilState(closeOutDateAtom);

  const handleVehicleTypeFilterChange = (values) => {
    const { value } = VehicleTypeOption;
    const newVehicleTypes = allVehicleTypes.filter((item) =>
      values.includes(item[value]),
    );
    setVehicleTypes(newVehicleTypes);
  };

  const handleRequisitionFilterChange = (values) => {
    const newStatuses = allRequisitionStatuses.filter((item) =>
      values.includes(item),
    );
    if (newStatuses) {
      //   This is added since order creation was using RECEIVED_BY_GSA status and changing to ORDER_RECEIVED. ( The cron job can be changed to use a flag later)
      if (newStatuses.includes('RECEIVED_BY_GSA')) {
        newStatuses.push('ORDER_RECEIVED');
      }
    }
    setRequisitionStatuses(newStatuses);
  };

  const handleRequisitionTypeFilterChange = (values) => {
    const newTypes = Object.values(allRequisitionTypes)
      .map((x) => x[0])
      .filter((item) => values.includes(item));
    setRequisitionTypes(newTypes);
  };

  const handleRequisitiionTransactionTypeFilterChange = (filters) => {
    const requisitionTransactionTypeFilter =
      filters.find(
        (filter) => filter.key === RequisitiionTransactionTypeOption.key,
      )?.value || [];

    if (
      requisitionTransactionTypes?.length !==
      requisitionTransactionTypeFilter?.length
    ) {
      setRequisitionTransactionType([...requisitionTransactionTypeFilter]);
      return;
    }

    for (let i = 0; i < requisitionTransactionTypeFilter.length; i++) {
      if (
        requisitionTransactionTypeFilter[i] === requisitionTransactionTypes[i]
      ) {
        setRequisitionTransactionType([...requisitionTransactionTypeFilter]);
        return;
      }
    }
  };

  const handleFiltersChange = (filters) => {
    const vehicleTypeFilter =
      filters.find((filter) => filter.key === VehicleTypeOption.key)?.value ||
      [];
    const requisitionFilter =
      filters.find((filter) => filter.key === RequisitionStatusOption.key)
        ?.value || [];

    if (vehicleTypes.length !== vehicleTypeFilter.length) {
      handleVehicleTypeFilterChange(vehicleTypeFilter);
    }
    if (requisitionStatuses.length !== requisitionFilter.length) {
      handleRequisitionFilterChange(requisitionFilter);
    }

    // Requistion type filter
    const requisitionTypeFilter =
      filters.find((filter) => filter.key === RequisitionTypeOption.key)
        ?.value || [];

    if (requisitionTypes.length !== requisitionTypeFilter.length) {
      handleRequisitionTypeFilterChange(requisitionTypeFilter);
    }

    handleRequisitiionTransactionTypeFilterChange(filters);

    const requisitionNumberFilter =
      filters.find((filter) => filter.key === 'requisitionNumber')?.value || '';
    if (requisitionNumber !== requisitionNumberFilter) {
      setRequisitionNumber(requisitionNumberFilter);
    }

    // Agency Filter
    const agencyFilters = filters.filter(
      (filter) => filter.key === AgencyOption.key,
    );

    if (!isEqual(sortBy(agencies, 'value'), sortBy(agencyFilters, 'value'))) {
      setAgencies(agencyFilters);
    }

    // Bureau Filter
    const bureauFilters = filters.filter(
      (filter) => filter.key === BureausOption.key,
    );

    if (!isEqual(sortBy(bureaus, 'value'), sortBy(bureauFilters, 'value'))) {
      setBureaus(bureauFilters);
    }

    // Office Filter
    const officeFilters = filters.filter(
      (filter) => filter.key === OfficesOption.key,
    );

    if (!isEqual(sortBy(offices, 'value'), sortBy(officeFilters, 'value'))) {
      setOffices(officeFilters);
    }
    console.log('filters on requisition', filters);

    // CloseOutDate Filter
    const closeOutDateFilters = filters.filter((filter) => filter.key === 'orderEndDate');
    if (closeOutDateFilters && !isEqual(closeOutDate, closeOutDateFilters)) {
      setCloseOutDate(closeOutDateFilters[0]);
    }

    // Leasing Filters

    const zoneFilter =
    filters.find((filter) => filter.key === ZONE)?.value || '';
    if (zone !== zoneFilter) {
      setZone(zoneFilter);
    }

    const fmcFilter =
    filters.find((filter) => filter.key === FMC)?.value || '';
    if (fmc !== fmcFilter) {
      setFmc(fmcFilter);
    }

    const fsrFilter =
    filters.find((filter) => filter.key === 'fsr_user_email_address')?.value || '';
    if (fsr !== fsrFilter) {
      setFsr(fsrFilter);
    }

    const boacFilter =
    filters.find((filter) => filter.key === 'boac')?.value || '';
    if (boac !== boacFilter) {
      setBoac(boacFilter);
    }
    const leasingAgencyFilters = filters.filter(
      (filter) => filter.key === LEASING_AGENCY_FILTER,
    );
    if (!isEqual(sortBy(leasingAgencies, 'value'), sortBy(leasingAgencyFilters, 'value'))) {
      setLeasingAgencies(leasingAgencyFilters);
    }

    const leasingBureauFilters = filters.filter(
      (filter) => filter.key === LEASING_BUREAU_FILTER,
    );
    if (!isEqual(sortBy(leasingBureaus, 'value'), sortBy(leasingBureauFilters, 'value'))) {
      setLeasingBureaus(leasingBureauFilters);
    }

    const leasingOfficeFilters = filters.filter(
      (filter) => filter.key === LEASING_OFFICE_FILTER,
    );
    if (!isEqual(sortBy(leasingOffices, 'value'), sortBy(leasingOfficeFilters, 'value'))) {
      setLeasingOffices(leasingOfficeFilters);
    }
  };

  return (
    <ComponentFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={internalUser ? [...purchasingFilters, ...leasingFilters] : purchasingFilters}
      model="Task"
      order={[]}
      setQueryFiltersState={(filters) => handleFiltersChange(filters)}
      showClearIcon
    />
  );
};

export default MyRequisitionsFilters;
