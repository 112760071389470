import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { REQUISITION_TYPE } from '../../constants';
import AddressReview from '../../../../components/ReviewComponents/AddressReview/AddressReview';

const DeliveryInformationReview = ({
  title,
  editPage,
  requisitionAddress,
  mailingAddress,
  deliveryAddress,
  deliveryDate,
  specialDeliveryInstructions,
  isEditable,
  reqType
}) => {
  return (
    <div role="tab" tabIndex="0" className="delivery-info-section">
      <div className="section-main-title">
        {title}
        {isEditable && (
          <Button
            variant="outline"
            onClick={() => {
              if( reqType === REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES) {
                editPage(3);
              }
              if(reqType === REQUISITION_TYPE.URGENT_REQUIREMENT){
                editPage(4);
              }
            }}
            data-testid="delivery-info-edit-section"
            leftIcon={{ name: 'edit' }}
            label="Edit"
            size="small"
          />
        )}
      </div>
      {requisitionAddress && (
        <AddressReview
          title="Requisitioning Address"
          addressData={requisitionAddress}
        />
      )}
      {mailingAddress && (
        <AddressReview title="Mailing Address" addressData={mailingAddress} />
      )}
      {deliveryAddress && (
        <AddressReview title="Delivery Address" addressData={deliveryAddress} />
      )}
      <div className="delivery-instruction-section">
        <div className="title">Special delivery instructions:</div>
        <div className="title-desc">{specialDeliveryInstructions}</div>
        <div className="title top-padding-20">Delivery date</div>
        <div className="title-desc">{deliveryDate}</div>
      </div>
    </div>
  );
};

DeliveryInformationReview.propTypes = {
  title: PropTypes.string.isRequired,
  editPage: PropTypes.func.isRequired,
  requisitionAddress: PropTypes.objectOf(PropTypes.string).isRequired,
  mailingAddress: PropTypes.objectOf(PropTypes.string).isRequired,
  deliveryAddress: PropTypes.objectOf(PropTypes.string).isRequired,
  deliveryDate: PropTypes.string.isRequired,
  specialDeliveryInstructions: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  reqType: PropTypes.number.isRequired
};

export default DeliveryInformationReview;
