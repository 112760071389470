export default function ReadonlyOption({ option }) {
    return (
        <div className='readonly-option'>
            <div className='option-name'>
                {option.option.equipmentCode.code}-{option.option.equipmentCode.title}
            </div>
            
            <div className='option-comment'>
                {option.comment}
            </div>
        </div>
    )
}