/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Spinner,
  PageTitle,
  Button,
  FilterTableFrame,
  TextInput,
  Modal,
  Alert
} from '@gsa/afp-component-library';
import { useDispatch, useSelector } from 'react-redux';
import useOrderGuideECState from './useOrderGuideECState';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import ContractECFilters from './filters/ContractECFilters';
import {
  getECTableColumns,
  CONTRACT_ECS_TABLE_COLUMN_HEADERS,
} from './tableHelpers';
import {
  getContractLineECbyId,
  updateContractLineECbyId,
  updateContractLineAFVbyId,
} from '../../requests/ordering-guide';
import BreadcrumbsEC from './widgets/BreadcrumbsEC';
import { OrderingGuideSteps } from './constants';
import { navigateToContractDetails, setShowSuccessMessage,setUpdatedContractLineECs } from '../../reducers/ordering-guide';
import { useContractECFilter } from './ContractECFilterProvider';
import './OrderingGuideFilter.scss';
import { UserRoles } from '../../constants';

export const getStandardItemLabel = (item) => {
  return { label: item.standardItemNumber, value: item.standardItemNumber };
};
const ContractECtable = () => {
  const {
    orderBy,
    orderDirection,
    setOrderDirection,
    setOrderBy,
    paginationState,
    setPaginationState,
  } = useOrderGuideECState();

  const dispatch = useDispatch();

  const {
    contractLineECs,
    contractECLoading,
    selectedContractLine,
    currentStep,
  } = useSelector((state) => state.orderingGuideReducer);
  const { filters } = useContractECFilter();
  useEffect(() => {
    if (
      selectedContractLine &&
      currentStep === OrderingGuideSteps.CONTRACT_ECS
    ) {
      const { conditions } = filters;
      const ecCode =
        conditions && conditions[0]?.value ? `${conditions[0]?.value}%` : '';

      dispatch(
        getContractLineECbyId(
          parseInt(selectedContractLine?.contractLineId, 10),
          ecCode,
          orderBy,
          orderDirection,
        ),
      );
    }
  }, [selectedContractLine, currentStep, filters, orderDirection, orderBy]);

  const data = useMemo(() => {
    if (!contractLineECs || !contractLineECs.length) {
      return [];
    }
    return contractLineECs.map((row) => getECTableColumns(row));
  }, [contractLineECs]);

  const columns = useMemo(() => CONTRACT_ECS_TABLE_COLUMN_HEADERS, []);
  return (
    <div className="ordering-guide-table">
      <div className="table-main-container">
        <AFPTable
          testId="orrderguide-table"
          columns={columns}
          data={contractECLoading ? [] : data}
          onSort={(sortOrder) => {
            const [newOrderBy, newOrder] = sortOrder.split(' ');
            setOrderBy(newOrderBy.replace(/`/g, ''));
            setOrderDirection(newOrder);
          }}
          defaultSort="ecCode ASC"
        />
        {contractECLoading && <Spinner className="margin-y-9" />}
        {!contractLineECs?.length && !contractECLoading && (
          <EmptyState
            data-testid="table-no-items"
            containerStyles="table-no-items"
            topTextStyles="title"
            bottomText="Select Different Contract on previous screen"
            bottomTextStyles="description"
          />
        )}
      </div>
    </div>
  );
};
const ContractECDetails = () => {
  const {
    selectedContractLine,
    currentStep,
    selectedSINValue,
    selectedSINTitle,
    updatedContractECs,
  } = useSelector((state) => state.orderingGuideReducer);
  const [showActionModal, showActionModalTo] = useState(false);
  const [selectValuesToUpdate, selectValuesToUpdateTo] = useState(false);
  const [updateFilter, updateFilterTo] = useState(false)
  const { currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const [afpBaseValue, afpBaseValueTo] = useState(
    selectedContractLine?.afvBase || 0,
  );

  useEffect(() => {
    updateFilterTo(true)
  })
  const handlePageNavigation = (direction) => {
    if (direction === 'previous') {
      if (updatedContractECs?.length) {
        showActionModalTo(true);
      } else {
        dispatch(
          navigateToContractDetails({
            currentStep: OrderingGuideSteps.CONTRACT_LIST,
            selectedContractLine: null,
          }),
        );
      }
    }

    if (direction === 'continue') {
      if (
        (afpBaseValue &&
          parseInt(afpBaseValue, 10) !=
            parseInt(selectedContractLine?.afvBase, 10)) ||
        updatedContractECs?.length
      ) {
        selectValuesToUpdateTo(false);
        if (
          afpBaseValue &&
          parseInt(afpBaseValue, 10) !=
            parseInt(selectedContractLine?.afvBase, 10)
        ) {
          dispatch(
            updateContractLineAFVbyId({
              contractLineId: +selectedContractLine?.contractLineId,
              afvBase: parseInt(afpBaseValue, 10),
            }),
          );
        }
        if (updatedContractECs?.length) {
          dispatch(
            updateContractLineECbyId(
              updatedContractECs?.map((ec) => {
                return {
                  id: ec?.contractLineECId,
                  afvByEquipment: parseInt(ec?.afvByEquipment, 10),
                  isLeaseAvailable: ec?.isLeaseAvailable,
                };
              }),
            ),
          );
        }
        dispatch(
          navigateToContractDetails({
            currentStep: OrderingGuideSteps.CONTRACT_LIST,
            selectedContractLine: null,
          }),
        );
        dispatch(setShowSuccessMessage(true))
        dispatch(setUpdatedContractLineECs({
          updatedContractECs: []
        }));
      } else {
        selectValuesToUpdateTo(true);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  const ContractECDetailsFrame = useMemo(
    () => FilterTableFrame(null, null, ContractECFilters, ContractECtable),
    [updateFilter],
  );

  const updateAFV = (value) => {
    afpBaseValueTo(value);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    // Allow empty input
    if (value === "") {
      updateAFV(value);
      return;
    }
    
    // Check if the input is a valid positive number (int or float)
    const isValid = /^\d*\.?\d*$/.test(value) && Number(value) >= 0;
    
    if (isValid) {
      updateAFV(value);
    }
  };
  const handleBlur = (e) => {
    const value = Number(e.target.value);
    if (value < 0 || isNaN(value)) {
      updateAFV(0);
    }
  };

  const handleHideCancelModal = () => {
    showActionModalTo(false);
  };

  const onConfirm = () => {
    dispatch(
      navigateToContractDetails({
        currentStep: OrderingGuideSteps.CONTRACT_LIST,
        selectedContractLine: null,
      }),
    );
    dispatch(setUpdatedContractLineECs({
      updatedContractECs: []
    }))
  };

  const canUpdate = currentUser?.roles?.some((role) => role?.id === '1503') || currentUser?.roles?.some((role) => role?.name === UserRoles.SITE_ADMIN);

  return (
    <>
      {showActionModal && (
        <div
          role="dialog"
          aria-modal="true"
          className="afp-modal-overlay modalContainer low-bid-justification"
        >
          <Modal
            variant="large"
            title={<h1 className="modal-title">Confirm action</h1>}
            onClose={() => {
              handleHideCancelModal();
            }}
            actions={
              <div>
                <Button
                  type="button"
                  variant="unstyled"
                  data-testid="previous-modal-cancel-button"
                  onClick={handleHideCancelModal}
                  label="Cancel"
                />
                <Button
                  className="modal-class-previous"
                  type="button"
                  variant="primary"
                  data-testid="previous-modal-confirm-button"
                  onClick={onConfirm}
                  label="Confirm"
                />
              </div>
            }
          >
            <p>
              Are you sure you want to go back to the previous step without
              saving changes? All updates will be lost.
            </p>
          </Modal>
        </div>
      )}
      <BreadcrumbsEC />
      <div className="ordering-guide-main-section">
        <div className="ordering-guide-ec-left-section">
          <PageTitle
            className="ordering-guide-left-section"
            title="Equipment codes"
            tabIndex={0}
          />
          <p className="contract-ec-title">
            {selectedSINValue}, {selectedContractLine?.vendorName},{' '}
            {selectedContractLine?.modelCode}, {selectedSINTitle}
          </p>
          <p className="contract-ec-title-desc">
            Price:{' '}
            {parseInt(selectedContractLine?.unitPrice, 10)?.toLocaleString(
              'en-US',
              {
                style: 'currency',
                currency: 'USD',
              },
            ) || 0}
          </p>
        </div>
        <div className="ordering-guide-right-section">
          <div className="ordering-guide-afpbase">
            <TextInput
              type="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={afpBaseValue}
              disabled={
                !canUpdate
              }
              prefix="$"
              label="AFV Base"
              name={`afv-name`}
              data-testid={`afv-data-test`}
              errorMessage={''}
            />
          </div>
        </div>
      </div>

      {selectValuesToUpdate && (
        <Alert type="error" slim>
          No records are updated to save
        </Alert>
      )}
      <ContractECDetailsFrame />
      <div className="page-navigation">
        <Button
          variant="outline"
          type="button"
          leftIcon={{ name: 'arrow_back' }}
          data-testid="order_guide_navigation_previous"
          onClick={() => handlePageNavigation('previous')}
          label="Previous"
        />
        <Button
          variant="default"
          type="button"
          data-testid="order_guide_navigation_continue"
          disabled={!canUpdate}
          onClick={() => handlePageNavigation('continue')}
          label=" Save"
        />
      </div>
    </>
  );
};

export default ContractECDetails;
