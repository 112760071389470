import MinimumRequirementRow from "./minimum-requirement-row";

export default function MinimumRequirementGroup({ name, options }) {
    return (
        <div className='minimum-requirements-group'>
            <h3>{name}</h3>

            <table class='minimum-requirements-table'>
                {options.map((option) => <MinimumRequirementRow option={option} />)}
            </table>
        </div>
    )
}