import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  Checkbox,
  SelectDropdown,
  Form,
  TextInput,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  CREATE_REQUISITION_ATTACHMENT,
  FETCH_SIGNED_URL_REQUISITIONS,
  GET_REQUISITION_ATTACHMENT_TYPES,
} from '../../../../services/data-layer';

import './AttachmentUploadModal.scss';
import { UserTypes } from '../../../../constants';
import {
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_LABELS,
} from '../../../non-standard-purchase/constants';
import {validateUploadedFile} from "../../../../utilities/commonUtils.jsx";

const AttachmentUploadModal = ({ requisitionId, onCompleted }) => {
  const { appURLs } = window.AFP_CONFIG;
  const { currentUser } = useCurrentUser();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);

  const [values, setValues] = useState({
    isInternalAttachment: false,
    fileType: -1,
    file: null,
    description: '',
  });
  const [fileAddErrors, setFileAddErrors] = useState(null);
  const { data: requisitionAttachmentTypeData } = useQuery(
    GET_REQUISITION_ATTACHMENT_TYPES,
  );
  const [returnSignedUrl] = useMutation(FETCH_SIGNED_URL_REQUISITIONS);
  const [addAttachmentRecord] = useMutation(CREATE_REQUISITION_ATTACHMENT, {
    onCompleted: (data) => {
      onCompleted(data);
      setShowAttachmentModal(false);
    },
  });

  const [attachmentTypes, setAttachmentTypes] = useState([]);

  useEffect(() => {
    const types = [
      {
        label: '-select-',
        value: -1,
      },
    ];

    if (
      !requisitionAttachmentTypeData?.getAllRequisitionAttachmentTypes?.length
    ) {
      return setAttachmentTypes(types);
    }

    return setAttachmentTypes(
      types.concat(
        requisitionAttachmentTypeData.getAllRequisitionAttachmentTypes.map(
          (type) => {
            return {
              value: type.requisitionAttachmentTypeId,
              label: type.attachmentType,
            };
          },
        ),
      ),
    );
  }, [requisitionAttachmentTypeData?.getAllRequisitionAttachmentTypes]);

  const onClickAddAttachment = () => {
    setShowAttachmentModal(true);
  };

  const handleFileChange = (e) => {
    const {fileError, isValidFormat} = validateUploadedFile(e);
    setFileAddErrors({
      ...fileAddErrors,
      file: fileError
    });
    setDisableUpload(!isValidFormat);
    setValues({ ...values, file: e });
  };

  const updateValue = (e, type) => {
    if (type === 'fileType' && fileAddErrors?.groupAssignment) {
      setFileAddErrors({
        ...fileAddErrors,
        groupAssignment: null,
      });
    }
    const updatedValues = { ...values };
    updatedValues[type] = e.target.value;
    setValues(updatedValues);
  };

  const updateCheckbox = (e, type) => {
    const updatedValues = { ...values };
    updatedValues[type] = e.target.checked;
    setValues(updatedValues);
  };

  const toBeRounded = (bytes) => {
    const converted = bytes / (1024 * 1024);
    const size = converted.toFixed(2);
    return Number(size);
  };

  const handleCancel = () => {
    setShowAttachmentModal(false);
  };

  const handleSubmitAttachmentForm = async () => {
    try {
      setFileAddErrors(null);

      let errorList = {};

      if (!values.file) {
        errorList = {
          ...errorList,
          file: 'Please add a Supporting file for upload',
        };
      }

      if (values.fileType === -1) {
        errorList = {
          ...errorList,
          groupAssignment: 'Please choose a valid File category',
        };
      }

      if (Object.keys(errorList).length > 0) {
        setFileAddErrors(errorList);
      } else {
        const returnSignedUrlData = await returnSignedUrl({
          variables: {
            input: {
              requisitionId,
              name: values?.file?.name,
              status: 1,
              attachmentTypeId: values?.fileType,
              docStoreUri: appURLs.store,
              size: toBeRounded(values?.file?.size),
              fileMimeType: values?.file?.type,
              description: values?.description,
            },
          },
        });

        if (!returnSignedUrlData?.data?.generateSignedUrl) {
          throw new Error('Error retrieving signed data');
        }

        const { signedUrl, metadataId } =
          returnSignedUrlData.data.generateSignedUrl;

        await axios.put(signedUrl, values.file, {
          headers: {
            'Content-Type': values.file.type,
          },
        });
        await addAttachmentRecord({
          variables: {
            input: {
              requisitionId,
              name: values?.file?.name,
              status: 1,
              attachmentTypeId: values?.fileType,
              docStoreUri: appURLs.store,
              size: toBeRounded(values?.file?.size),
              fileMimeType: values?.file?.type,
              description: values?.description,
              signedUrl,
              metadataId,
            },
          },
        });
        setValues({
          isInternalAttachment: false,
          fileType: -1,
          file: null,
          description: '',
        });
        setFileAddErrors(null);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <>
      {!!showAttachmentModal && (
        <div className="afp-modal-overlay modal-overlay">
          <Modal
            title={<h2>Upload file</h2>}
            onClose={handleCancel}
            className="upload-attachment-modal"
            variant="large"
            actions={
              <div>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  variant="unstyled"
                  data-testid="requisition-attachment-upload-cancel-button"
                  onClick={handleCancel}
                  label="Cancel"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  disabled={disableUpload}
                  data-testid="requisition-attachment-upload-submit-button"
                  onClick={handleSubmitAttachmentForm}
                  label="Add file"
                />
              </div>
            }
          >
            <Form
              name="requisition-attachment-upload"
              className="requisition-attachments-upload-form"
            >
              <FileUpload
                acceptableFiles={ACCEPT_FILE_TYPES}
                data-testid="requisition-attachment-upload-file"
                acceptableFilesLabel={ACCEPT_FILE_LABELS}
                onChange={handleFileChange}
              />
              {fileAddErrors?.file && (
                <ErrorMessage id="file-error">
                  {fileAddErrors?.file}
                </ErrorMessage>
              )}
              <Alert type="info">
                Help prevent a privacy incident by ensuring that any supporting
                document uploaded here does not contain personally identifiable
                information (PII).
              </Alert>

              {currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE && (
                <>
                  <Alert
                    type="warning"
                    data-testid="requisition-internal-attachment-banner"
                  >
                    If you would like to restrict this file’s access to internal
                    GSA users only, please make sure to select the checkbox
                    below.
                  </Alert>

                  <Checkbox
                    id="requisition-attachment-is-internal"
                    name="requisition-attachment-is-internal"
                    value={values.isInternalAttachment}
                    data-testid="requisition-attachment-internal-attachment"
                    onChange={(e) => updateCheckbox(e, 'isInternalAttachment')}
                    label="This file is internal to GSA and should not be accessible to customers or vendors"
                  />
                </>
              )}

              <SelectDropdown
                placeholder="hello"
                id="groupAssignment"
                onChange={(e) => updateValue(e, 'fileType')}
                value={values.fileType}
                label="File category"
                data-testid="requisition-attachment-upload-file-type"
                required
                options={attachmentTypes}
              />
              {fileAddErrors?.groupAssignment && (
                <ErrorMessage id="group-assignment-error">
                  {fileAddErrors?.groupAssignment}
                </ErrorMessage>
              )}
              <TextInput
                type="textarea"
                placeholder="Type comment here..."
                value={values.description}
                onChange={(e) => updateValue(e, 'description')}
                data-testid="requisition-attachment-upload-description"
                maxLength={100}
                hint={`${100} characters allowed`}
              />
            </Form>
          </Modal>
        </div>
      )}

      <div className="add-attachment">
        <Button
          type="button"
          onClick={onClickAddAttachment}
          leftIcon={{ name: 'add' }}
          label="Add attachment"
          variant="outline"
          data-testid="requisition-attachment-upload-button"
        />
      </div>
    </>
  );
};

AttachmentUploadModal.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
};

AttachmentUploadModal.defaultProps = {
  onCompleted: () => {},
};

export default AttachmentUploadModal;
