import { createSlice } from '@reduxjs/toolkit';

const orderInitialState = {
  allOrders: { rows: [], count: 0 },
  orderDetails: {},
  orderDetailsLoading: false,
  orderListLoading: false,
  orderDetailsError: null,
  orderContractLineIdData: {},
  orderTab: 'orderDetails',
  requisitionAttachments: [],
  contractLineOptions: [],
  paintAndGraphicOptions: [],
  inReqTaggedOptions: [],
  engTaggedOptions: [],
  optionalReqData: [],
  orderActivity: {
    orderActivitiesData: {},
    requisitionActivitiesData: {},
  },
  orderAttachments: {
    orderAttachmentsData: {},
    orderAttachmentsLoading: false,
    orderAttachmentTypesData: {},
  },
  vehicles: {
    orderVehiclesData: {},
    filters: [],
    sort: '',
    isReset: false,
    isVehiclesDataLoading: false
    // paginationState: {
    //   limit: 10,
    //   offset: 0,
    //   currentPage: 1,
    // },
  }
};

const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: {
    // example of a reducer
    getOrdersForUser: (state, action) => {
      return { ...state, allOrders: action.payload };
    },
    orderInProgress: (state, action) => {
      return { ...state, orderListLoading: action.payload };
    },
    orderSuccess: (state) => {
      return { ...state, orderListLoading: false };
    },
    orderFailure: (state, action) => {
      return { ...state, error: action.payload };
    },
    getOrderDetails: (state, action) => {
      return { ...state, orderDetails: action.payload };
    },
    orderDetailsInProgress: (state, action) => {
      return { ...state, orderDetailsLoading: action.payload };
    },
    orderDetailsFailure: (state, action) => {
      return { ...state, orderDetailsError: action.payload };
    },
    setContractLineIdData: (state, action) => {
      return { ...state, orderContractLineIdData: action.payload };
    },
    setOrderActivitiesData: (state, action) => {
      return {
        ...state,
        orderActivity: {
          ...state.orderActivity,
          orderActivitiesData: action.payload,
        },
      };
    },
    setRequisitionActivitiesData: (state, action) => {
      return {
        ...state,
        orderActivity: {
          ...state.orderActivity,
          requisitionActivitiesData: action.payload,
        },
      };
    },
    setOrderAttachments: (state, action) => {
      return {
        ...state,
        orderAttachments: {
          ...state.orderAttachments,
          orderAttachmentsData: action.payload,
        },
      };
    },
    orderAttachmentsInProgress: (state, action) => {
      return {
        ...state,
        orderAttachments: {
          ...state.orderAttachments,
          orderAttachmentsLoading: action.payload,
        },
      };
    },
    setOrderAttachmentTypesData: (state, action) => {
      return {
        ...state,
        orderAttachments: {
          ...state.orderAttachments,
          orderAttachmentTypesData: action.payload,
        },
      };
    },
    setOrdersTab: (state, action) => {
      return { ...state, orderTab: action.payload };
    },
    getRequisitionAttachmentsData: (state, action) => {
      return { ...state, requisitionAttachments: action.payload };
    },
    getContractLineOptionsData: (state, action) => {
      return { ...state, contractLineOptions: action.payload };
    },
    paintAndGraphicOptionsData: (state, action) => {
      return { ...state, paintAndGraphicOptions: action.payload };
    },
    inReqTaggedOptionsData: (state, action) => {
      return { ...state, inReqTaggedOptions: action.payload };
    },
    engineerTaggedOptionsData: (state, action) => {
      return { ...state, engTaggedOptions: action.payload };
    },
    optionalReqData: (state, action) => {
      return { ...state, optionalReqData: action.payload };
    },
    setOrderVehicles: (state, action) => {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          orderVehiclesData: action?.payload,
        },
      };
    },
    setOrderVehiclesLoading: (state, action) => {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          isVehiclesDataLoading: action?.payload,
        },
      };
    },
    setOrderVehicleFilters: (state, action) => {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          filters: action?.payload,
        },
      };
    },
  },
});

export const {
  orderInProgress,
  orderSuccess,
  orderFailure,
  getOrdersForUser,
  getOrderDetails,
  orderDetailsInProgress,
  orderDetailsFailure,
  setContractLineIdData,
  setOrderActivitiesData,
  setRequisitionActivitiesData,
  setOrderAttachments,
  setOrdersTab,
  getRequisitionAttachmentsData,
  getContractLineOptionsData,
  paintAndGraphicOptionsData,
  inReqTaggedOptionsData,
  engineerTaggedOptionsData,
  optionalReqData,
  orderAttachmentsInProgress,
  setOrderAttachmentTypesData,
  setGeneratedSignedUrlData,
  setOrderVehicles,
  setOrderVehiclesLoading,
  setOrderVehicleFilters
} = orderSlice.actions;

export default orderSlice.reducer;
