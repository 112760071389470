import { z } from "zod";

export const vehicleGarageSchema = z.object({
    countryCode: z.string().refine(val => val !== '-1' && !!val, 'Please select a country'),
    addressLine1: z.string().nullable(),
    addressLine2: z.string().nullable(),
    city: z.string().nullable(),
    stateCode: z.string().refine(val => val !== '-1' && !!val, 'Please select a state'),
    zipCode: z.string().min(1, 'Please enter a zip code'),
    usedForTowing: z.string().min(1, 'Please select an option'),
    useSameSin: z.string().min(1, 'Please select an option'),
    towingWeight: z.coerce.string().nullable(),
    towingWeightUnit: z.string().nullable(),
}).superRefine((data, ctx) => {
    if (data.usedForTowing === 'yes') {
        if (!data.towingWeight) {
            ctx.addIssue({
                message: 'Please enter a towing weight',
                code: z.ZodIssueCode.custom,
                path: ['towingWeight']
            });
        }
    }
})