import VehicleGarage from "./steps/vehicle-garage/vehicle-garage";
import './replace-vehicle.scss';
import { useSelector } from "react-redux";
import { useMemo } from "react";
import SelectOptions from "./steps/select-options/select-options";
import ReviewAndSubmitReplacementRequest from "./steps/review-submit/review-submit";

export const REPLACE_VEHICLE_STEPS = Object.freeze({
    GARAGE: 'garage',
    SELECT_OPTIONS: 'select-options',
    REVIEW_SUBMIT: 'review-submit',
})

export default function ReplaceVehicle() {
    const { currentStep } = useSelector((state) => state.vehicleReferralReducer);

    const step = useMemo(() => {
        switch (currentStep) {
            case REPLACE_VEHICLE_STEPS.GARAGE:
                return <VehicleGarage />;
            case REPLACE_VEHICLE_STEPS.SELECT_OPTIONS:
                return <SelectOptions />;
            case REPLACE_VEHICLE_STEPS.REVIEW_SUBMIT:
                return <ReviewAndSubmitReplacementRequest />;
            default:
                return null;
        }
    }, [currentStep]);

    return (
        <div className='refer-replace-vehicle'>
            {step}
        </div>
    );
}

