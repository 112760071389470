import { useState, useRef } from "react";
import LeasingNavigation from "../../../components/navigation.jsx";
import { LeasingSteps, OCONUSStateCodes } from "../../../leasing-consts.js";
import useLeasingNavigation from "../../../../../hooks/useLeasingNavigation.jsx";
import { FormGenerator } from "@gsa/afp-shared-form-utils";
import { Spinner } from "@gsa/afp-component-library";
import { marshallingAddressSchema } from "./marhsalling-address-schema.jsx";
import generateMarshallingAddressForm from "./marshalling-address-form.jsx";
import './marshalling-address.scss';
import TelephoneControl from "../../../../../components/FormComponents/telephone-input.jsx";
import ComboBoxControl from "../../../../../components/FormComponents/combobox.jsx";
import { useSelector } from "react-redux";
import { useGetAllVendorMarshallerLocations, useGetCountryCodeOptions, useGetFleetManagementCenters, useGetStatesOptions, useGetUserById } from "../../../../../requests/leasing.jsx";
import useUpdateDraft from "../../../components/update-draft/update-leasing-draft.js";
import { useDispatch } from "react-redux";
import { setMarshallingAddress } from "../../../../../reducers/leasing.jsx";

export default function MarshallingAddress() {
    const { marshallingAddress } = useSelector((state) => state.leasingReducer);
    const { handleLeasingNavigation } = useLeasingNavigation();
    const { zones, requisitionId, createdByUserId } = useSelector((state) => state.leasingReducer);
    const dispatch = useDispatch();
    const [showSkippedAlert, setShowSkippedAlert] = useState(false);
    const formRef = useRef(null);

    const handlePreviousNavigation = () => {
        handleLeasingNavigation(LeasingSteps.REVIEW_VEHICLE_BUILD);
    };

    const [updateDraft] = useUpdateDraft();
    const { data: countryCodeOptions, loading: countryCodesLoading } = useGetCountryCodeOptions();
    const { data: defaultStates, loading } = useGetStatesOptions();
    const { data: fleetManagementCenters, loading: fmcLoading } = useGetFleetManagementCenters();
    const { data: marshallerLocations, loading: marshallerLocationsLoading } = useGetAllVendorMarshallerLocations();
    const { data: createdByUser, loading: createdByUserLoading } = useGetUserById({
        skip: !createdByUserId,
        variables: {
            id: createdByUserId
        }
    });

    const [defaultValues, formContent] = generateMarshallingAddressForm({
        marshallingAddress,
        requisitionId,
        zones: zones.map(zone => ({ value: zone.id, label: zone.name })),
        defaultStates,
        showSkippedAlert,
        setShowSkippedAlert,
        marshallerLocations,
        fleetManagementCenters: fleetManagementCenters || [],
        formRef,
        createdByUser,
        countryCodeOptions,
    });

    const handleSubmit = (data) => {
        setShowSkippedAlert(false);

        let addr1 = {
            title: 'GSA Fleet Zonal Vital Records Center',
            address1: '2300 Main Street',
            address2: 'Suite 7NE',
            city: 'Kansas City',
            state: 'MO',
            zip: '64108',
            country: 'USA'
        }

        let euMailingAddress = {
            title: 'GSA Fleet IFMS',
            address1: 'McCully Barracks, Bldg 6295',
            address2: 'Ober-Olmer Str',
            city: 'Wackernheim',
            state: '',
            zip: '55362',
            country: 'DE'
        }

        const input = {
            clientData: {
                clientState: {
                    marshallingAddress: {
                        deliveryAddress: data,
                        mailingAddress:
                            (data.marshallingAddress.deliveryAddress.country === null ||
                            data.marshallingAddress.deliveryAddress.country === 'US' ||
                            data.marshallingAddress.deliveryAddress.country === 'JP' ||
                                OCONUSStateCodes.indexOf(data.marshallingAddress.deliveryAddress.state) > -1) ?
                                addr1 : euMailingAddress
                    }
                }
            },
        }

        updateDraft(input, LeasingSteps.REVIEW_SUBMIT).then((data) => {
            if (data) {
                dispatch(setMarshallingAddress(input.clientData.clientState.marshallingAddress));
                handleLeasingNavigation(LeasingSteps.REVIEW_SUBMIT);
            }
        });
    };

    const handleErrors = (errors) => {
        if (errors.location) {
            setShowSkippedAlert(true);
        }
    }

    if (loading || fmcLoading || marshallerLocationsLoading || createdByUserLoading || countryCodesLoading) {
        return <Spinner size="large" />;
    }

    return (
        <div className="marshalling-address">
            <div className='marshalling-address-header'>
                <h3>Provide delivery address</h3>
                <p>
                    Please provide information for the following addresses.
                </p>
            </div>

            <FormGenerator
                ref={formRef}
                id="marshalling-address-form"
                schema={marshallingAddressSchema}
                content={formContent}
                defaultValues={defaultValues}
                onSubmit={handleSubmit}
                onError={handleErrors}
                controls={{
                    'phone': TelephoneControl,
                    'combobox': ComboBoxControl
                }}
            />

            <LeasingNavigation
              onPrevious={handlePreviousNavigation}
              hide={['cancel', 'submit', 'reject']}
              nextFormId='marshalling-address-form'
            />
        </div>
    )
}