export const modifyAgencyInfoData = (requisition) => {
  // const reqCartPurchaseLine = requisition?.requisitionCartPurchaseLine[0];
  return {
    agencyCode: requisition?.agencyCode,
    bureauCode: requisition?.bureauCode,
    agency: sessionStorage.getItem('orderingAgency'),
    bureau: sessionStorage.getItem('orderingBureau'),
    office: sessionStorage.getItem('orderingOffice'),
    agencyName: `${requisition?.agencyCode}-${requisition?.agencyInfo?.name}`,
    bureauName: `${requisition?.bureauCode}-${requisition?.bureauInfo?.name}`,
    officeName: `${requisition?.officeInfo?.officeCode || ''}-${
      requisition?.officeInfo?.officeName || ''
    }`,
    requisitionJulian: requisition?.julianDate,
    requisitionBOAC: requisition?.boac,
    agencyOrderNumber: requisition?.customerAssignedNumber,
    requisitionSerialNumber: requisition?.serialNumber,
    groupAssignment: requisition?.officeInfo?.officeCode,
    firstName: requisition?.agencyContact?.firstName,
    lastName: requisition?.agencyContact?.lastName,
    email: requisition?.agencyContact?.email,
    phoneCountryCallingCode: requisition?.agencyContact?.phoneCountryCode,
    phoneNumber: requisition?.agencyContact?.phoneNumber,
    phoneExtension: requisition?.agencyContact?.phoneExtension,
    signalCode: requisition?.finSignalCode,
    serviceCode: requisition?.finServiceCode,
    signalSupplementaryAddress: requisition?.finSupplementaryAddress,
    fundCode: requisition?.finFundCode,
    unitPrice: requisition?.vehicles[0]?.unitPriceToCustomer,
    totalSellingPrice: requisition?.totalSellingPrice,
    agencyFinancialData: requisition?.finAdditionalInfo,
    finAdditionalFundsPerUnit: requisition?.finAdditionalFundsPerUnit,
    markForInformation: requisition?.markForInformation,
    accountingClassificationReferenceNumber:
      requisition?.accountingClassificationReferenceNumber,
    registrationNumber: requisition?.registrationNumber,
    nationalStockNumber: requisition?.nationalStockNumber,
    transportationControlNumber: requisition?.transportationControlNumber,
    treasuryAccountSymbol: requisition?.clientData?.clientState?.validatedTas,
  };
};

export const modifyAddressData = (address, agencyContact = null) => {
  return {
    ...address,
    entityName: address?.entityName || address?.dealerName,
    agencyOrganizationName:
      address?.agencyOrganizationName || address?.entityName,
    addressLine1: address?.addressLine1 || address?.address1,
    addressLine2: address?.addressLine2 || address?.address2,
    stateCode: address?.stateCode || address?.state?.stateCode,
    zipcode: address?.zipcode || address?.postalCode,
    email: address?.email || address?.email || agencyContact?.email,
  };
};

export const formatPhoneNumber = (phoneCountryCode, number, extension) => {
  if (!number) {
    return '--';
  }

  const phoneCountryPart = phoneCountryCode ? `${phoneCountryCode}-` : '';
  const numbersOnly = number.replace(/\D[^.]/g, '');
  const extensionText = extension ? ` ext. ${extension}` : '';
  return `${phoneCountryPart}${numbersOnly.substring(
    0,
    3,
  )}-${numbersOnly.substring(3, 6)}-${numbersOnly.substring(
    6,
  )}${extensionText}`;
};

export const getCallingCode = (formattedAddressData) => {
  if (formattedAddressData.phoneCountryCallingCode) {
    return formattedAddressData.phoneCountryCallingCode;
  }

  if (formattedAddressData.phoneCountryCode) {
    return formattedAddressData.phoneCountryCode.toString().split(':')[0];
  }

  return '';
};

export const RequisitionStatus = {
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  DRAFT_APPROVAL: 'DRAFT_APPROVAL',
  FINAL_APPROVAL: 'FINAL_APPROVAL',
  ENGINEERING_REVIEW: 'ENGINEERING_REVIEW',
  ENGINEERING_APPROVAL: 'ENGINEERING_APPROVAL',
  RECEIVED_BY_GSA: 'RECEIVED_BY_GSA',
  CONTRACTING_REVIEW: 'CONTRACTING_REVIEW',
  CONTRACTING_APPROVAL: 'CONTRACTING_APPROVAL',
  PENDING_CUSTOMER_RESPONSE: 'PENDING_CUSTOMER_RESPONSE',
  CONTRACTING_FINAL_REVIEW: 'CONTRACTING_FINAL_REVIEW',
  ORDER_RECEIVED: 'ORDER_RECEIVED',

  ON_HOLD: 'ON_HOLD',
  RETURNED: 'RETURNED',
  CANCELLED: 'CANCELLED',
  DELETED: 'DELETED',

  ORDERING_ADMIN_APPROVAL: 'ORDERING_ADMIN_APPROVAL',
  ORDERING_ADMIN_REVIEW: 'ORDERING_ADMIN_REVIEW',
  FMC_APPROVAL: 'FMC_APPROVAL',
  ZONAL_APPROVAL: 'ZONAL_APPROVAL',
};

export const RequisitionStatusLabels = {
  DRAFT: 'Draft',
  PENDING_APPROVAL: 'Pending approval',
  DRAFT_APPROVAL: 'Draft approval',
  FINAL_APPROVAL: 'Final approval',
  ENGINEERING_REVIEW: 'Engineering review',
  ENGINEERING_APPROVAL: 'Engineering approval',
  CONTRACTING_REVIEW: 'Contracting review',
  CONTRACTING_APPROVAL: 'Contractor approval',
  PENDING_CUSTOMER_RESPONSE: 'Pending customer response',
  CONTRACTING_FINAL_REVIEW: 'Contracting final review',
  RECEIVED_BY_GSA: 'Received by GSA',
  ORDER_RECEIVED: 'Received by GSA',

  ON_HOLD: 'On hold by Engineer',
  RETURNED: 'Returned',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted',

  ORDERING_ADMIN_REVIEW: 'Ordering admin review',
  ORDERING_ADMIN_APPROVAL: 'Ordering admin approval',
  FMC_APPROVAL: 'FMC approval',
  ZONAL_APPROVAL: 'Zonal approval'
};

export const RequisitionTransactionType = {
  VEHICLE_SALE: 'VEHICLE_SALE',
  VEHICLE_LEASE: 'VEHICLE_LEASE',
};

export const displayReqStatus = (status) => {
  return RequisitionStatusLabels[status] || status;
};
