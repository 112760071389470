import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  Typeahead,
  Icon,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import NotAvailableModal from './NotAvailableModal';
import './SearchBySIN.scss';
import { GET_REQUISITION_SINS_TYPEAHEAD } from '../../services/data-layer/standards';
import EngineeringReviewModal from '../EngineeringReviewModal/EngineeringReviewModal';
import { TagsToWatch } from '../../constants/constants';
import { useGetStandardItems } from '../../requests/leasing';
import { useSelector } from 'react-redux';

export const getStandardItemLabel = (item) => {
  return `${item.standardItemNumber}, ${item.title}`;
};

const SearchBySIN = ({ onSelectItem, leasing, replacement }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const engineeringReviewModal = useModal();
  const DisplayEngineeringReviewModal = connectModal(EngineeringReviewModal);

  const [vehicleData, setVehicleData] = useState([]);
  const [forceUpdate, setForceUpdate] = useState({ value: '' });
  const [selectedSIN, setSelectedSIN] = useState('');
  const [labelSINMap, setLabelSINMap] = useState(null);
  const { referralFiscalYear } = useSelector(
    (state) => state.vehicleReferralReducer,
  );
  const [getData] = useLazyQuery(GET_REQUISITION_SINS_TYPEAHEAD, {
    fetchPolicy: 'network-only',
  });

  const [getStandardItems] = useGetStandardItems();

  const fetchValues = async (_, value) => {
    const queryVariables = {
      limit: 15,
      offset: 0,
      searchString: String(value),
      leasingQuery: leasing,
    };
    let data;
    if (replacement) {
      data = await getStandardItems({
        variables: {
          filters: {
            operator: 'AND',
            value: [
              {
                key: 'standardItemNumber',
                operator: 'LIKE',
                value: `${value}%`,
              },
              { operator: 'EQ', key: 'year', value: referralFiscalYear },
              { operator: 'EQ', key: 'deletedAt', value: null },
              { operator: 'EQ', key: 'isActive', value: 1 },
            ],
          },
          order: 'standardItemNumber DESC',
          offset: 0,
          limit: 1000,
          groupBy: ['standardItemNumber'],
        },
      });

      if (data.data.rows) {
        data = data.data.rows;
      }
    } else {
      data = await getData({ variables: queryVariables });
      if (data.data?.getRequisitionSinsTypeahead?.rows) {
        data = data.data.getRequisitionSinsTypeahead.rows;
      }
    }

    if (data) {
      setVehicleData([...data]);
      if (data.length) {
        const list = data.reduce((accum, standardItem) => {
          const label = getStandardItemLabel(standardItem);
          return { ...accum, [label]: standardItem };
        }, {});
        setLabelSINMap(list);
      }
    } else {
      setLabelSINMap(null);
      setForceUpdate({ value: '' });
    }
  };

  const filter = {
    key: 'SIN-filter',
    title: 'Tag Number',
    type: 'typeahead',
    placeholder: 'Search by Standard Item Number',
    value: '',
    defaultFilter: false,
    permanent: false,
    operator: '$like',
  };

  useEffect(() => {
    const input = document.querySelector(
      '.sin-search-container .usa-input.main_input',
    );
    if (input) {
      input.setAttribute('placeholder', filter.placeholder);
    }
  }, []);

  const handleClose = () => {
    closeModal();
    setForceUpdate({ value: '' });
  };

  const onOptionEnter = (value) => {
    if (value.includes('(Not Available') || value.includes('(Available')) {
      openModal();
      return;
    }
    onSelectItem(labelSINMap[value]);
  };

  const onSelect = (item) => {
    setSelectedSIN(item);
    const standardItem = labelSINMap[item];

    return standardItem.tags?.value?.some((tag) => TagsToWatch.includes(tag)) &&
      !leasing
      ? engineeringReviewModal.openModal()
      : onOptionEnter(item);
  };

  const onCancel = () => {
    engineeringReviewModal.closeModal();
    setForceUpdate({ value: '' });
  };

  return (
    <div
      data-testid="sin-search-container"
      className="sin-search-container usa-search"
    >
      <Typeahead
        id="sin-check"
        key="sin-search"
        forceUpdate={forceUpdate}
        accessor="sin-accessor"
        filterValue={filter.value}
        placeholder={filter.placeholder}
        onOptionEnter={onSelect}
        // No duplicates pls
        typeaheadValues={[...new Set(vehicleData.map(getStandardItemLabel))]}
        fetchTypeaheadValues={(accessor, value) => {
          fetchValues(accessor, value);
        }}
        inputCharNum={2}
        debounceDelay={0}
      />
      <div data-testid="search-icon" className="search-icon-conatiner">
        <Icon iconName="search" className="usa-icon--size-3" />
      </div>
      <NotAvailableModal
        isOpen={isOpen}
        openModal={openModal}
        closeModal={handleClose}
      />
      <DisplayEngineeringReviewModal
        isOpen={engineeringReviewModal.isOpen}
        openModal={engineeringReviewModal.openModal}
        onClose={onCancel}
        cancel={onCancel}
        handleClick={() => onOptionEnter(selectedSIN)}
      />
    </div>
  );
};

SearchBySIN.propTypes = {
  onSelectItem: PropTypes.func.isRequired,
  leasing: PropTypes.bool,
};

export default SearchBySIN;
