import AdditionalOptionSelection from "./additional-option-selection";

export default function AdditionalOptionsGroup({ name, options }) {
    return (
        <div className='additional-options-group'>
            <h3>{name}</h3>

            <div className='additional-options-list'>
                {options.map((option) => (
                    <AdditionalOptionSelection
                        key={option.equipmentCode.code}
                        option={option}
                        multiple={option.equipmentCode.categoryCode.additionalProps.selectionType === 'M'}
                    />
                ))}
            </div>
        </div>
    )
}