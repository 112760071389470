import ImageViewer from "../../../../../utilities/ImageViewer.jsx";
import React from "react";
import './available-vehicles.scss';

const { appURLs } = window.AFP_CONFIG;

const SelectTypeCard = ({ list, onVehicleTypeClick }) => {
    return (
        <div className='vehicle-type-container'>
            {list.map((item) => {
                const imageURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${item.code}.svg`;

                return (
                    <div
                        key={item.id}
                        data-testid={`on-vehicle-type-click-${item.code}`}
                        className="sin-type-card"
                        onClick={() => onVehicleTypeClick(item)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') onVehicleTypeClick(item);
                        }}
                        role="button"
                        tabIndex="0"
                    >
                        <div className="details-container">
                            <div className="image-container">
                                <ImageViewer
                                    key={imageURL}
                                    imgUrl={imageURL}
                                    defaultImgUrl={`${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`}
                                />
                            </div>
                            <div className="underline-section" />
                            <div className="image-label">{item.title}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectTypeCard;