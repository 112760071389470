import { gql } from '@apollo/client';

export const GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL = gql`
  query GetCustomerFSREmailsByPartialEmail(
    $fsrUserEmail: String!
    $limit: Float
  ) {
    getCustomerFSREmailsByPartialEmail(
      fsrUserEmail: $fsrUserEmail
      limit: $limit
    ) {
      fsrUserEmail
    }
  }
`;

export const SEARCH_CUSTOMER_POINTS_OF_CONTACT = gql`
  query SearchPointsOfContact(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: [Filter!]
  ) {
    searchPointsOfContact(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      pocEmailAddress
    }
  }
`;


export const GET_CUSTOMER_ACCOUNT_TYPEAHEADS = gql`
  query GetCustomerAccounts(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getCustomerAccounts(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      count
      hasMore
      rows {
        customerId
        accountName
        legacyCustomerNumber
      }
    }
  }
`;
