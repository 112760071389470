import React from "react";
import {
    Spinner
} from '@gsa/afp-component-library';
import { useDispatch, useSelector } from "react-redux";
import { setAvailableVehicleTypes, setSelectedVehicleType, setSelectedStandardItem as leasingSetSelectedStandardItem, setVehicleTypeFilters } from "../../../../../../reducers/leasing.jsx";
import SearchBySIN from "../../../../../../components/SearchBySIN/SearchBySIN.jsx";
import SelectTypeCard from "../../../../../leasing/steps/destination-and-vehicle-types/available-vehicles/select-type-card.jsx";
import { useGetVehicleTypes } from "../../../../../../requests/leasing.jsx";
import { VEHICLE_GROUP_TAG_STORE } from "../../../../../../constants/constants.jsx";
import { VehicleListings } from "../../../../../leasing/steps/destination-and-vehicle-types/vehicle-listings/vehicle-listings.jsx";
import { setSelectedStandardItem } from "../../../../../../reducers/vehicle-referral.jsx";
import { placeholderOption } from "../../../../../vehicle-listings/filter-item-constants.jsx";
import { STANDARD_ITEM_PARENT_CATEGORY } from "../../../../utils/vehicle-referral-util.jsx";

const AvailableVehicles = () => {
    const {
        availableVehicleTypes,
        selectedVehicleType,
        vehicleTypeFilters
    } = useSelector(state => state.leasingReducer);

    const {
        referralFiscalYear
    } = useSelector(state => state.vehicleReferralReducer);

    const { loading: vehicleTypesLoading } = useGetVehicleTypes({
        variables: {
            childTag: VEHICLE_GROUP_TAG_STORE,
        },
        onError: (e) => {
            console.error('error getting vehicle types', e);
        },
        onCompleted: ({ getActiveVehicleGroups: activeVehicleTypes }) => {
            dispatch(setAvailableVehicleTypes(activeVehicleTypes));
            const items = activeVehicleTypes.map((x) => ({
                    label: x.title,
                    value: x.code,
                    key: x.code,
                })).sort((a, b) => a.label.localeCompare(b.label));

            dispatch(setVehicleTypeFilters([...[placeholderOption], ...items]))
        }
    });

    const dispatch = useDispatch();

    const onSelectItem = (vehicleType) => {
        const standardItem = { ...vehicleType }
        standardItem.id = parseInt(standardItem.id);

        dispatch(setSelectedStandardItem(standardItem));
        dispatch(leasingSetSelectedStandardItem(standardItem));
        if (
            standardItem.vehicleTypeCode.parentCategory ===
            STANDARD_ITEM_PARENT_CATEGORY.FED
        ) {
            dispatch(setSelectedVehicleType(standardItem.vehicleType));
        } else {
            dispatch(setSelectedVehicleType(standardItem.vehicleTypeCode.parentCode));
        }
    };

    const onVehicleTypeClick = (vehicleType) => {
        dispatch(setSelectedVehicleType(null));

        setTimeout(() => {
            dispatch(setSelectedVehicleType(vehicleType.code));
        }, 1);
    };

    return (
        <>
            <div className="filter-container">
                <h4>Search Vehicles</h4>
                <SearchBySIN
                    data-testid="sin-search"
                    onSelectItem={onSelectItem}
                    leasing
                    replacement
                />
            </div>
            <div className="filter-container">
                {(!availableVehicleTypes || !vehicleTypeFilters || vehicleTypesLoading) ? <Spinner aria-busy="true" size="large" /> : (
                    <>
                        <h4>Search by Type</h4>
                        <SelectTypeCard
                            list={availableVehicleTypes}
                            onVehicleTypeClick={onVehicleTypeClick}
                        />
                        {selectedVehicleType && <VehicleListings
                                replacement
                                standardItemsYear={referralFiscalYear}
                                onSelectItem={onSelectItem}
                                getOnVehicleTypeChange={true}
                                inline={true}
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
}

export default AvailableVehicles;