import React, { useMemo } from 'react';
import { PhoneFaxInput } from '@gsa/afp-component-library';
import { getErrorsForKey } from '@gsa/afp-shared-form-utils';
import './telephone-input.scss';

export default function TelephoneControl(props, config, useFormContext) {
  const {
    setValue,
    getValues,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const countryCodes = useMemo(
    () => config.element?.control?.countryCodes || [],
    [],
  );

  const countryCodeValue = useMemo(() => {
    return (
      (countryCodes || []).filter((i) => {
        return (
          i.value?.split(':')[0] === getValues(`${config.id}.countryCode`) &&
          i.value?.indexOf('Canada') === -1 // sorry canada
        );
      })?.[0] || ''
    );
  }, [countryCodes]);

  const phoneNumberValue = getValues(`${config.id}.number`) || '';
  const extensionValue = getValues(`${config.id}.extension`) || '';

  const controlErrors = getErrorsForKey(errors, config.id);

  return useMemo(() => {
    return (
      <PhoneFaxInput
        type={config.element?.control?.type || 'phone'}
        countryCodeProps={{
          value: countryCodeValue?.value || '',
          options: countryCodes,
          errorMessage: controlErrors?.countryCode?.message || '',
          defaultValue: countryCodeValue?.value || '',
          label: 'Country code',
          required: config.required,
          onChange(e) {
            const currentValue = getValues(`${config.id}.countryCode`);
            if (currentValue === (e || '')) return;

            const key = `${config.id}.countryCode`;
            setValue(key, e?.split(':')[0] || '', {
              shouldValidate: isSubmitted,
              shouldTouch: true,
              shouldDirty: true,
            });
          },
        }}
        phoneFaxProps={{
          errorMessage: controlErrors?.number?.message || '',
          label: config.id?.includes('phone') ? 'Phone' : 'Fax',
          defaultValue: phoneNumberValue,
          required: config.required,
          onChange(e) {
            const currentValue = getValues(`${config.id}.number`);
            const newValue = e?.target?.value || '';
            const isValid = /^[0-9-]*$/.test(newValue);
            if (!isValid) {
              return;
            }
            if (currentValue === (e || '')) return;

            const key = `${config.id}.number`;
            setValue(key, e?.target?.value || '', {
              shouldValidate: isSubmitted,
              shouldTouch: true,
              shouldDirty: true,
            });
          },
        }}
        extensionProps={{
          errorMessage: controlErrors?.extension?.message || '',
          defaultValue: extensionValue,
          label: 'Extension',
          onChange(e) {
            const currentValue = getValues(`${config.id}.extension`);
            if (currentValue === (e || '')) return;

            const key = `${config.id}.extension`;
            setValue(key, e?.target?.value || '', {
              shouldValidate: isSubmitted,
              shouldTouch: true,
              shouldDirty: true,
            });
          },
        }}
      />
    );
  }, [
    countryCodes,
    config.id,
    controlErrors?.countryCode,
    controlErrors?.number,
    controlErrors?.extension,
    countryCodeValue,
    phoneNumberValue,
    extensionValue,
  ]);
}
