import { emDashUnicode } from "@gsa/afp-shared-form-utils";

export default function MinimumRequirementRow({ option }) {
    return (
        <tr>
            <td>
                {option.equipmentCode.code} {option.equipmentCode.title}
            </td>
            <td>
                {option.associationText || emDashUnicode}
            </td>
        </tr>
    )
}