import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function useBackToList() {
    const navigate = useNavigate();
    const { vehicleReferralFilters } = useSelector(state => state.vehicleReferralReducer);

    const backToList = (state) => {
        const urlParams = new URLSearchParams();
        vehicleReferralFilters?.forEach((filter) => { urlParams.append(`filter-${filter.key}`, filter.value); })
        navigate({
            pathname: '/lease/vehicle-referral',
            search: urlParams.toString(),
        }, {
            state,
        });
        window.scrollTo(0, 0);
    }

    return {
        backToList
    }
}