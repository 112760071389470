import { Button, useModal } from '@gsa/afp-component-library';
import CancelModal from '../vehicle-selection/cancel-modal';
import { useDispatch } from 'react-redux';
import { resetVehicleReferralState } from '../../../../reducers/vehicle-referral';
import { useSelector } from 'react-redux';
import { VEHICLE_REFERRAL_STATUS } from '../../utils/vehicle-referral-util';
import useBackToList from '../../utils/use-back-to-list';

export default function VehicleReferralButtons({
  disable,
  saveButton,
  submitButton,
  cancelButton,
  nextFormId,
  hideButtons,
  returnToSearch,
  returnToCustomer,
  isReOpen,
}) {
  const { status } = useSelector((state) => state.vehicleReferralReducer);
  const { backToList } = useBackToList();

  const cancelModal = useModal();
  const dispatch = useDispatch();

  if (!disable) disable = [];
  if (!hideButtons) hideButtons = [];

  return (
    <>
      <CancelModal
        isOpen={cancelModal.isOpen}
        handleCancel={() => {
          dispatch(resetVehicleReferralState());
          backToList();
        }}
        handleClose={cancelModal.closeModal}
      />
      <div className="vehicle-referral-action-buttons">
        {!hideButtons.includes('cancel') && (
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              cancelModal.openModal();
            }}
            disabled={false}
            label="Cancel"
            {...cancelButton}
          />
        )}
        {!hideButtons.includes('save') && (
          <Button
            type="button"
            variant="outline"
            onClick={() => {}}
            leftIcon={{
              name: 'save_alt',
            }}
            disabled={false}
            label="Save draft"
            {...saveButton}
          />
        )}
        {!hideButtons.includes('returnToSearch') && (
          <Button
            type="button"
            variant="outline"
            leftIcon={{ name: 'ArrowBack' }}
            onClick={() => {
              if (
                status === VEHICLE_REFERRAL_STATUS.DRAFT ||
                status === VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION
              ) {
                cancelModal.openModal();
              } else {
                backToList();
              }
            }}
            disabled={disable.includes('returnToSearch')}
            label="Return to search"
            {...returnToSearch}
          />
        )}
        {(!hideButtons.includes('returnToCustomer') || isReOpen) && (
          <Button
            type="button"
            variant="secondary"
            onClick={() => {}}
            leftIcon={{ name: isReOpen ? '' : 'undo' }}
            disabled={
              disable.includes('returnToCustomer') || disable.includes('reOpen')
            }
            label={isReOpen ? 'Re-open request' : 'Return to customer'}
            {...returnToCustomer}
          />
        )}
        {!hideButtons.includes('submit') && (
          <Button
            variant="primary"
            onClick={() => {}}
            leftIcon={{ name: 'check' }}
            disabled={disable.includes('submit')}
            label={'Save and submit'}
            type={nextFormId ? 'submit' : 'button'}
            form={nextFormId}
            {...submitButton}
          />
        )}
      </div>
    </>
  );
}