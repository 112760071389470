import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_OFFICES_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';
import {
  LEASING_AGENCY_FILTER,
  LEASING_BUREAU_FILTER,
  LEASING_OFFICE_FILTER,
} from './filter-item-constants';

const placeholderOption = {
  value: '',
  label: '-Select-',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const LeasingOfficeFilterItem = ({
  filter,
  subject,
  operation,
  agencyFilterPath,
  bureauFilterPath,
  officeFilterPath,
  type = 'multiselectdropdown',
}) => {
  const isSelectDropdown = type === 'select';
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState();
  const [selectedOfficeCode, setSelectedOfficeCode] = useState();
  const [options, setOptions] = useState(
    isSelectDropdown ? [placeholderOption] : [],
  );
  const [isBureauRetained, setIsBureauRetained] = useState(true);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getOffices, { data: officeOptions, loading: officeOptionsLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    const agencyFilter = agencyFilterPath
      ? getFilterFromState(agencyFilterPath)
      : getFilterFromState(LEASING_AGENCY_FILTER) || {};

    setAgencyCode(agencyFilter.value);

    const bureauFilter = bureauFilterPath
      ? getFilterFromState(bureauFilterPath)
      : getFilterFromState(LEASING_BUREAU_FILTER) || {};

    setBureauCode(bureauFilter.value);
    setIsBureauRetained(bureauFilter.retained);
    const officeFilter = officeFilterPath
      ? getFilterFromState(officeFilterPath)
      : getFilterFromState(LEASING_OFFICE_FILTER) || {};
    setSelectedOfficeCode(officeFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    if (!isBureauRetained) {
      clearOneFilter(filter);
      setOptions(isSelectDropdown ? [placeholderOption] : []);
    }
    const isBureauCodeValid = isSelectDropdown
      ? bureauCode
      : bureauCode?.length === 1;

    if (agencyCode && isBureauCodeValid) {
      getOffices({
        variables: {
          agencyCode,
          bureauCode: isSelectDropdown ? bureauCode : bureauCode[0],
          operation: operation || 'view',
          subject: subject || StoreSubjects.Order,
          order: [['officeCode', 'ASC']],
        },
      });
    }
  }, [bureauCode, isBureauRetained]);

  useEffect(() => {
    if (!officeOptions?.getOfficesByPermission) {
      return;
    }

    const formattedOfficeOptions = officeOptions.getOfficesByPermission.map(
      ({ officeCode, officeName }) => ({
        value: officeCode,
        label: officeName,
      }),
    );

    setOptions(
      isSelectDropdown
        ? [placeholderOption, ...formattedOfficeOptions]
        : formattedOfficeOptions,
    );
  }, [officeOptions]);

  if (officeOptionsLoading) {
    return <Spinner />;
  }

  if (!isSelectDropdown && bureauCode?.length > 1) {
    return <p>Cannot filter by office when multiple bureaus are selected</p>;
  }

  return (
    <div id="leasing-group-filter-item" className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: type,
          options,
          value: selectedOfficeCode || [],
        }}
      />
    </div>
  );
};

LeasingOfficeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LeasingOfficeFilterItem;
