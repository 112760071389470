import { useMemo } from "react";
import AttachmentDownload from '@/pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import { UploadDocumentationModal } from "./upload-documentation-modal";
import { convertSectionToReadonlyOnCondition, emDashUnicode } from "@gsa/afp-shared-form-utils";
import DeleteDocumentationModal from "./delete-documentation-modal";
import { Alert } from "@gsa/afp-component-library";

export default function generateSupplementaryInformationForm({
    handleUpload,
    handleSave,
    handleDelete,
    handleEditAction,
    handleDeleteAction,
    uploading,
    saving,
    deleting,
    attachmentTypes,
    readonly,
    defaultValues,
}) {
    const defaultValuesInternal = useMemo(() => {
        return {
            requireMakeModel: 'no',
            makeModel: '',
            files: [],
            specialInstructions: '',
            acknowledge: false,
            ...defaultValues
        }
    }, [JSON.stringify(defaultValues.files)]);

    const uploadTableColumns = [
        {
            Header: 'Category',
            accessor: 'category',
            sortable: false,
            Cell: ({ row: { original } }) => {
                const type = attachmentTypes.find(type => type.value == original?.replacementAttachmentTypeId);
                return type?.label || emDashUnicode;
            },
        },
        {
            Header: 'File name',
            accessor: 'filename',
            sortable: false,
            Cell: ({ row: { original } }) => {
                return (
                    <AttachmentDownload
                        name={original?.name}
                        metadataId={original?.metadataId}
                    />
                )
            }
        },
        {
            Header: 'Note',
            accessor: 'note',
            sortable: false,
            cellClassName: 'attachment-note',
            Cell: ({ row: { original } }) => {
                return original?.description || emDashUnicode;
            },
        },
    ];

    const formContent = {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        title: 'Supplementary information',
        sections: [
            {
                columnWidth: 'col-9-12',
                gap: '50px',
                sections: [
                    {
                        fields: [
                            {
                                id: 'requireMakeModel',
                                label: 'Do you require a specific make and model to meet your mission?',
                                type: 'radio',
                                options: [
                                    { value: 'yes', label: 'Yes' },
                                    { value: 'no', label: 'No' }
                                ],
                                required: true,
                                element: {
                                    label: {
                                        style: {
                                            maxWidth: '100%',
                                        }
                                    },
                                    control: {
                                        inline: true,
                                    }
                                }
                            }
                        ]
                    },
                    {
                        dependentOn: 'requireMakeModel',
                        dependentValue: 'yes',
                        gap: '0px',
                        sections: [
                            {
                                id: 'makeModelWrapper',
                                altStyle: 'alt-blue-no-hover',
                                fields: [
                                    {
                                        id: 'makeModel',
                                        type: 'text',
                                        label: readonly ? 'Vehicle make and model' : 'Enter vehicle make and model',
                                    },
                                ]
                            },
                            {
                                id: 'makeModelWarning',
                                component: (config) => {
                                    if (config.element?.control?.hide) return null;
                                    return <Alert style={{marginTop: 6}} type='warning'>Please be sure to upload <strong>at least one make and model justification</strong> in the documentation below</Alert>
                                }
                            },
                        ]
                    },
                    {
                        header: 'Documentation',
                        subheader: readonly ? null : 'You may load multiple files; however, the maximum size of an uploaded file cannot exceed 10 MB. File types supported include Word, Excel, PDF, JPEG, GIF and PNG.',
                        element: {
                            header: {
                                style: {
                                    borderBottom: '1px solid #DFE1E2',
                                    fontSize: '1rem',
                                    color: '#005ea2',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px',
                                    marginBottom: '10px',
                                    paddingBottom: '5px',
                                    fontWeight: '900',
                                },
                            },
                            subheader: {
                                style: {
                                    marginBottom: '50px',
                                    maxWidth: '90%',
                                },
                            }
                        },
                        fields: [
                            {
                                id: 'files',
                                type: 'file-table-upload',
                                element: {
                                    control: {
                                        uploadModal: UploadDocumentationModal,
                                        uploadModalProps: {
                                            attachmentTypes,
                                        },
                                        confirmDeleteModal: DeleteDocumentationModal,
                                        handleUpload,
                                        handleSave,
                                        handleDelete,
                                        savingState: saving,
                                        uploadingState: uploading,
                                        deletingState: deleting,
                                        tableActions: [
                                            { icon: 'edit', label: 'Edit file documentation', handler: handleEditAction },
                                            { icon: 'delete', label: 'Delete file', handler: handleDeleteAction },
                                        ],
                                        tableColumns: uploadTableColumns,
                                        readonly: false,
                                    }
                                }
                            }
                        ]
                    },
                    {
                        header: 'Special instructions',
                        element: {
                            header: {
                                style: {
                                    borderBottom: '1px solid #DFE1E2',
                                    fontSize: '1rem',
                                    color: '#005ea2',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px',
                                    marginBottom: '10px',
                                    paddingBottom: '5px',
                                    fontWeight: '900',
                                },
                            },
                        },
                        fields: [
                            {
                                id: 'specialInstructions',
                                type: 'textarea',
                                element: {
                                    control: {
                                        maxLength: 500,
                                        countdown: true,
                                        autoGrow: true,
                                        style: {
                                            maxWidth: '100%',
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                id: 'acknowledgeWrapper',
                gap: '0px',
                sections: [
                    {
                        fields: [
                            {
                                label: 'Read and acknowledge statement below',
                                type: 'readonly',
                                required: true,
                                element: {
                                    control: {
                                        modifier: () => null,
                                    }
                                }
                            }
                        ]
                    },
                    {
                        altStyle: 'alt-blue-no-hover',
                        element: {
                            section: {
                                style: {
                                    padding: 20
                                }
                            }
                        },
                        fields: [
                            {
                                id: 'acknowledge',
                                type: 'checkbox',
                                label: 'In accordance with EISA Section 141, a low greenhouse gas vehicle will be ordered for the SIN selected, if applicable. If no low GHG vehicle is available, then a functional needs exemption will need to be on file with your agency.',
                                element: {
                                    label: {
                                        style: {
                                            maxWidth: '100%',
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }

    return {
        formContent: convertSectionToReadonlyOnCondition(readonly, formContent, [], {
            makeModelWrapper: {
                element: {
                    section: {
                        style: {
                            backgroundColor: '#F6F6F6',
                        }
                    }
                }
            },
            makeModelWarning: {
                element: { control: { hide: true } }
            },
            files: {
                type: 'file-table-upload',
                element: {
                    control: {
                        readonly: true,
                        tableColumns: uploadTableColumns
                    }
                }
            },
            specialInstructions: {
                help: null,
            },
            acknowledgeWrapper: {
                element: {
                    section: {
                        style: {
                            marginTop: '5rem'
                        }
                    }
                }
            },
            acknowledge: {
                type: 'checkbox',
                label: 'In accordance with EISA Section 141, a low greenhouse gas vehicle will be ordered for the SIN selected, if applicable. If no low GHG vehicle is available, then a functional needs exemption will need to be on file with your agency.',
                disabled: true,
                element: {
                    label: {
                        style: {
                            maxWidth: '100%',
                            color: '#1B1B1B',
                        }
                    }
                }
            },
        }),
        defaultValues: defaultValuesInternal,
    }
}
