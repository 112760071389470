import { useMemo } from "react";

export function generateNotReferredForm({
    comment
} = {
    comment: ''
}) {
    const defaultValues = useMemo(() => ({
        comment,
    }), []);

    const form = {
        buttonControls: {
            submit: false,
            cancel: false,
        },
        sections: [
            {
                fields: [
                    {
                        id: "comment",
                        type: "textarea",
                        label: "Provide justification",
                        required: true,
                        help: {
                            after: "75 characters allowed"
                        },
                        element: {
                            control: {
                                maxLength: 75,
                                countdown: true,
                            }
                        }
                    },
                ]
            }
        ]
    }

    return [form, defaultValues];
}