import React, { useRef } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import {
  VehicleStatus,
  formatVehicleStatusName,
  AGENCY_AND_BUREAU_AND_OFFICE,
  AGENCY_FILTER,
  BUREAU_FILTER,
  OFFICE_FILTER,
} from '../constants/vehicle-constants';
import AgencyFilterItem from '../../my-orders/Filters/AgencyFilterItem';
import BureauFilterItem from '../../my-orders/Filters/BureauFilterItem';
import OfficeFilterItem from '../../my-orders/Filters/OfficeFilterItem';
import { useVehicleFilter } from '../vehicle-filter-provider';

const MIN_VALUE = {
  '$orderVehicles.serial_number_vin$': 10,
  rpnNumber: 4,
  requisitionNumber: 10,
};

export const ReceiptStatusList = [
  { value: 'Delinquent', label: 'Delinquent' },
  { value: 'Pending', label: 'Pending' },
];
const { FilterPanel: ComponentFilterPanel } = FilterPanel;

const VehicleFilter = () => {
  const lastFilters = useRef();
  const { setFilters, vehicleTypes } = useVehicleFilter();

  const ReceiptStatusOption = {
    key: 'receiptStatusFilter',
    title: 'Receipt status',
  };
  const VehicleTypeOption = {
    label: 'title',
    value: 'code',
    key: 'vehicle_type_code',
    title: 'Vehicle type',
  };
  const AgencyOption = {
    label: 'name',
    value: 'id',
    key: AGENCY_FILTER,
    title: 'Agency Item',
  };
  const BureausOption = {
    label: 'name',
    value: 'id',
    key: BUREAU_FILTER,
    title: 'Bureau Item',
  };
  const OfficesOption = {
    label: 'officeName',
    value: '',
    key: OFFICE_FILTER,
    title: 'Group Item',
  };

  const handleFiltersChange = (filters) => {
    const filteredMinFilters = filters?.filter((fil) => {
      if (
        [
          '$orderVehicles.serial_number_vin$',
          'rpnNumber',
          'requisitionNumber',
        ].includes(fil.key)
      ) {
        if (fil?.value?.length >= MIN_VALUE[fil.key]) {
          return fil;
        }
      } else {
        return fil;
      }
      return null;
    });

    if (lastFilters.current === JSON.stringify(filteredMinFilters)) {
      return;
    }

    lastFilters.current = JSON.stringify(filteredMinFilters);
    setFilters(filteredMinFilters.length > 0 ? filteredMinFilters : []);
  };

  return (
    <div data-testid="vehicle-order-filters">
      <ComponentFilterPanel
        clearButtonLabel="Reset All"
        filterStructure={[
          {
            title: 'Vehicle status',
            key: 'status_code',
            id: 'filter-order-vehicle-statuses',
            type: 'multiselect',
            value: [],
            options: Object.values(VehicleStatus).map((status) => ({
              value: status,
              label: formatVehicleStatusName(status),
            })),
            permanent: false,
            operator: '$in',
            hideClear: true,
          },
          {
            key: 'serial_number_vin',
            title: 'Vehicle identification number',
            type: 'text',
            operator: '$like',
            value: '',
            minSearchLength: 10,
          },
          {
            key: 'rpn_number',
            title: 'Order number',
            type: 'text',
            operator: '$like',
            value: '',
            minSearchLength: 4,
          },
          {
            key: 'requisition_number',
            title: 'Requisition number',
            type: 'text',
            operator: '$like',
            value: '',
            minSearchLength: 10,
          },
          {
            key: ReceiptStatusOption.key,
            title: ReceiptStatusOption.title,
            type: 'multiselect',
            value: [],
            options: ReceiptStatusList,
            operator: '$in',
            hideClear: true,
          },
          {
            title: 'Organization',
            key: AGENCY_AND_BUREAU_AND_OFFICE,
            filters: [
              {
                key: AgencyOption.key,
                title: AgencyOption.title,
                component: AgencyFilterItem,
                permanent: false,
                operator: '$exact',
                value: '',
              },
              {
                key: BureausOption.key,
                title: BureausOption.title,
                component: BureauFilterItem,
                permanent: false,
                operator: '$in',
                placeholder: '- Select bureau -',
              },
              {
                key: OfficesOption.key,
                title: OfficesOption.title,
                component: OfficeFilterItem,
                permanent: false,
                operator: '$in',
                value: '',
                placeholder: '- Select group -',
              },
            ],
          },
          {
            key: VehicleTypeOption.key,
            title: VehicleTypeOption.title,
            type: 'multiselect',
            value: [],
            options: vehicleTypes.map((vehicleType) => ({
              label: vehicleType[VehicleTypeOption.label],
              value: vehicleType[VehicleTypeOption.value],
            })),
            operator: '$in',
            hideClear: true,
          },
        ]}
        model="Task"
        order={[]}
        setQueryFiltersState={(filters) => handleFiltersChange(filters)}
        showClearIcon
      />
    </div>
  );
};

export default VehicleFilter;
