import ReadonlyOption from "./readonly-option";

export default function ReadonlyOptionGroup({ name, options }) {
    return (
        <div className='readonly-option-group'>
            <h3>{name}</h3>

            <div className='readonly-options-list'>
                {options.map((option) => (
                    <ReadonlyOption
                        key={option.option.equipmentCode.code}
                        option={option}
                    />
                ))}
            </div>
        </div>
    )
}