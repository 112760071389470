import {
    Button
} from '@gsa/afp-component-library'
import { useDispatch, useSelector } from 'react-redux';
import {
    removeSelectedVehicle,
    setReplacementVehicles,
    setLeasingAccount,
    setVehicleQuantity
} from '../../../../../reducers/leasing';
import { useDeleteLeasingVehicleReplacement, useGetLeasingReplacementVehicles } from '../../../../../requests/leasing';

const RemoveReplacementVehicleButton = (props) => {
    const dispatch = useDispatch();
    const { requisitionId, replacementVehiclesTableState, referralReplacement } = useSelector(state => state.leasingReducer);
    const [deleteReplacementVehicle] = useDeleteLeasingVehicleReplacement();
    const [getReplacementVehicles] = useGetLeasingReplacementVehicles();

    const handleRemoveVehicleButton = async () => {
        if (props.row.original.requisitionLeasingVehicleReplacementId) {
            await deleteReplacementVehicle({
                variables: {
                    requisitionLeasingVehicleReplacementId: props.row.original.requisitionLeasingVehicleReplacementId
                }
            })

            getReplacementVehicles({
                variables: {
                  requisitionId,
                  order: replacementVehiclesTableState.order,
                  limit: replacementVehiclesTableState.limit,
                  offset: (replacementVehiclesTableState.currentPage - 1) * replacementVehiclesTableState.limit,
                }
              }).then(({ data }) => {
                const replacementVehicles = data.rows
                    dispatch(setReplacementVehicles(replacementVehicles));
                    dispatch(setVehicleQuantity(replacementVehicles.length));
              });
        } else {
            dispatch(removeSelectedVehicle(props.row.original));
        }
    }

    if (!!referralReplacement) return null;
    
    return (
        <Button
            label='Delete'
            leftIcon={{ name: 'delete' }}
            variant='unstyled'
            onClick={handleRemoveVehicleButton}
        />
    )
}

export default RemoveReplacementVehicleButton;