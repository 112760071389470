import {ACCEPT_FILE_TYPES} from "../pages/non-standard-purchase/constants.jsx";

export const getJulian = (date) => {
  const dateUTC = date ? new Date(date) : new Date();
  const lastDigit = dateUTC.getFullYear().toString().substring(3, 4);
  const diff = dateUTC - new Date(dateUTC.getFullYear(), 0, 0);
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  const dayString = day.toString().padStart(3, '0');

  return `${lastDigit}${dayString}`;
};

export function validateVINFormat(vin, vehicleType) {
  // https://cm-jira.usa.gov/browse/AFP-129317 - Do not validate VIN for OCONUS vehicle types
  if (vehicleType === '96') {
    return true;
  }
  // updated to allow user to enter lower case
  return /^[A-HJ-NPR-Z0-9a-hj-npr-z]{17}$/.test(vin);
}

export const coercionString = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value.toString() || null;
};

export const USDateStrToUTC = (dateStr, isEndDate = false) => {
  const usDateFormatRegex =
    /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  if (!usDateFormatRegex.test(dateStr)) return dateStr;
  const [mon, day, year] = dateStr.split('/');
  return isEndDate
    ? `${year}-${mon.padStart(2, '0')}-${day.padStart(2, '0')}T23:59:59.000Z`
    : `${year}-${mon.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00.000Z`;
};

export const utcDateToMMDDYYYY = (dateStr) => {
  if (!dateStr) {
    return '';
  }
  const date = new Date(dateStr);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
};
export const hasNonEmptyData = (obj) => {
  return Object.values(obj).some((value) => {
    if (typeof value === 'object' && value !== null) {
      return hasNonEmptyData(value);
    }
    return (
      value !== null &&
      value !== '' &&
      value !== undefined &&
      value !== 0 &&
      value !== '0'
    );
  });
};

export const UTCtoISOString = (date, isEndDate = false) => {
    const utcDateString = new Date(date);
    if(isEndDate) {
      utcDateString.setDate(utcDateString.getDate() + 1);
    }
    const fixedUTCDate = new Date(Date.UTC(utcDateString.getFullYear(), utcDateString.getMonth(), utcDateString.getDate(), 4, 0, 0, 0));
    return fixedUTCDate.toISOString();
}
export const formattedESTDate = (date) => {
  const dataStr = utcDateToMMDDYYYY(date);
  return dataStr.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    dateStyle: 'short',
  });
};

export function fromBinary(encoded) {
  const binary = atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
};

export const setSessionStorageItems = (requisition) => {
  let orderingAgency = '';
  let orderingBureau = '';
  let orderingOffice = '';
  let purchasingForAgency = '';
  let purchasingForBureau = '';
  let officeName = '';
  const officeCode = requisition?.officeCode ? requisition?.officeCode : '';

  if (requisition?.agencyInfo?.name) {
    orderingAgency = `${requisition?.agencyCode}-${requisition?.agencyInfo?.name}`;
  }
  if (requisition?.bureauInfo?.name) {
    orderingBureau = `${requisition?.bureauCode}-${requisition?.bureauInfo?.name}`;
  }
  if (requisition?.officeInfo?.officeName) {
    orderingOffice = `${requisition?.officeCode}-${requisition?.officeInfo?.officeName}`;
  }

  if (requisition?.purchasingForAgencyInfo?.name) {
    purchasingForAgency = `${requisition?.purchasingForAgencyCode}-${requisition?.purchasingForAgencyInfo?.name}`;
  }
  if (requisition?.purchasingForBureauInfo?.name) {
    purchasingForBureau = `${requisition?.purchasingForBureauCode}-${requisition?.purchasingForBureauInfo?.name}`;
  }
  if (requisition?.purchasingForOfficeInfo?.officeName) {
    officeName = `${requisition?.purchasingForOfficeCode}-${requisition?.purchasingForOfficeInfo?.officeName}`;
  }
  sessionStorage.setItem('agencyCode', requisition?.agencyCode);
  sessionStorage.setItem('bureauCode', requisition?.bureauCode);
  sessionStorage.setItem('officeCode', officeCode);
  sessionStorage.setItem('orderingAgency', orderingAgency);
  sessionStorage.setItem('orderingBureau', orderingBureau);
  sessionStorage.setItem('orderingOffice', orderingOffice);
  sessionStorage.setItem('receivingAgency', purchasingForAgency);
  sessionStorage.setItem('receivingBureau', purchasingForBureau);
  sessionStorage.setItem('receivingOffice', officeName);

  return null;
};

export function exportFileContent(base64Content, contentType, fileName) {
  if (!(base64Content && contentType && fileName)) return;

  // Convert base64 to binary data using fetch API for better support
  fetch(`data:${contentType};base64,${base64Content}`)
    .then((res) => res.blob())
    .then((blob) => {
      if (navigator.msSaveBlob) {
        // For IE 10+
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.style.visibility = 'hidden';
        document.body.appendChild(link);

        // Trigger download
        link.click();

        // Remove link from DOM and revoke the object URL to free memory
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    })
    .catch((error) => console.error('Error exporting file:', error));
}

export function exportFile(data, type, fileName) {
  if (!(data && type && fileName)) return;
  

  const blob = new Blob([data], { type });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const FileMimeType = {
  'application/pdf': 'pdf',
  'text/csv':'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-excel':'xls'

}

/**
 * country codes could potentially be saved as the following:
 * 1,
 * +1 (United States),
 * +1 United States
 * 1:+1 (United States) 
 * 1-721
 * +1-721 (Sint Maarten)
 * +1-721 Sint Maarten
 * 1-721:+1-721 (Sint Maarten)
 * 
 * but we need just the number back, returns an empty string if falsy/bad input
 * unless returnAsNumber is true, then it will return -1 if falsy/bad input
 * 
 * @param {string | number} countryCode
 * @param {boolean} returnAsNumber
 * @returns {string}
 */
export function getPhoneNumberCountryCode(countryCode, returnAsNumber = false) {
  if (!countryCode) {
    return returnAsNumber ? -1 : '';
  }

  let code = countryCode.toString();

  if (typeof countryCode === 'string') {
    if (countryCode.includes(':')) {
      code = countryCode.split(':')[0];
    } else if (countryCode.includes('(')) {
      code = countryCode.split('(')[0].trim();
    } else if (countryCode.includes(' ')) {
      code = countryCode.split(' ')[0].trim();
    }
  }

  if (code.includes('+')) code = code.replace('+', '');

  if (returnAsNumber) {
    if (code.includes('-')) code = code.replace('-', '');
    const codeAsNumber = parseInt(code, 10);
    return isNaN(codeAsNumber) ? -1 : codeAsNumber;
  } else {
    return code;
  }
}

export const validateUploadedFile = (input) => {
    console.log({input});
    let fileError = null;
    if (!input || !input.name){
        fileError = 'Please add a supporting file for upload'
    }
    return {
        fileError,
        isValidFormat: ACCEPT_FILE_TYPES.includes(input?.type)
    }
}