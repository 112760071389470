import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import './CommentActivityCard.scss';
import { humanize } from '../../constants/utils';

export const REQUISITION_ACTIVITY_TYPES = {
  REQUISITION_CREATED: 'REQUISITION_CREATED',
  REQUISITION_SUBMITTED_TO_ENGINEER: 'REQUISITION_SUBMITTED_TO_ENGINEER',
  COMMENT: 'COMMENT',
  REQUISITION_RETURNED: 'REQUISITION_RETURNED',
  REQUISITION_RESUBMITTED: 'REQUISITION_RESUBMITTED',
  RECEIVED_BY_GSA: 'RECEIVED_BY_GSA',
  REQUISITION_MAS_SUBMITTED_TO_GSA_CONTRACTING:
    'REQUISITION_MAS_SUBMITTED_TO_GSA_CONTRACTING',
  REQUISITION_MAS_RESUBMITTED_TO_GSA_CONTRACTING:
    'REQUISITION_MAS_RESUBMITTED_TO_GSA_CONTRACTING',
  REQUISITION_MAS_SUBMITTED_FOR_AGENCY_APPROVAL:
    'REQUISITION_MAS_SUBMITTED_FOR_AGENCY_APPROVAL',
  REQUISITION_APPROVED: 'REQUISITION_APPROVED',
  JUSTIFICATION: 'LEASING_VEHICLE_JUSTIFICATION',
};

export const ORDER_ACTIVITY_TYPES = {
  ORDER_CREATED: 'ORDER_CREATED',
  ORDER_COMMENT: 'ORDER_COMMENT',
  ORDER_ATTACHMENT_UPLOADED: 'ORDER_ATTACHMENT_UPLOADED',
};

const systemEvents = [ORDER_ACTIVITY_TYPES.ORDER_CREATED];

const CommentActivityCard = ({ activity }) => {
  const {
    requisitionActivityId,
    comment,
    type,
    createdUserInfo,
    createdAt,
    fileMetadata,
  } = activity;

  // NOTE: Activity type is nullable
  const activityType = type?.name;
  const { fullName: userName, email, id: userId } = createdUserInfo ?? {};
  const { currentUser } = useCurrentUser();

  const dateFormat = (date) => {
    if (moment(date).isSame(new Date(), 'day')) {
      return `Today ${moment(date).format('• hh:mm a')}`;
    }
    return moment(date).format('MM/DD/YYYY • hh:mm a');
  };

  const isSystemEvent = () => {
    return systemEvents.includes(activityType);
  };

  const isOrder = Object.values(ORDER_ACTIVITY_TYPES).includes(activityType);

  const getCardHeader = (headerActivity, headerUsername) => {
    const activityMap = {
      [REQUISITION_ACTIVITY_TYPES.COMMENT]: `Comment added by ${headerUsername}`,
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_RETURNED]: 'Requisition returned',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_RESUBMITTED]:
        'Requisition resubmitted',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_SUBMITTED_TO_ENGINEER]:
        'Requisition submitted for engineer review',
      [REQUISITION_ACTIVITY_TYPES.RECEIVED_BY_GSA]:
        'Requisition received by GSA',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_MAS_SUBMITTED_TO_GSA_CONTRACTING]:
        'Requisition submitted to GSA contracting',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_MAS_RESUBMITTED_TO_GSA_CONTRACTING]:
        'Requisition re-submitted to GSA contracting',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_MAS_SUBMITTED_FOR_AGENCY_APPROVAL]:
        'Requisition submitted for agency approval',
      [ORDER_ACTIVITY_TYPES.ORDER_COMMENT]: `Comments by ${headerUsername}`,
      [ORDER_ACTIVITY_TYPES.ORDER_CREATED]: 'Order received by GSA',
      [ORDER_ACTIVITY_TYPES.ORDER_ATTACHMENT_UPLOADED]: 'Attachment uploaded',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_CREATED]: 'Requisition submitted',
      [REQUISITION_ACTIVITY_TYPES.REQUISITION_APPROVED]: 'Requisition approved',
      [REQUISITION_ACTIVITY_TYPES.JUSTIFICATION]: 'Justification added for leasing vehicle',
    };

    return activityMap[headerActivity] || humanize(headerActivity);
  };

  return (
    <div className="activity-panel-dot" tabIndex={0} role="menuitem">
      <div className="dot" />
      <div className={`panel ${isOrder ? 'blue-backround' : ''}`}>
        <div className="panel-title-timestp-row w-clearfix">
          <h5
            data-testid={`header-${requisitionActivityId}-${activityType}`}
            className="panel-h5"
          >
            {getCardHeader(activityType, userName)}
          </h5>
          <div className="time-stamp">{dateFormat(createdAt)}</div>
        </div>
        {userId && !isSystemEvent() && (
          <div className="user-info-row w-clearfix">
            <div className="user-info-block w-clearfix">
              <div className="fa-svg_user w-embed">
                <Icon iconName="account_circle" className="usa-icon--size-1" />
              </div>
              <div className="user-name">
                {currentUser.id !== userId ? (
                  <a className="a-tag" href={`mailto:${email}`}>
                    {userName}
                  </a>
                ) : (
                  userName
                )}
              </div>
            </div>
          </div>
        )}
        {comment && !isSystemEvent() && (
          <div className="panel-comments-seg">
            <div className="user-comments-title">Comment</div>
            <p className="user-comments-p">{comment}</p>
            {fileMetadata?.name && (
              <p className="user-uploaded-attachments">
                <span>{fileMetadata.name}</span>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CommentActivityCard.propTypes = {
  activity: PropTypes.shape({
    requisitionActivityId: PropTypes.string,
    comment: PropTypes.string,
    type: PropTypes.shape({
      name: PropTypes.string,
    }),
    createdUserInfo: PropTypes.shape({
      fullName: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    fileMetadata: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default CommentActivityCard;
