import React from 'react';
import {
  Button,
  Modal, connectModal
} from '@gsa/afp-component-library';
import { z } from 'zod';
import { FormGenerator } from '@gsa/afp-shared-form-utils';

const ReturnModal = ({
  tagNumber,
  handleCancel,
  handleReturn,
  isReOpen = false,
}) => {
  const schema = z.object({
    comment: z
        .string()
        .min(1, "This is a required field")
        .max(500, "Comment exceeds the 500 character limit"),
  })

  const content = {
    buttonControls: { submit: false, cancel: false },
    sections: [
      {
          fields: [
              {
                  id: "comment",
                  type: "textarea",
                  label: `Provide reason for ${isReOpen ? 're-opening' : 'return'}`,
                  required: true,
                  help: {
                      after: "500 characters allowed"
                  },
                  element: {
                      control: {
                          maxLength: 500,
                      }
                  }
              },
          ]
      }
  ]
  }

  return (
    <Modal
      onClose={handleCancel}
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={handleCancel}
            label="Cancel"
            className="padding-right-2"
          />
          <Button
            type="submit"
            variant="secondary"
            leftIcon={{ name: 'close' }}
            label={isReOpen ? 'Re-open request' : 'Return request'}
            form="return-modal"
          />
        </>
      }
    >
      <h2 className='font-body-md'>
        {isReOpen
          ? 'Are you sure you want to re-open the following request?'
          : 'Are you sure you want to return the following request to the customer?'}
      </h2>

      {isReOpen && (
        <div>
          Re-opening the request will return the record to the customer.
        </div>
      )}
      <div className="margin-top-2 padding-2 bg-primary-lightest padding">
        <strong>{tagNumber}</strong>
      </div>

      <FormGenerator
        className='margin-top-2'
        id='return-modal'
        schema={schema}
        content={content}
        onSubmit={(data) => handleReturn(data)}
      />
    </Modal>
  );
};

export default connectModal(ReturnModal);
