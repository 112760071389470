import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';
import ReferralRequestStatusBadge from '../../referral-request-status-badge';
import { Tooltip, Icon } from '@gsa/afp-component-library';

export default function VehicleDetails() {
  const { vehicleDetails, previousOptions, status } = useSelector(
    (state) => state.vehicleReferralReducer,
  );

  const vehicleType =
    vehicleDetails?.standardItem?.title?.split(',')[0] || emDashUnicode;

  const vehicleURL = `${
    window.AFP_CONFIG.appURLs.vms
  }/vehicles/${encodeURIComponent(vehicleDetails?.vin)}`;

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="grid-col-6 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <div className="margin-top-neg-2 display-flex">
            <div className="text-primary title-l">
              {vehicleDetails?.tagNumber}
            </div>
            <div className="margin-left-3 margin-top-1">
              <ReferralRequestStatusBadge requestStatus={status} />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-col-12 padding-top-1 padding-bottom-6">
        <div className="display-flex flex-column" data-testid="data-section">
          <div className="text-primary title-m">
            {vehicleDetails?.modelYear} {vehicleDetails?.vehicleMake?.makeName}{' '}
            {vehicleDetails?.vehicleModel?.modelDescription ||
              vehicleDetails?.modelCode}{' '}
            | VIN:{' '}
            {vehicleDetails?.vin ? (
              <Link to={vehicleURL} target="_blank">
                {vehicleDetails.vin}
              </Link>
            ) : (
              emDashUnicode
            )}
          </div>
        </div>
      </div>
      <div className="grid-col-2">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Vehicle type</strong>
          <div>{vehicleType}</div>
        </div>
      </div>
      <div className="grid-col-2">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>SIN</strong>
          <div>
            {vehicleDetails?.standardItem?.standardItemNumber || emDashUnicode}
            {vehicleDetails?.standardItem?.standardItemNumber &&
              vehicleDetails?.standardItem?.title && (
                <Tooltip
                  className="usa-button--unstyled margin-top-neg-1"
                  label={vehicleDetails.standardItem.title}
                  position="right"
                >
                  <Icon
                    iconName="info"
                    className="text-primary usa-icon--size-3 margin-left-05"
                  />
                </Tooltip>
              )}
          </div>
        </div>
      </div>
      <div className="grid-col-2">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Fuel type</strong>
          <div>{vehicleDetails?.fuel?.description || emDashUnicode}</div>
        </div>
      </div>
      <div className="grid-col-2">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Law enforcement designation</strong>
          <div>{vehicleDetails?.ald?.lawEnforcement || emDashUnicode}</div>
        </div>
      </div>
      <div className="grid-col-2">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Telematics exemption</strong>
          <div>{vehicleDetails?.isTelematicsExempt ? 'Yes' : 'No'}</div>
        </div>
      </div>
      <div className="grid-col-2">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Odometer reading</strong>
          <div>
            {vehicleDetails?.newOdometerReading?.odometer || emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-2 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Agency</strong>
          <div>
            {vehicleDetails?.customer?.customerAgency?.prefixedName ||
              emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-2 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Bureau</strong>
          <div>
            {vehicleDetails?.customer?.customerBureau?.prefixedName ||
              emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-2 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Office</strong>
          <div>
            {vehicleDetails?.customer?.customerPhysicalOffice?.prefixedName ||
              emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-2 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Customer account</strong>
          <div>{vehicleDetails?.customer?.customerId}</div>
          <div>{vehicleDetails?.customer?.accountName}</div>
        </div>
      </div>
      <div className="grid-col-2 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Legacy customer number</strong>
          <div>
            {vehicleDetails?.customer?.legacyCustomerNumber || emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-2"></div>
      <div className="grid-col-3 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Primary Customer POC</strong>
          <div className="text-wrap text-lowercase">
            {vehicleDetails?.primaryPoC?.email || emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-3 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>FSR</strong>
          <div className="text-wrap text-lowercase">
            {vehicleDetails?.fsrUserEmail || emDashUnicode}
          </div>
        </div>
      </div>
      <div className="grid-col-6"></div>
      <div className="grid-col-12 padding-top-3">
        <div className="display-flex flex-column" data-testid="data-section">
          <strong>Previously selected options</strong>
          <div>{previousOptions?.join(', ') || emDashUnicode}</div>
        </div>
      </div>
    </div>
  );
}
