import moment from 'moment';

export const VehicleStatus = {
  CANCELED: 'DE',
  IN: 'IN',
  CC: 'CC',
  DD: 'DD',
  SP: 'SP',
  AR: 'AR',
};

export const ReceiptStatus = {
  DELINQUENT: {
    LABEL: 'DELINQUENT',
    COLOR: 'Urgent-Dark',
  },
  PENDING: {
    LABEL: 'PENDING',
    COLOR: 'Info-Light',
  },
};

const formatVehicleStatusName = (value) => {
  let orderVehicleName;
  // eslint-disable-next-line default-case
  switch (value) {
    case VehicleStatus.CANCELED:
      orderVehicleName = 'Canceled';
      break;
    case VehicleStatus.IN:
      orderVehicleName = 'In production';
      break;

    case VehicleStatus.CC:
      orderVehicleName = 'Shipped';
      break;

    case VehicleStatus.DD:
      orderVehicleName = 'Delivered';
      break;

    case VehicleStatus.SP:
      orderVehicleName = 'Ordered';
      break;
    case VehicleStatus.AR:
      orderVehicleName = 'Agency received';
      break;
  }
  return orderVehicleName;
};

const formatDate = (date) => {
  //  Returns a date of format MM/DD/YYYY
  if (!date) {
    return '';
  }
  return moment(date.replace(/-/g, '/').replace(/T.+/, '')).format(
    'MM/DD/YYYY',
  );
};

const AGENCY_FILTER = 'agency_code';
const BUREAU_FILTER = 'bureau_code';
const OFFICE_FILTER = 'office_code';
const AGENCY_AND_BUREAU_AND_OFFICE = 'agency_bureau_office';

export {
  VehicleStatus as default,
  formatVehicleStatusName,
  formatDate,
  AGENCY_FILTER,
  AGENCY_AND_BUREAU_AND_OFFICE,
  BUREAU_FILTER,
  OFFICE_FILTER,
};
