import React from 'react';
import { ActivityTracker } from '@gsa/afp-component-library';
import { useSelector } from 'react-redux';
import {
  getFormattedComment,
  ReferralRequestStatusActivity
} from '../../utils/vehicle-referral-util';

const VehicleReferralActivities = () => {
  const { vehicleReferralActivities, currentReferralAction } = useSelector(
    (state) => state.vehicleReferralReducer,
  );

  const loadActivities = () => {
      const activities = vehicleReferralActivities?.map((activity) => {
        return {
          id: activity?.referralRequestActivityLogId,
          type: activity?.activityTitle || ReferralRequestStatusActivity[activity?.referralRequestStatusCode],
          date: activity?.createdAt,
          userName: activity?.createdByUser?.fullName,
          email: activity?.createdByUser?.email,
          comment: getFormattedComment(
            activity?.referralRequestComment,
            activity?.referralRequestStatusCode,
          ),
          // activityBody: <strong>{currentReferralAction}</strong>,
        };
      });
      return activities;
  };

  return (
    <>
      {vehicleReferralActivities?.length > 0 ? (
        <ActivityTracker activities={loadActivities()} />
      ) : (
        <div className='padding-2'>No activities to display</div>
      )}
    </> 
  );
};

export default VehicleReferralActivities;
