import { Modal, Button, connectModal, Alert, Spinner } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { notReferredSchema } from './not-referred-modal-schema';
import { generateNotReferredForm } from './not-referred-modal-form';

function NotReferredModal({
    onClose,
    isOpen,
    onSubmit,
    vehicle,
}) {
    const [modalFormContent, defaultValues] = generateNotReferredForm({
        comment: ''
    });

    const handleSubmit = async (data) => {
        onSubmit(data, vehicle);
    };

    const closeModal = () => {
        onClose()
    }

    return (
        <Modal
            isOpen={isOpen}
            variant="small"
            onClose={closeModal}
            actions={
                <div>
                    <Button
                        variant="unstyled"
                        onClick={closeModal}
                        label="Cancel"
                    />
                    <Button
                        label="Confirm"
                        type="submit"
                        form="modal-form" // important to target the form inside the modal
                    />
                </div>
            }
        >
            <>
                <div>
                    <h2>
                        Vehicle selected is not referred
                    </h2>

                    <p>
                    This vehicle has not been referred for replacement. Are you sure you want to proceed?
                    </p>
                </div>

                <FormGenerator
                    id="modal-form" // important to give the form an id so that the submit button can target it
                    schema={notReferredSchema}
                    content={modalFormContent}
                    defaultValues={defaultValues}
                    onSubmit={handleSubmit}
                />
            </>
        </Modal>
    );
}

export default connectModal(NotReferredModal);