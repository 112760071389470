import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  Checkbox,
  SelectDropdown,
  Form,
  TextInput,
  ErrorMessage,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_LABELS,
} from '../../non-standard-purchase/constants';
import { useDispatch, useSelector } from 'react-redux';
import {generateSignedUrlForOrders, getOrderAttachmentTypes} from '../../../requests/order';
import './OrderAttachmentUpload.scss';
import { UserTypes } from '../../../constants';
import {validateUploadedFile} from "../../../utilities/commonUtils.jsx";

const OrderAttachmentUpload = () => {
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [values, setValues] = useState({
    isInternalAttachment: false,
    fileType: -1,
    file: null,
    description: '',
  });
  const [fileAddErrors, setFileAddErrors] = useState(null);
  const [disableUpload, setDisableUpload] = useState(false);
  const { orderDetails, orderAttachments } = useSelector((state) => state.orderReducer);

  const onClickAddAttachment = () => {
    setShowAttachmentModal(true);
  };

  const handleFileChange = (e) => {
    const {fileError, isValidFormat} = validateUploadedFile(e);
    setFileAddErrors({
        ...fileAddErrors,
        file: fileError
    });
    setDisableUpload(!isValidFormat);
    setValues({ ...values, file: e });
  };

  const updateValue = (e, type) => {
    if (type === 'fileType' && fileAddErrors?.groupAssignment) {
      setFileAddErrors({
        ...fileAddErrors,
        groupAssignment: null,
      });
    }
    const updatedValues = { ...values };
    updatedValues[type] = e.target.value;
    setValues(updatedValues);
  };

  const updateCheckbox = (e, type) => {
    const updatedValues = { ...values };
    updatedValues[type] = e.target.checked;
    setValues(updatedValues);
  };

  const handleCancel = () => {
    setShowAttachmentModal(false);
  };

  const handleSubmitAttachmentForm = async () => {
    try {
      setFileAddErrors(null);

      let errorList = {};

      if (!values.file) {
        errorList = {
          ...errorList,
          file: 'Please add a Supporting file for upload',
        };
      }

      if (values.fileType === -1) {
        errorList = {
          ...errorList,
          groupAssignment: 'Please choose a valid File category',
        };
      }

      if (Object.keys(errorList).length > 0) {
        setFileAddErrors(errorList);
      } else {
        dispatch(generateSignedUrlForOrders({orderDetails, values}));
        setShowAttachmentModal(false);
        setValues({
          isInternalAttachment: false,
          fileType: -1,
          file: null,
          description: '',
        });
        setFileAddErrors(null);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const getInternalAttachmentInfo = () => {
    if (currentUser?.userType?.id !== UserTypes.GSA_EMPLOYEE) {
      return null;
    }
    return (
      <>
        <Alert type="warning" data-testid="order-internal-attachment-banner">
          If you would like to restrict this file’s access to internal GSA users
          only, please make sure to select the checkbox below.
        </Alert>

        <Checkbox
          id="order-attachment-is-internal"
          name="order-attachment-is-internal"
          value={values.isInternalAttachment}
          data-testid="order-attachment-internal-attachment"
          onChange={(e) => updateCheckbox(e, 'isInternalAttachment')}
          label="This file is internal to GSA and should not be accessible to customers or vendors"
        />
      </>
    );
  };

  const getFileCategoryTypes = () => {
    let types = [
      {
        label: '-select-',
        value: -1,
      },
    ];

    if (orderAttachments?.orderAttachmentTypesData.length > 0) {
      types = [
        ...types,
        ...orderAttachments?.orderAttachmentTypesData.map((i) => ({
          label: i.description,
          value: i.orderAttachmentTypeId,
        })),
      ];
    }

    return types;
  };

  return (
    <>
      {!!showAttachmentModal && (
        <div className="afp-modal-overlay modal-overlay">
          <Modal
            title="Upload file"
            onClose={handleCancel}
            className="upload-attachment-modal"
            variant="large"
            actions={
              <div>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  data-testid="order-attachment-upload-cancel-button"
                  onClick={handleCancel}
                  label="Cancel"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  disabled={disableUpload}
                  data-testid="order-attachment-upload-submit-button"
                  onClick={handleSubmitAttachmentForm}
                  label="Add file(s)"
                />
              </div>
            }
          >
            <Form
              name="order-attachment-upload"
              className="order-attachments-upload-form"
            >
              <FileUpload
                acceptableFiles={ACCEPT_FILE_TYPES}
                data-testid="order-attachment-upload-file"
                acceptableFilesLabel={ACCEPT_FILE_LABELS}
                onChange={handleFileChange}
              />
              {fileAddErrors?.file && (
                <ErrorMessage id="file-error">
                  {fileAddErrors?.file}
                </ErrorMessage>
              )}
              <Alert type="info">
                Help prevent a privacy incident by ensuring that any supporting
                document uploaded here does not contain personally identifiable
                information (PII).
              </Alert>

              {getInternalAttachmentInfo()}

              <SelectDropdown
                id="groupAssignment"
                onChange={(e) => updateValue(e, 'fileType')}
                value={values.fileType}
                label="File category"
                data-testid="order-attachment-upload-file-type"
                required
                options={getFileCategoryTypes()}
              />
              {fileAddErrors?.groupAssignment && (
                <ErrorMessage id="group-assignment-error">
                  {fileAddErrors?.groupAssignment}
                </ErrorMessage>
              )}
              <TextInput
                type="textarea"
                placeholder="Type comment here..."
                value={values.description}
                onChange={(e) => updateValue(e, 'description')}
                data-testid="order-attachment-upload-description"
                maxLength={100}
                hint={`${100} characters allowed`}
              />
            </Form>
          </Modal>
        </div>
      )}

      <Button
        type="button"
        onClick={onClickAddAttachment}
        leftIcon={{ name: 'add' }}
        label="Add attachment"
        variant="outline"
        data-testid="order-attachment-upload-button"
      />
    </>
  );
};


export default OrderAttachmentUpload;
