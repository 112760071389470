import moment from 'moment';
import { emDashUnicode } from '../../../constants/constants';

const getContractShipmentDate = (row) => {
  const daysOffset =
    (row.baseShipmentDays ? row.baseShipmentDays : 0) +
    (row.additionalShipmentDays ? row.additionalShipmentDays : 0);
  return moment(row.createdAt).add(daysOffset, 'days').format('MM/DD/YYYY');
};

export const getTableColumns = (rows) => {
  const simplifiedRows = rows.map((item) => ({
    id: item?.orderVehicleId,
    vin: item?.serialNumberVin,
    von: item?.vehicleOrderNumber,
    orderId: item?.orderId,
    statusCode: item?.statusCode,
    shipmentDate: item?.shipmentDate,
    receivedAt: item?.receivedAt,
    deliveryDate: item?.deliveryDate,
    rpnNumber: item?.rpnNumber,
    deliveryContact: `${item?.deliveryContactFirstName || ''} ${item?.deliveryContactLastName || ''}`.trim() || emDashUnicode,
    agencyContact: {
      firstName: item?.agencyContactFirstName,
      lastName: item?.agencyContactLastName,
      phoneCountryCode: item?.agencyContactPhoneCountryCode,
      phoneNumber: item?.agencyContactPhoneNumber,
      phoneExtension: item?.agencyContactPhoneExtension,
      faxCountryCode: item?.agencyContactFaxCountryCode,
      email: item?.agencyContactEmail
    },
    deliveryAddress: {
      entityName: item?.deliveryAddressEntityName,
      city: item?.deliveryAddressCity,
      zipcode: item?.deliveryAddressZipcode,
      addressLine1: item?.deliveryAddressaddressLine1,
      addressLine2: item?.deliveryAddressaddressLine2,
      stateCode: item?.deliveryAddressStateCode,
    },
    deliveryContact: {
      firstName: item?.deliveryContactFirstName,
      lastName: item?.deliveryContactLastName,
      phoneCountryCode: item?.deliveryContactPhoneCountryCode,
      phoneNumber: item?.deliveryContactPhoneNumber,
      phoneExtension: item?.deliveryContactPhoneExtension,
      faxCountryCode: item?.deliveryContactFaxCountryCode,
      email: item?.deliveryContactEmail,
    },
    caseNumber: item?.caseNumber || emDashUnicode,
    standardItemId: item?.standardItemCode || emDashUnicode,
    friendlyName: item?.friendlyName || emDashUnicode,
    requisitionNumber: item?.requisitionNumber || emDashUnicode,
    status: item?.status || emDashUnicode,
    createdDateTime: item?.createdAt ? new Date(item.createdAt).toLocaleDateString() : emDashUnicode,
    contractShipmentDate: getContractShipmentDate(item) || emDashUnicode,
    vehicleTypeCode: item?.vehicleTypeCode || emDashUnicode,
    bureauCode: item?.bureauCode || emDashUnicode,
    agencyCode: item?.agencyCode || emDashUnicode,
    modelCode: item?.modelCode || emDashUnicode,
    quantity: item?.quantity || emDashUnicode,
    totalSellingPrice: item?.totalSellingPrice || emDashUnicode,
    standardItemCode: item?.standardItemCode || emDashUnicode,
    createdByUser: item.createdUserInfo
      ? `${item.createdUserInfo.firstName} ${item.createdUserInfo.lastName}`
      : emDashUnicode,
    createdByUserEmail: item.createdUserInfo?.email || emDashUnicode,
    bureau: item.bureauInfoName || emDashUnicode,
    office: item.officeInfoName || emDashUnicode,
    upiidNumber: item.upiidNumber || emDashUnicode,
    model: item.modelInfo?.modelName || emDashUnicode,
    approvedByUser: item.approvedByUserInfo
      ? `${item.approvedByUserInfo.firstName} ${item.approvedByUserInfo.lastName}`
      : emDashUnicode,
    approvedByUserEmail: item.approvedByUserInfo?.email || emDashUnicode,
    agencyInfoName: item.agencyInfoName || emDashUnicode,
  }));

  return simplifiedRows;
};