import { PageTitle, Alert } from '@gsa/afp-component-library';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import VehicleRequestTabs from './components/vehicle-selection/vehicle-tabs';
import Breadcrumbs from '../leasing/components/Breadcrumbs';
import VehicleReferralTable from './vehicle-referral-table';
import VehicleReferralFilters from './vehicle-referral-filters';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NotFound, useTitle } from '@gsa/afp-shared-ui-utils';
import { getRequestSuccessMsg } from './utils/vehicle-referral-util';
import { resetVehicleReferralState } from '../../reducers/vehicle-referral';
import GSALogoSVG from '../../assets/images/gsa-icon.svg';

export default function VehicleReferral() {
  useTitle('Vehicle Referral | Leasing');
  const { assetId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { submitted, tagNumber, status } = location.state || {};
  const [showingSubmittedAlert, setShowingSubmittedAlert] = useState(submitted);

  useEffect(() => {
    setShowingSubmittedAlert(submitted);
  }, [submitted]);

  useEffect(() => {
    setNotFound(false);
    dispatch(resetVehicleReferralState());
  }, [assetId]);

  const [notFound, setNotFound] = useState(false);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs />
        {assetId && (
          <div className='display-flex flex-justify'>
            <img className="margin-right-1" src={GSALogoSVG} alt="GSA_logo" />
            <Link
              to={`${window.AFP_CONFIG.appURLs.catalog}/public/fvs/vehicle-standards`}
              target="_blank"
            >
              Federal Vehicle Standards
            </Link>
          </div>
        )}
      </div>
      {notFound ? (
        <NotFound
          goBackLink={{
            href: '/lease/vehicle-referral',
            text: 'Go back to lease requisitions start',
          }}
        />
      ) : (
        <>
          {showingSubmittedAlert && tagNumber && (
            <Alert
              type="success"
              showClose
              onClose={() => setShowingSubmittedAlert(false)}
            >
              {getRequestSuccessMsg(status, tagNumber)}
            </Alert>
          )}
          <PageTitle title={assetId ? "Leased vehicle request details" : "Leased vehicle requests"} />
          <div className="margin-top-neg-1">
            Use this feature to view vehicles referred for replacement, request
            a replacement, and review and approve pending requests
          </div>
          {!assetId && (
            <>
              <VehicleReferralFilters />
              <VehicleReferralTable />
            </>
          )}

          {assetId && <VehicleRequestTabs setNotFound={setNotFound} />}
        </>
      )}
    </>
  );
}
