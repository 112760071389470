import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  connectModal,
  Modal,
  Button,
  Spinner,
  useModal,
  TextInput,
} from '@gsa/afp-component-library';
import { useMutation, useLazyQuery } from '@apollo/client';
import CheckedIcon from '../../assets/images/checked-icon';
import { SUBMIT_TO_GSA, GET_REQUISITION_CART } from '../../services/data-layer';
import './PlaceOrderModal.scss';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { PLACE_ORDER } from '../VehicleRequisition/constants/VehicleRequisitionConstants';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { isAreqType } from '../non-standard-purchase/constants';

const PlaceOrderModal = ({
  requisitionId,
  requisitionName,
  reference = () => {},
  requisitionStatus = '',
}) => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);

  const { isOpen, openModal, closeModal } = useModal();
  const navigate = useNavigate();
  let submittingCustomerResponse = false;
  // TODO: With validations
  const [getDraftDataWhenOpenModal] = useLazyQuery(GET_REQUISITION_CART, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getRequisition }) => {
      if (!getRequisition?.validations ||
        getRequisition?.validations?.validationErrors.length === 0) {
        openModal();
      }
    },
  });

  const placeOrderModal = () => {
    const [approvalComment, setApprovalComment] = useState('');

    const [submitToGSA, { loading: loadingSubmitToGSA }] = useMutation(
      SUBMIT_TO_GSA,
      {
        onError: () => {
          // TODO
          // not covered in user story yet
          closeModal();
        },
        onCompleted: (data) => {
          const { operationStatus, requisitionCartValidations } =
            data.submitToGSA;
          if (operationStatus && !requisitionCartValidations) {
            navigate(
              `/my-requisitions?requisitionId=${requisitionId}&draftName=${requisitionName}&operationStatus=${operationStatus}`,
            );
          } else {
            dispatch({
              type: VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE,
              payload: requisitionCartValidations,
            });
            dispatch({
              type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
              payload: true,
            });
            closeModal();
          }
        },
      },
    );

    const [getDraftData, { loading: loadingGetDraftData }] = useLazyQuery(
      GET_REQUISITION_CART,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ getRequisition }) => {
          if (!getRequisition?.validations ||
            getRequisition?.validations?.validationErrors.length === 0) {
            submitToGSA({
              variables: {
                requisitionId,
                approvalComment: approvalComment.trim(),
                contractNumber: isAreqType(state?.requisitionType)
                  ? state?.selectedContract?.contract
                      ?.contractNumber
                  : undefined,
              },
            });
          } else {
            navigate.go(0);
          }
        },
      },
    );

    const Actions = ({ onCancel, onSave }) => {
      return (
        <footer>
          <div className="grid-row flex-row flex-align-center grid-gap-lg">
            <div>
              <Button
                type="button"
                variant="unstyled"
                onClick={onCancel}
                data-testid="place-order-cancel-btn"
                label="Cancel"
              />
            </div>
            <div>
              <Button
                type="button"
                onClick={onSave}
                data-testid="place-order-btn"
                label={PLACE_ORDER}
              />
            </div>
          </div>
        </footer>
      );
    };

    Actions.propTypes = {
      onCancel: PropTypes.func.isRequired,
      onSave: PropTypes.func.isRequired,
    };

    const onChangeComment = (event) => {
      setApprovalComment(event.target.value);
    };

    const onPlaceOrder = () => {
      getDraftData({ variables: { requisitionId } });
    };

    if (loadingSubmitToGSA || loadingGetDraftData || submittingCustomerResponse) {
      return (
        <div className="afp-modal-overlay requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      );
    }

    return (
      <Modal
        title={
          <h2>
            Are you sure you would like to approve the following requisition and
            place the order?
          </h2>
        }
        onClose={closeModal}
        actions={<Actions onCancel={closeModal} onSave={onPlaceOrder} />}
      >
        <div className="place-order-comment-section">
          <p>{requisitionName}</p>
          <span>Provide comments for this approval</span>
          <TextInput
            id="place-order-modal-comment"
            type="textarea"
            maxLength={500}
            placeholder="Type comment here..."
            value={approvalComment}
            onChange={onChangeComment}
            data-testid="place-order-modal-comment"
            hint="500 characters allowed"
          />
        </div>
      </Modal>
    );
  };

  const ConnectedPlaceOrderModal = connectModal(placeOrderModal);

  const onOpenModalClick = () => {
    getDraftDataWhenOpenModal({
      variables: { requisitionId },
    });
  };

  return (
    <>
      <button
        data-testid="review-place-order-button"
        type="button"
        className="place-order-button"
        onClick={async () => {
          let submissionErrored = false;
          if (
            requisitionStatus === RequisitionStatus.PENDING_CUSTOMER_RESPONSE ||
            requisitionStatus === RequisitionStatus.ORDERING_ADMIN_APPROVAL
          ) {
              submittingCustomerResponse = true;
              submissionErrored = await reference.current.submitCustomerResponse();
              submittingCustomerResponse = false;
          }
          if (!submissionErrored) {
            onOpenModalClick();
          }
        }}
      >
        <CheckedIcon />
        <span className="button-text">Place order</span>
      </button>
      <ConnectedPlaceOrderModal
        data-testid="place-order-modal"
        isOpen={isOpen}
      />
    </>
  );
};

PlaceOrderModal.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  reference: PropTypes.func,
  requisitionStatus: PropTypes.string,
};

PlaceOrderModal.defaultProps = {
  reference: () => {},
  requisitionStatus: '',
};

export default PlaceOrderModal;
