import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_AGENCIES_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const LeasingAgencyFilterItem = ({
  filter,
  subject,
  operation,
  placeholder = '-Select agency-',
  defaultValueSelected = false,
}) => {
  const placeholderOption = {
    value: '',
    label: placeholder,
    defaultValue: true,
  };
  const { setFilters } = useFilterPanel();
  const [options, setOptions] = useState([placeholderOption]);

  const [
    fetchAgencyFilterOptions,
    { data: agencyOptions, loading: agencyOptionsLoading },
  ] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAgencyFilterOptions({
      variables: {
        operation: operation || 'view',
        subject: subject || StoreSubjects.Order,
        order: [['agencyCode', 'ASC']],
      },
    });
  }, []);

  useEffect(() => {
    if (agencyOptions?.getAgenciesByPermission) {
      const formattedAgencyOptions = agencyOptions.getAgenciesByPermission.map(
        (agencyDetails) => ({
          value: agencyDetails.id,
          label: agencyDetails.name,
        }),
      );
      setOptions(
        formattedAgencyOptions
          ? [placeholderOption, ...formattedAgencyOptions]
          : [placeholderOption],
      );

      if (defaultValueSelected && formattedAgencyOptions?.length === 1) {
        setOptions(formattedAgencyOptions);
        setFilters({
          type: 'setOne',
          fetchNewData: true,
          filter: { ...filter, value: formattedAgencyOptions[0].value },
        });
      }
    }
  }, [agencyOptions]);

  useEffect(() => {
    if (defaultValueSelected && !filter.value && options.length === 1) {
      setFilters({
        type: 'setOne',
        fetchNewData: true,
        filter: { ...filter, value: options[0].value },
      });
    }
  }, [filter]);

  if (agencyOptionsLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

LeasingAgencyFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LeasingAgencyFilterItem;
