import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  AFPTable,
  Spinner,
  Pagination,
  Button,
  EmptyState,
  useModal,
} from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import VehicleStatusTag from './vehicle-status-tag';
import ReceiptStatusTag from './receipt-status-tag';
import { VehicleStatus, formatDate } from '../constants/vehicle-constants';
import useVehicleState from '../useVehicleState';
import { useVehicleFilter } from '../vehicle-filter-provider';
import { resetFocusToFirstElement } from '../../../utilities/commons';
import { getTableColumns } from './tableHelpers';
import ReceiptStatusModal from './receipt-status-modal/receipt-status-modal';
import { UPDATE_ORDER_VEHICLE_RECEIVED } from '../../../services/data-layer';
import { emDashUnicode } from '../../../constants/constants';
import { Link } from 'react-router-dom';

const SubComponent = ({ row: { original } }) => {
  const deliveryAddress = original?.deliveryAddress;
  const { vehicleTypes } = useVehicleFilter();

  const vehicleType = vehicleTypes.find(
    (type) => type.code === original?.vehicleTypeCode,
  );

  let state = deliveryAddress?.stateCode;
  if (state) {
    state = state.includes('-')
      ? deliveryAddress?.stateCode.split('-')[1]
      : deliveryAddress?.stateCode;
  }

  return (
    <>
      <div className="requisition-number-container">
        <span className="requisition-number">
          {original?.vin || original?.von}
        </span>
      </div>

      <div className="sub-component">
        <div className="tablet:grid-col margin-bottom-3 margin-right-4">
          <div className="sub-row">
            <span className="text-bold">Delivery location</span>
            <div style={{ textAlign: 'right' }}>
              {!deliveryAddress ? (
                emDashUnicode
              ) : (
                <div>
                  {deliveryAddress?.entityName ? (
                    <div>{deliveryAddress.entityName}</div>
                  ) : null}
                  {deliveryAddress?.addressLine1 ? (
                    <div>{deliveryAddress.addressLine1}</div>
                  ) : null}
                  {deliveryAddress?.addressLine2 ? (
                    <div>{deliveryAddress.addressLine2}</div>
                  ) : null}
                  <div>
                    {[deliveryAddress?.city, state, deliveryAddress?.zipcode]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="sub-row">
            <span className="text-bold">Requisition number</span>
            <span>{original?.requisitionNumber}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Agency</span>
            <span>{original?.agencyInfoName || emDashUnicode}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Bureau</span>
            <span>{original?.bureau || emDashUnicode}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Office</span>
            <span>{original?.office || emDashUnicode}</span>
          </div>
        </div>
        <div className="tablet:grid-col margin-bottom-3 margin-right-4">
          <div className="sub-row">
            <span className="text-bold">Delivery point of contact</span>
            <div style={{ textAlign: 'right' }}>
              {!original?.deliveryContact ? (
                emDashUnicode
              ) : (
                <div>
                  {original?.deliveryContact?.firstName ||
                  original?.deliveryContact?.lastName ? (
                    <div>
                      {[
                        original?.deliveryContact?.firstName,
                        original?.deliveryContact?.lastName,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </div>
                  ) : null}
                  {original?.deliveryContact?.phoneNumber ? (
                    <div>
                      {[
                        original?.deliveryContact?.phoneCountryCode,
                        original?.deliveryContact?.phoneNumber,
                        original?.deliveryContact?.phoneExtension,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </div>
                  ) : null}
                  {original?.deliveryContact?.email ? (
                    <div>{original?.deliveryContact?.email}</div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="sub-row">
            <span className="text-bold">Contract shipment date</span>
            <span>{original?.contractShipmentDate}</span>
          </div>
          <div className="sub-row">
            <span className="text-bold">Agency point of contact</span>
            <div style={{ textAlign: 'right' }}>
              {!original?.agencyContact ? (
                emDashUnicode
              ) : (
                <div>
                  {original?.agencyContact?.firstName ||
                  original?.agencyContact?.lastName ? (
                    <div>
                      {[
                        original?.agencyContact?.firstName,
                        original?.agencyContact?.lastName,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </div>
                  ) : null}
                  {original?.agencyContact?.phoneNumber ? (
                    <div>
                      {[
                        original?.agencyContact?.phoneCountryCode,
                        original?.agencyContact?.phoneNumber,
                        original?.agencyContact?.phoneExtension,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </div>
                  ) : null}
                  {original?.agencyContact?.email ? (
                    <div>{original?.agencyContact?.email}</div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="sub-row">
            <span className="text-bold">Vehicle type</span>
            <span>{vehicleType?.title || emDashUnicode}</span>
          </div>
        </div>
      </div>
    </>
  );
};

SubComponent.propTypes = {
  row: PropTypes.shape(Object).isRequired,
};

const VehicleTable = () => {
  const {
    onOrderVehiclesLoading,
    orderRows,
    orderCount,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    orderBy,
    orderDirection,
    setOrderDirection,
    setOrderBy,
    isReset,
    setResetPagination,
    getVehicleOrders,
  } = useVehicleState();

  const { filters } = useVehicleFilter();

  const getVehicleIdNumber = ({ vin, von }) => {
    return vin || von;
  };

  const OrderLink = ({ value, row }) => {
    const navigate = useNavigate();
    return (
      <Link
        to={`/order/${row.original.orderId}`}
      >
        {value}
      </Link>
    );
  };

  OrderLink.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
      original: PropTypes.shape({
        orderId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  const ReceiptDate = ({ value, row: { original } }) => {
    const { isOpen, openModal, closeModal } = useModal();

    const updateOrderVehicleReceived = useMutation(
      UPDATE_ORDER_VEHICLE_RECEIVED,
    );

    if (value !== null) {
      return original.statusCode === VehicleStatus.AR
        ? formatDate(value)
        : null;
    }

    if (!original.vin || original.statusCode === VehicleStatus.CANCELED) {
      return null;
    }

    return (
      <>
        <ReceiptStatusModal
          isOpen={isOpen}
          onClose={closeModal}
          updateOrderVehicleReceived={updateOrderVehicleReceived}
          orderVehicleId={original.id}
          getOrders={getVehicleOrders}
        />
        <Button
          variant="outline"
          onClick={openModal}
          leftIcon={{ name: 'calendar' }}
          style={{ whiteSpace: 'nowrap' }}
          label="Enter date"
        />
      </>
    );
  };

  ReceiptDate.propTypes = {
    value: PropTypes.string,
    row: PropTypes.shape({
      original: PropTypes.shape({
        statusCode: PropTypes.string,
        id: PropTypes.string,
        vin: PropTypes.string,
      }),
    }),
  };

  ReceiptDate.defaultProps = {
    value: null,
    row: {
      original: {
        statusCode: '',
        id: '',
        vin: '',
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        Header: 'VIN/VON',
        accessor: 'vin',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          const value = getVehicleIdNumber(original);
          return value || emDashUnicode;
        },
      },
      {
        Header: 'Order Number',
        accessor: 'rpnNumber',
        Cell: OrderLink,
      },
      {
        Header: 'Vehicle status',
        accessor: 'statusCode',
        Cell: (cell) => {
          const { value } = cell;
          return <VehicleStatusTag value={value} />;
        },
      },
      {
        Header: 'Delivery date',
        accessor: 'deliveryDate',
        Cell: ({ value }) => formatDate(value) || emDashUnicode,
      },
      {
        Header: 'Agency received date',
        accessor: 'receivedAt',
        Cell: ReceiptDate,
      },
      {
        Header: 'Receipt status',
        accessor: 'receiptDate',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return <ReceiptStatusTag original={original} />;
        },
      },
    ],
    [],
  );
  const data = useMemo(() => {
    if (!orderRows || !orderRows.length) {
      return [];
    }
    return getTableColumns(orderRows);
  }, [orderRows]);

  const refetchOrders = () => {
    getVehicleOrders({
      variables: {
        query: {
          offset: (currentPage - 1) * perPage,
          limit: perPage,
          orderBy,
          orderDirection,
          filters,
        },
      },
    });
  };

  useEffect(() => {
    refetchOrders();
    setResetPagination(true);
  }, [filters, orderBy, orderDirection]);

  useEffect(() => {
    refetchOrders();
  }, [currentPage, perPage, isReset]);

  const handlePaginationChange = (newCurPage, newPerPage) => {
    if (newPerPage !== perPage) {
      setCurrentPage(1);
      setPerPage(newPerPage);
      setResetPagination(true);
    } else {
      setCurrentPage(newCurPage);
      setPerPage(newPerPage);
    }
    resetFocusToFirstElement();
  };

  return (
    <>
      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        expandable
        renderRowSubComponent={SubComponent}
        data={onOrderVehiclesLoading ? [] : data || []}
        onSort={(sortOrder) => {
          const sortOrderParts = sortOrder.split(' ');

          let newOrderBy = sortOrderParts[0];
          const newOrderDirection = sortOrderParts[1];

          newOrderBy = newOrderBy.replace(/`/g, '');

          switch (newOrderBy) {
            case 'rpnNumber':
              newOrderBy = 'rpn_number';
              break;
            case 'receivedAt':
              newOrderBy = 'received_at';
              break;
            case 'vin':
              newOrderBy = 'serial_number_vin';
              break;
            case 'statusCode':
              newOrderBy = 'status_code';
              break;
            case 'deliveryDate':
              newOrderBy = 'delivery_date';
              break;
            case 'receiptDate':
              newOrderBy = 'received_at';
              break;
            default:
              break;
          }

          setOrderBy(newOrderBy);
          setOrderDirection(newOrderDirection);
        }}
      />
      {onOrderVehiclesLoading && <Spinner className="margin-y-9" />}
      {!onOrderVehiclesLoading && (
        <>
          <Pagination
            buttonVariant="primary"
            boundaryCount={1}
            variant="advanced"
            itemsCount={orderCount}
            currentPage={currentPage}
            itemsPerPage={perPage}
            siblingCount={1}
            isReset={isReset}
            onPageChange={handlePaginationChange}
            defaultSort={`${orderBy}, ${orderDirection}`}
          />
          {!orderRows?.length && (
            <EmptyState
              data-testid="table-no-items"
              containerStyles="table-no-items"
              topText="No vehicles available"
              topTextStyles="title"
              bottomText="There are no matches for the filtered values at left."
              bottomTextStyles="description"
            />
          )}
        </>
      )}
    </>
  );
};

export default VehicleTable;
