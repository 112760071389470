import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { useGetDistinctStandardItems } from '../requests/vehicle-referral';

const placeholderOption = {
  value: '',
  label: '-Select-',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const StandardItemFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);
  const [filterLoading, setFilterLoading] = useState(true);

  useGetDistinctStandardItems()
    .then(({ data }) => {
      setFilterLoading(false);
      const formattedOptions =
        data?.length > 0 &&
        data.map((standardItem) => ({
          value: standardItem.standardItemNumber,
          label: standardItem.standardItemNumber + ' - ' + standardItem.title,
        }));
      setOptions(
        formattedOptions
          ? [placeholderOption, ...formattedOptions]
          : [placeholderOption],
      );
    })
    .catch((error) => {
      console.error('Error fetching standard items', error);
      setFilterLoading(false);
    });

  if (filterLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

StandardItemFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default StandardItemFilterItem;
