import { useDispatch, useSelector } from 'react-redux';
import {
  RadioButton,
  PageTitle,
  Button,
  useModal,
} from '@gsa/afp-component-library';
import fileDownload from 'js-file-download';
import {
  useGenerateReadSignedUrl,
  useDocumentManagementAttachments,
  useDocMetaData,
} from '../../../../requests/vehicle-referral';
import TurnInVehicle from '../turn-in/turn-in-vehicle';
import {
  OPTIONS,
  VEHICLE_REFERRAL_STATUS,
} from '../../utils/vehicle-referral-util';
import DoNotReplaceVehicle from '../do-not-replace/do-not-replace-vehicle';
import './option-selection.scss';
import ReplaceVehicle, {
  REPLACE_VEHICLE_STEPS,
} from '../../replace-vehicle/replace-vehicle';
import SelectedVehicle from '../selected-vehicle/selected-vehicle';
import { setAgencyGuidanceDocument } from '../../../../reducers/vehicle-referral';
import ChangeSelectionModal from './change-selection-modal';
import { useState } from 'react';

export default function OptionSelection({ selectedOption, setSelectedOption }) {
  const [option, setOption] = useState(null);
  const { isOpen, closeModal, openModal } = useModal();
  const dispatch = useDispatch();
  const { agencyGuidanceDocument, currentStep, status, vehicleDetails } =
    useSelector((state) => state.vehicleReferralReducer);
  const [getSignedUrl] = useGenerateReadSignedUrl();
  const [getDocMetadata] = useDocMetaData();
  const AGENCY_DOCUMENT_TYPE = 'agency_guidance';

  useDocumentManagementAttachments({
    apolloOptions: {
      skip: !vehicleDetails?.customer?.customerAgency?.id,
    },
    variables: {
      docType: AGENCY_DOCUMENT_TYPE,
      agencyCode: vehicleDetails?.customer?.customerAgency?.id,
    },
  }).then(({ data }) => {
    dispatch(setAgencyGuidanceDocument(data));
  });

  const downloadAttachment = async () => {
    getDocMetadata({
      variables: {
        id: agencyGuidanceDocument?.metadataId,
      },
    }).then(async ({ data }) => {
      if (data) {
        const signedUrl = await getSignedUrl({
          variables: {
            fileKey: data?.fileLocation,
            fileType: data?.fileMimeType,
          },
        });
        if (signedUrl?.data) {
          // Download file using signedUrl.
          const response = await fetch(signedUrl?.data, {
            method: 'GET',
            headers: { 'Content-Type': agencyGuidanceDocument?.type },
          });
          // Grab blob from response
          const blob = await response.blob();
          // Browser starts download
          fileDownload(blob, agencyGuidanceDocument?.name);
        }
      }
    });
  };

  const handleSelectionChange = (selection) => {
    if (
      (status === VEHICLE_REFERRAL_STATUS.DRAFT ||
        status === VEHICLE_REFERRAL_STATUS.RETURNED) &&
      selectedOption !== selection &&
      selectedOption
    ) {
      setOption(selection);
      openModal();
    } else {
      setSelectedOption(selection);
    }
  };

  const handleCancel = () => {
    closeModal();
    setSelectedOption(option);
    setOption(null);
  };

  const handleChangeClose = () => {
    closeModal();
    setOption(null);
  };

  const isEditable =
    status === VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION ||
    status === VEHICLE_REFERRAL_STATUS.DRAFT ||
    status === VEHICLE_REFERRAL_STATUS.RETURNED;

  return (
    <div>
      <ChangeSelectionModal
        isOpen={isOpen}
        handleClose={handleChangeClose}
        handleCancel={handleCancel}
      />

      {currentStep === REPLACE_VEHICLE_STEPS.GARAGE && (
        <div className="actions-header with-options">
          <PageTitle title="Select a request action" />
          <Button
            className="agency-guidance-btn"
            type="button"
            variant="outline"
            onClick={() => {
              downloadAttachment();
            }}
            disabled={!agencyGuidanceDocument?.contentURL}
            label="Agency guidance"
          />
        </div>
      )}

      {isEditable && currentStep === REPLACE_VEHICLE_STEPS.GARAGE && (
        <>
          <div className="option-selections-container">
            <RadioButton
              key={'replace-vehicle-button'}
              name={'replace-vehicle'}
              label={'Replace vehicle'}
              value={OPTIONS.REPLACE}
              checked={OPTIONS.REPLACE === selectedOption}
              onChange={() => {
                handleSelectionChange(OPTIONS.REPLACE);
              }}
            />

            <RadioButton
              key={'turn-in-button'}
              name={'turn-in'}
              label={'Turn in vehicle'}
              value={OPTIONS.TURNIN}
              checked={OPTIONS.TURNIN === selectedOption}
              onChange={() => {
                handleSelectionChange(OPTIONS.TURNIN);
              }}
            />

            <RadioButton
              key={'do-not-replace-button'}
              name={'do-not-replace'}
              label={'Do not replace'}
              value={OPTIONS.KEEP}
              checked={OPTIONS.KEEP === selectedOption}
              onChange={() => {
                handleSelectionChange(OPTIONS.KEEP);
              }}
            />
          </div>
        </>
      )}

      {currentStep !== REPLACE_VEHICLE_STEPS.GARAGE && (
        <>
          <div className="actions-header">
            <PageTitle
              className="replace-vehicle-header"
              title="Replace vehicle:"
            />
            <Button
              className="agency-guidance-btn"
              type="button"
              variant="outline"
              onClick={() => {
                downloadAttachment();
              }}
              disabled={!agencyGuidanceDocument?.contentURL}
              label="Agency guidance"
            />
          </div>

          <SelectedVehicle />
        </>
      )}

      {selectedOption === OPTIONS.TURNIN && (
        <TurnInVehicle section={OPTIONS.TURNIN} />
      )}

      {selectedOption === OPTIONS.REPLACE && <ReplaceVehicle />}

      {selectedOption === OPTIONS.KEEP && (
        <DoNotReplaceVehicle section={OPTIONS.KEEP} />
      )}
    </div>
  );
}
