import ContactBuyingPopover from "../../../../../components/ContactBuyingPopover/ContactBuyingPopover.jsx";
import SearchBySIN from "../../../../../components/SearchBySIN/SearchBySIN.jsx";
import VehicleList from "../../../../vehicle-listings/VehicleList.jsx";
import { Pagination } from '@gsa/afp-component-library';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVehicleType, setVehicleListingsPagination } from "../../../../../reducers/leasing.jsx";
import { setVehicleListings } from "../../../../../reducers/leasing.jsx";
import { useGetRequisitionSins, useGetStandardItems, useGetVehicleGroupTypesLazy } from "../../../../../requests/leasing.jsx";
import {
    setSelectedVehicleSubType
} from "../../../../../reducers/leasing.jsx";
import VehicleListingFilters from "../vehicle-listing-filters/vehicle-listing-filters.jsx";
import { Spinner } from '@gsa/afp-component-library';

export const VehicleListings = ({onSelectItem, getOnVehicleTypeChange, inline, replacement, standardItemsYear}) => {
    const {
        vehicleListingsPagination,
        selectedVehicleType,
        selectedVehicleSubType,
        availableVehicleSubTypes,
        selectedStandardItem,
        vehicleListings,
    } = useSelector(state => state.leasingReducer);

    const [resetPagination] = React.useState(false)
    const [getVehicleGroupTypes, { loading: loadingVehicleGroupTypes }] = useGetVehicleGroupTypesLazy();
    const [getRequisitionSins, { loading: loadingRequisitionSins }] = useGetRequisitionSins();
    const [getStandardItems, { loading: loadingStandardItems }] = useGetStandardItems();
    const [cachedVehicleTypeIds, setVehicleTypeIds] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedVehicleType && getOnVehicleTypeChange) {
            getListing([selectedVehicleType], vehicleListingsPagination.currentPage, vehicleListingsPagination.itemsPerPage);
        }
    }, [selectedVehicleType])

    const getListing = (vehicleTypeIds, currentPage, itemsPerPage) => {
        if (replacement) {
            getStandardItems({
                variables: {
                    filters: {
                        operator: "AND",
                        value: [
                            {
                                key: "vehicleType",
                                operator: "IN",
                                value: vehicleTypeIds || cachedVehicleTypeIds,
                            },
                            {operator: "EQ", key: "year", value: standardItemsYear}, 
                            {operator: "EQ", key: "deletedAt", value: null},
                            {operator: "EQ", key: "isActive", value: 1},
                        ]
                    },
                    order: "standardItemNumber DESC",
                    offset: (currentPage - 1) * itemsPerPage,
                    limit: itemsPerPage,
                    groupBy: ["standardItemNumber"]
                }
            }).then(({ data, dispatch }) => {
                dispatch(setVehicleListings(data));
                if (vehicleTypeIds) setVehicleTypeIds(vehicleTypeIds);
            });
        } else {
            getRequisitionSins({
                variables: {
                    vehicleTypeIds: vehicleTypeIds || cachedVehicleTypeIds,
                    order: [
                        {
                            sortBy: 'standardItemNumber',
                            sortOrder: 'DESC',
                        },
                    ],
                    offset: (currentPage - 1) * itemsPerPage,
                    limit: itemsPerPage,
                    leasingQuery: true
                }
            }).then(({ data, dispatch }) => {
                dispatch(setVehicleListings(data));
                if (vehicleTypeIds) setVehicleTypeIds(vehicleTypeIds);
            });
        }
    };

    const handlePaginationChange = (currentPage, itemsPerPage) => {
        dispatch(setVehicleListingsPagination({ currentPage, itemsPerPage }));
        getListing(undefined, currentPage, itemsPerPage);
    };

    const resetButton = () => {
        dispatch(setSelectedVehicleType(''));
        dispatch(setSelectedVehicleSubType(''));
        dispatch(setVehicleListingsPagination({ currentPage: 1, itemsPerPage: 10 }));
        dispatch(setVehicleListings({ rows: [], count: 0 }));
        
        // remove any query param that starts with filter- from the URL to force-reset
        const urlParams = new URLSearchParams(window.location.search);
        for (let param of urlParams.keys()) {
            if (param.startsWith('filter-')) {
                urlParams.delete(param);
            }
        }

        window.history.replaceState({}, '', `${window.location.pathname}${urlParams.size > 0 ? '?' + urlParams.toString() : ''}`);
    };

    return (
        <div className="main-container">
            {!inline ? (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                        }}
                    >
                        <ContactBuyingPopover/>
                    </div>

                    <div className="main-title">
                        <p className="veh-listing-desc">
                            For items currently not available, please email our Customer Care
                            team at&nbsp;
                            <a className="usa-link" href="mailto:vehicle.buying@gsa.gov">
                                Customer Care
                            </a>{' '}
                            for assistance.
                        </p>
                    </div>
                </>
            ) : (
                <h4>Select a Standard Item</h4>
            )}

            <div data-testid="listing-container-test" className="listing-container">
                <VehicleListingFilters
                    resetButton={resetButton}
                    initVehicleType={selectedVehicleType}
                    initVehicleSubType={selectedVehicleSubType}
                    getListing={getListing}
                    getVehicleGroupTypes={getVehicleGroupTypes}
                    loadingVehicleGroupTypes={loadingVehicleGroupTypes}
                    retainFilters={!inline}
                />
                <div className="vehicles-container">
                    {!inline && (<SearchBySIN onSelectItem={onSelectItem} leasing/>)}
                    {(loadingStandardItems || loadingRequisitionSins || loadingVehicleGroupTypes || !availableVehicleSubTypes) ? <Spinner size='large' /> : (
                        <>
                            <VehicleList
                                data={vehicleListings.rows}
                                onSelectItem={onSelectItem}
                                currentSelected={selectedStandardItem}
                                leasing
                                replacement={replacement}
                            />
                            <div className="pagination">
                                <Pagination
                                    buttonVariant="primary"
                                    boundaryCount={1}
                                    variant="advanced"
                                    itemsCount={vehicleListings.count}
                                    {...vehicleListingsPagination}
                                    siblingCount={1}
                                    isReset={resetPagination}
                                    onPageChange={(currentPage, itemsPerPage) => {
                                        handlePaginationChange(currentPage, itemsPerPage);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}