import { useDispatch } from 'react-redux';
import { useState } from 'react';
import VehicleReferralButtons from '../../../components/vehicle-referral-buttons/vehicle-referral-buttons';
import { REPLACE_VEHICLE_STEPS } from '../../replace-vehicle';
import {
  changeReplacementStep,
  setCurrentStatusForVehicle,
  setReferralReplacementId,
  setReferralRequestId,
  setRequisitionId,
  setSupplementaryInformation,
  setVehicleReferralActivities,
  updateSelectedOption,
  setRequisitionNumber,
  setSinNotAvailable,
} from '../../../../../reducers/vehicle-referral';
import './review-submit.scss';
import { useSelector } from 'react-redux';
import ReadonlyOptionGroup from './readonly-option-group';
import SupplementaryInformation from './supplementary-information/supplementary-information';
import {
  useCreateReferralReplacement,
  useGetAddOptions,
  useReturnReferralRequest,
  useGetActiveContracts,
  useGetReferralRequestLazy,
} from '../../../../../requests/vehicle-referral';
import { Spinner, useModal, Alert } from '@gsa/afp-component-library';
import { useEffect, useRef } from 'react';
import { VEHICLE_REFERRAL_STATUS } from '../../../utils/vehicle-referral-util';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import ReturnModal from '../../../components/return-modal/return-modal';
import useBackToList from '../../../utils/use-back-to-list';
import { useCreateLeasingDraft } from '../../../../../requests/leasing';
import { RequisitionTransactionType } from '../../../../ReviewDetails/RequisitionDetailsUtils';
import {
  RequisitionType,
  StoreOperations,
  StoreSubjects,
  UserType,
} from '../../../../../constants/constants';
import moment from 'moment';
import {
  InitialLeasingSteps,
  LeasingSteps,
} from '../../../../leasing/leasing-consts';
import { getStep } from '../../../../leasing/leasing-steps';

export const EDITABLE_STATUSES = [
  VEHICLE_REFERRAL_STATUS.DRAFT,
  VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION,
  VEHICLE_REFERRAL_STATUS.RETURNED,
];

export default function ReviewAndSubmitReplacementRequest() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const {
    selectedOptions,
    selectedStandardItem,
    referralRequestId,
    approvalLevel,
    status,
    vehicleDetails,
    referralReplacementId,
    requisitionId,
    sinNotAvailable,
    garageAddress,
  } = useSelector((state) => state.vehicleReferralReducer);
  const [
    updateReferralReplacement,
    { loading: updateReferralReplacementLoading },
  ] = useCreateReferralReplacement();
  const [returnReferralRequest] = useReturnReferralRequest();
  const [getActiveContracts] = useGetActiveContracts();
  const [createLeasingDraft] = useCreateLeasingDraft();
  const { isOpen, closeModal, openModal } = useModal();
  const { backToList } = useBackToList();
  const [submitting, setSubmitting] = useState(false);
  const [getReferralRequest] = useGetReferralRequestLazy();
  const { currentUser } = useCurrentUser();

  const ability = useAppAbility();
  const canManageReferrals = ability.can(
    StoreOperations.Manage,
    StoreSubjects.VehicleReferral,
  );
  const isEmployee = currentUser?.userType.id === UserType.GSA_Employee;
  let isReOpen =
    canManageReferrals &&
    status === VEHICLE_REFERRAL_STATUS.COMPLETED &&
    isEmployee;

  // if we are loading the review/submit from the db, we need to get the full additional option data
  const selectedOptionsFromDb =
    selectedOptions.length > 0
      ? selectedOptions.some((selectedOption) => !selectedOption.option)
      : false;
  const additionalOptions = useGetAddOptions(selectedStandardItem.id, {
    skip: !selectedOptionsFromDb,
    raw: true,
  });
  useEffect(() => {
    if (selectedOptionsFromDb && !additionalOptions.loading) {
      if (additionalOptions.data.length === 0) {
        console.warn(
          'there are options in the state, but none coming from the api.',
        );
      }

      for (let selected of selectedOptions) {
        const option = additionalOptions.data.find(
          (option) => selected.equipmentCode === option.equipmentCode.code,
        );
        dispatch(updateSelectedOption({ option, comment: selected.comment }));
      }
    }
  }, [additionalOptions.data, additionalOptions.loading]);

  if (additionalOptions.loading || selectedOptionsFromDb) {
    return <Spinner size="large" />;
  }

  const groupedByCategory = {};
  for (let item of selectedOptions) {
    if (!groupedByCategory[item.option.equipmentCode.categoryCode.title]) {
      groupedByCategory[item.option.equipmentCode.categoryCode.title] = [];
    }

    groupedByCategory[item.option.equipmentCode.categoryCode.title].push(item);
  }

  // sort the keys alphabetically
  const sortedKeys = Object.keys(groupedByCategory).sort();
  const sortedGroupedByCategory = {};

  for (let key of sortedKeys) {
    sortedGroupedByCategory[key] = groupedByCategory[key].sort((a, b) => {
      if (a.option.equipmentCode.code < b.option.equipmentCode.code) return -1;
      if (a.option.equipmentCode.code > b.option.equipmentCode.code) return 1;
      return 0;
    });
  }

  const handleSave =
    (autoNavigate = false, navigateStep) =>
    () => {
      const formValues = formRef.current.getValues();

      const payload = {
        referralRequestId,
        referralReplacementStatus:
          status === VEHICLE_REFERRAL_STATUS.RETURNED
            ? VEHICLE_REFERRAL_STATUS.RETURNED
            : VEHICLE_REFERRAL_STATUS.DRAFT,
        missionMakeModel:
          formValues.requireMakeModel === 'yes' ? formValues.makeModel : null,
        specificInstructions: formValues.specialInstructions,
        acknowledged: formValues.acknowledge,
      };

      updateReferralReplacement({
        variables: {
          input: payload,
        },
      }).then(({ data }) => {
        dispatch(setReferralRequestId(data.referralRequestId));
        dispatch(
          setReferralReplacementId(
            data.referralReplacement.referralReplacementId,
          ),
        );
        dispatch(
          setSupplementaryInformation({
            missionMakeModel:
              formValues.requireMakeModel === 'yes'
                ? formValues.makeModel
                : null,
            specialInstructions: formValues.specialInstructions,
            acknowledge: formValues.acknowledge,
          }),
        );
        dispatch(setCurrentStatusForVehicle(data.referralRequestStatusCode));

        if (autoNavigate) {
          dispatch(changeReplacementStep(navigateStep));
          window.scrollTo(0, 0);
        }
      });
    };

  const handleReturnSubmit = ({ comment }) => {
    returnReferralRequest({
      variables: {
        referralRequestId,
        returnReason: comment,
        reOpen: isReOpen,
      },
    }).then(() => {
      closeModal();
      backToList({
        submitted: true,
        tagNumber: vehicleDetails?.tagNumber,
        status: isReOpen
          ? VEHICLE_REFERRAL_STATUS.RE_OPENED
          : VEHICLE_REFERRAL_STATUS.RETURNED,
      });
    });
  };

  const handleSupplementaryInformationSubmit = (finished) => {
    if (!finished) {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }
  };

  const createRequisition = (e) => {
    e.preventDefault();

    const today = moment();
    const requisitionName = [
      vehicleDetails.customer?.legacyCustomerNumber,
      `${today.format('MMMM')}${today.format('DD')}`.toUpperCase(),
      `${today.format('HHmmss')}`,
    ].join('_');

    const payload = {
      createLeasingCustomerAccountInput: {
        customerAccountId: vehicleDetails.customer.customerId,
        customerAccountName: vehicleDetails.customer.accountName,
        agencyCode: vehicleDetails.customer?.customerAgency?.id,
        bureauCode: vehicleDetails.customer?.customerBureau?.id,
        vehicleReplacements: [
          {
            assetId: vehicleDetails.id,
            tagNumber: vehicleDetails.tagNumber,
            serialNumberVin: vehicleDetails.vin,
            standardItemNumber:
              vehicleDetails?.standardItem?.standardItemNumber,
          },
        ],
      },
      createInput: {
        transactionType: RequisitionTransactionType.VEHICLE_LEASE,
        requisitionType: RequisitionType.STANDARD_ORDER,
        agencyCode: '047',
        bureauCode: '09',
        friendlyName: requisitionName,
        zoneId: vehicleDetails.fleetManagementCenter?.zoneId,
        fmcId: vehicleDetails.fleetManagementCenter?.id,
        regionId: vehicleDetails.customer?.regionId,
        fsrContact: {
          firstName: vehicleDetails.customer?.fsrUser?.firstName,
          lastName: vehicleDetails.customer?.fsrUser?.lastName,
          email: vehicleDetails.customer?.fsrUser?.email,
          phoneNumber: vehicleDetails.customer?.fsrUser?.phoneNumber,
          phoneCountryCode: vehicleDetails.customer?.fsrUser?.phoneCountryCode,
        },
        standardItemCode: selectedStandardItem.standardItemNumber,
        quantity: 1,
        referralReplacementId,
        clientState: {
          currentStep: getStep(
            InitialLeasingSteps,
            LeasingSteps.COMPARE_AND_SELECT,
          ),
          selectedStandardItem,
          destinationType:
            garageAddress.country === 'US' ? 'us-territory' : 'foreign-county',
          selectedState: `${garageAddress.country}-${garageAddress.state}`,
          shipmentCountry: garageAddress.country,
        },
      },
    };

    getActiveContracts({
      variables: {
        standardItemId: selectedStandardItem.id,
        // Commenting this out to make sure it is not needed at a later point
        // transactionType: RequisitionTransactionType.VEHICLE_LEASE,
      },
    }).then(({ data }) => {
      if (data) {
        if (data?.length > 0) {
          createLeasingDraft({
            variables: payload,
          }).then(async ({ data }) => {
            window.open(`/lease/${data.requisitionId}`, '_blank');
            dispatch(
              setCurrentStatusForVehicle(
                VEHICLE_REFERRAL_STATUS.REQUISITION_CREATED,
              ),
            );

            const { data: referralRequest } = await getReferralRequest({
              variables: {
                assetId: vehicleDetails.id,
                agencyCode: vehicleDetails?.customer?.customerAgency?.id,
                bureauCode: vehicleDetails?.customer?.customerBureau?.id,
                officeCode:
                  vehicleDetails?.customer?.customerPhysicalOffice?.officeCode,
              },
            });
            const activities = referralRequest.referralRequestActivities;
            if (activities?.length) {
              dispatch(
                setVehicleReferralActivities(
                  activities.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                  ),
                ),
              );
            }
            dispatch(setRequisitionId(data.requisitionId));
            dispatch(setRequisitionNumber(data.requisitionNumber));
            setSinNotAvailable(false);
          });
        } else {
          dispatch(setSinNotAvailable(true));
        }
      }
    });
  };

  let showButtonIcons = true;
  let returnToCustomerLabel = 'Return to customer';
  let submitButtonLabel = '';
  switch (approvalLevel) {
    case VEHICLE_REFERRAL_STATUS.FSR_APPROVAL:
      submitButtonLabel = 'Save and submit';
    case VEHICLE_REFERRAL_STATUS.OFFICE_APPROVAL:
      submitButtonLabel = 'Submit to office reviewer';
      break;
    case VEHICLE_REFERRAL_STATUS.BUREAU_APPROVAL:
      submitButtonLabel = 'Submit to bureau reviewer';
      break;
    case VEHICLE_REFERRAL_STATUS.AGENCY_APPROVAL:
      submitButtonLabel = 'Submit to agency reviewer';
      break;
    case VEHICLE_REFERRAL_STATUS.COMPLETED:
      submitButtonLabel = 'Approve';
      break;
    default:
      submitButtonLabel = 'Save and submit';
      break;
  }

  if (!EDITABLE_STATUSES.includes(status) && approvalLevel === null) {
    submitButtonLabel = '';
  }

  if (EDITABLE_STATUSES.includes(status)) {
    returnToCustomerLabel = '';
  }

  let submitHandler = () => {};
  let submitButtonType = 'submit';

  if (status === VEHICLE_REFERRAL_STATUS.APPROVED_AT_FSR && isEmployee) {
    returnToCustomerLabel = 'Re-open request';
    submitButtonLabel = 'Create requisition';
    showButtonIcons = false;
    submitHandler = createRequisition;
  }

  if (
    requisitionId &&
    status === VEHICLE_REFERRAL_STATUS.REQUISITION_CREATED &&
    isEmployee
  ) {
    returnToCustomerLabel = '';
    submitButtonLabel = 'View requisition';
    showButtonIcons = false;
    submitHandler = () => window.open(`/lease/${requisitionId}`, '_blank');
    submitButtonType = 'button';
  }

  return (
    <div className="replacement-request-review-submit">
      <div>
        <div className="section-header">
          <h3>Requested options</h3>
          <p>
            In accordance with FMR 102-34.50, optional equipment selections
            should be limited to what is mission essential. Not all selected
            options are guaranteed. Note that previous vehicles may have had
            options included that now may need to be selected. Options selected
            may affect the final lease rate.
          </p>
        </div>

        <div className="selected-options">
          {Object.keys(sortedGroupedByCategory).length === 0 ? (
            <Alert>No selected options</Alert>
          ) : (
            Object.keys(sortedGroupedByCategory).map((category) => (
              <ReadonlyOptionGroup
                key={category}
                name={category}
                options={sortedGroupedByCategory[category]}
              />
            ))
          )}
        </div>
      </div>

      <SupplementaryInformation
        formRef={formRef}
        onSubmit={handleSupplementaryInformationSubmit}
      />

      {sinNotAvailable && (
        <div>
          <Alert type="error" className="show-error-alert">
            <b>There are no makes and models available for this SIN.</b>
            <div> You can: </div>
            <ul>
              <li>
                Wait until the contract has been awarded and create the
                requisition
              </li>
              <li>
                Re-open the request and return it to the customer to select a
                different SIN
              </li>
              <li>Override SIN selection</li>
            </ul>
          </Alert>
        </div>
      )}

      <VehicleReferralButtons
        cancelButton={{
          label: 'Previous',
          leftIcon: { name: 'arrow_back' },
          onClick: handleSave(true, REPLACE_VEHICLE_STEPS.SELECT_OPTIONS),
          disabled: updateReferralReplacementLoading || submitting,
        }}
        returnToCustomer={{
          onClick: openModal,
          label: returnToCustomerLabel,
          ...(!showButtonIcons ? { leftIcon: null } : {}),
        }}
        saveButton={{
          onClick: handleSave(false, null),
          disabled: updateReferralReplacementLoading || submitting,
        }}
        submitButton={{
          label: submitButtonLabel,
          ...(!showButtonIcons ? { leftIcon: null } : {}),
          onClick: submitHandler,
          type: submitButtonType,
          disabled: updateReferralReplacementLoading || submitting,
        }}
        nextFormId="supplementary-information-form"
        hideButtons={[
          (submitButtonLabel === '' || !canManageReferrals) && 'submit',
          (EDITABLE_STATUSES.includes(status) || !canManageReferrals) &&
            'returnToSearch',
          (!EDITABLE_STATUSES.includes(status) || !canManageReferrals) &&
            'cancel',
          (!EDITABLE_STATUSES.includes(status) || !canManageReferrals) &&
            'save',
          (submitButtonLabel === '' ||
            returnToCustomerLabel === '' ||
            !(
              canManageReferrals &&
              ((!isEmployee &&
                status !== VEHICLE_REFERRAL_STATUS.APPROVED_AT_FSR) ||
                isEmployee)
            )) &&
            'returnToCustomer',
        ]}
      />

      <ReturnModal
        isOpen={isOpen}
        handleCancel={closeModal}
        tagNumber={vehicleDetails?.tagNumber}
        handleReturn={handleReturnSubmit}
        isReOpen={isReOpen}
      />
    </div>
  );
}
