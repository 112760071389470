import React from 'react';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';

function CancelModal({ handleClose, handleCancel }) {
  return (
    <Modal
      title="Cancel selection type"
      onClose={handleClose}
      actions={
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button
            type="button"
            variant="unstyled"
            onClick={handleClose}
            label="Go back"
          />
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              handleCancel();
              handleClose();
            }}
            label="Confirm cancel"
          />
        </div>
      }
    >
      Are you sure you want to cancel selection type for this vehicle? You
      will lose any unsaved changes.
    </Modal>
  );
}

export default connectModal(CancelModal);