import { Accordion, Spinner, Alert } from '@gsa/afp-component-library';
import './select-options.scss';
import { useCreateReferralReplacement, useGetAddOptions, useGetMinOptions } from '../../../../../requests/vehicle-referral';
import MinimumRequirementGroup from './minimum-requirement-group';
import AdditionalOptionsGroup from './additional-options-group';
import VehicleReferralButtons from '../../../components/vehicle-referral-buttons/vehicle-referral-buttons';
import { useDispatch } from 'react-redux';
import { changeReplacementStep, setCurrentStatusForVehicle, setReferralReplacementId, setReferralRequestId } from '../../../../../reducers/vehicle-referral';
import { REPLACE_VEHICLE_STEPS } from '../../replace-vehicle';
import { useSelector } from 'react-redux';
import { VEHICLE_REFERRAL_STATUS } from '../../../utils/vehicle-referral-util';
import { emDashUnicode } from '@gsa/afp-shared-form-utils';

export default function SelectOptions() {
    const { selectedStandardItem, selectedOptions, referralRequestId, previousOptions, status } = useSelector((state) => state.vehicleReferralReducer);
    const [updateReferralReplacement, { loading: updateReferralReplacementLoading }] = useCreateReferralReplacement();
    const dispatch = useDispatch();
    const loading = [];

    // get the minimum options for the given standard item
    const minimumOptions = useGetMinOptions(selectedStandardItem.id);
    loading.push(minimumOptions.loading);

    // get the additional options for the given standard item
    const additionalOptions = useGetAddOptions(selectedStandardItem.id);
    loading.push(additionalOptions.loading);

    const isLoading = loading.some((l) => l);

    const handleSubmit = (autoNavigate = false, navigateStep) => () => {
        const payload = {
            referralRequestId,
            referralReplacementStatus: status === VEHICLE_REFERRAL_STATUS.RETURNED ? VEHICLE_REFERRAL_STATUS.RETURNED : VEHICLE_REFERRAL_STATUS.DRAFT,
            options: selectedOptions.map((option) => (option?.option?.equipmentCode?.code ? {
                equipmentCode: option.option.equipmentCode.code,
                comment: option.comment
            } : null)).filter((option) => option !== null)
        };

        updateReferralReplacement({
            variables: {
                input: payload
            }
        }).then(({ data }) => {
            dispatch(setReferralRequestId(data.referralRequestId));
            dispatch(setReferralReplacementId(data.referralReplacement.referralReplacementId));
            dispatch(setCurrentStatusForVehicle(data.referralRequestStatusCode));

            if (autoNavigate) {
                dispatch(changeReplacementStep(navigateStep))
                window.scrollTo(0, 0);
            }
        })
    }

    return (
        <>
            <div className='vehicle-referral-select-options'>
                <h2>Select options</h2>
                <p>
                    In accordance with FMR 102-34.50, optional equipment selections should be limited to what is mission essential. Not all selected options are guaranteed. Note that previous vehicles may have had options included that now may need to be selected. Options selected may affect the final lease rate.
                </p>

                {isLoading ?
                    <Spinner size='large' />
                    : (
                        <>
                            <div className='previously-selected-options'>
                                <h3>Previously selected options</h3>
                                <div>
                                    {previousOptions?.join(', ') || emDashUnicode}
                                </div>
                            </div>
                            <Accordion
                                items={[
                                    {
                                        id: 'minimum-requirements',
                                        key: 'minimum-requirements',
                                        title: (
                                            <div className="select-options-accordion-title">
                                                <div>Minimum requirements</div>
                                                <div>GSA Minimum requirements</div>
                                            </div>
                                        ),
                                        content: minimumOptions.error ?
                                            <Alert type='error'>
                                                There was an issue loading the minimum requirements.
                                            </Alert>
                                            : Object.keys(minimumOptions.data).length === 0 ?
                                                <Alert type='warning'>
                                                    There were no minimum requirements found for this standard item.
                                                </Alert>
                                                : (
                                                    <div className='minimum-requirements'>
                                                        {Object.keys(minimumOptions.data).map((key) => (
                                                            <MinimumRequirementGroup
                                                                key={key}
                                                                name={key}
                                                                options={minimumOptions.data[key]}
                                                            />
                                                        ))}
                                                    </div>
                                                ),
                                        expanded: true,
                                    },
                                    {
                                        id: 'additional-options',
                                        key: 'additional-options',
                                        title: (
                                            <>Add Options</>
                                        ),
                                        className: 'additional-options-wrapper',
                                        content: additionalOptions.error ?
                                            <Alert type='error'>
                                                There was an issue loading the additional options.
                                            </Alert>
                                            : Object.keys(additionalOptions.data).length === 0 ?
                                                <Alert type='warning'>
                                                    There were no additional options found for this standard item.
                                                </Alert>
                                                : (
                                                    <div className='additional-options'>
                                                        {Object.keys(additionalOptions.data).map((key) => (
                                                            <AdditionalOptionsGroup
                                                                key={key}
                                                                name={key}
                                                                options={additionalOptions.data[key]}
                                                            />
                                                        ))}
                                                    </div>
                                                ),
                                        expanded: true,
                                    }
                                ]}
                                id='select-options-accordion'
                                className=""
                                bordered={false}
                            />
                        </>
                    )}
            </div>

            <VehicleReferralButtons
                cancelButton={{
                    label: 'Previous',
                    leftIcon: { name: 'arrow_back' },
                    onClick: handleSubmit(true, REPLACE_VEHICLE_STEPS.GARAGE),
                    disabled: updateReferralReplacementLoading,
                }}
                saveButton={{
                    disabled: updateReferralReplacementLoading,
                    onClick: handleSubmit(false, null),
                }}
                submitButton={{
                    label: 'Next',
                    leftIcon: null,
                    rightIcon: { name: 'arrow_forward' },
                    onClick: handleSubmit(true, REPLACE_VEHICLE_STEPS.REVIEW_SUBMIT),
                    disabled: updateReferralReplacementLoading,
                }}
                hideButtons={['returnToSearch', 'returnToCustomer', 'overrideSIN']}
            />
        </>
    )
}