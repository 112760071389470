import { useMemo, useRef, useState } from "react";
import { useGetStateList } from "../../../../../requests/vehicle-referral";
import { SelectControl } from "@gsa/afp-shared-form-utils";
import { Spinner } from '@gsa/afp-component-library';

export default function generateVehicleGarageFormContent({
    defaultValues,
    countries,
    defaultStates
}) {
    const [getStates, { loading: statesLoading }] = useGetStateList();

    const values = useMemo(() => ({
        countryCode: 'US',
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateCode: '-1',
        zipCode: '',
        usedForTowing: 'no',
        useSameSin: 'yes',
        towingWeight: null,
        towingWeightUnit: 'lbs',
        ...defaultValues
    }), []);

    const content = {
        buttonControls: { cancel: false, submit: false },
        gap: '85px',
        sections: [
            {
                header: 'Replace vehicle:',
                element: {
                    header: {
                        className: 'replace-vehicle-form-header'
                    }
                },
                fields: [
                    {
                        label: 'Provide garage address',
                        tooltip: 'The garage address is the physical location of the vehicle. This address should align with its future CDD garage address, and will be used to determine a delivery location.',
                    },
                    {
                        id: 'countryCode',
                        label: 'Country',
                        required: 'true',
                        type: 'combobox',
                        options: countries.data,
                    },
                    {
                        id: 'addressLine1',
                        label: 'Address line 1',
                        type: 'text',
                    },
                    {
                        id: 'addressLine2',
                        label: 'Address line 2',
                        type: 'text',
                    },
                    {
                        id: 'city',
                        label: 'City',
                        type: 'text',
                    },
                    {
                        id: 'stateCode',
                        label: 'State',
                        options: defaultStates.data,
                        type: 'select',
                        required: true,
                        component: (props, useFormContext) => {
                            const [states, setStates] = useState(defaultStates.data);
                            // use this for a ref that the component has rendered for the first time
                            // since we want to use the defaultStates that came when the page loaded,
                            // avoiding a second network request to load the same data again
                            const first = useRef(false);

                            const watchCountry = useFormContext().getValues('countryCode');

                            useMemo(async () => {
                                if (!first.current) {
                                    first.current = true;
                                    return;
                                }

                                const countryStates = await getStates({
                                    variables: { category: `ISOStateCode-${watchCountry}` },
                                });

                                setStates(countryStates.data);
                                useFormContext().setValue('stateCode', '-1');
                            }, [watchCountry]);

                            return (
                                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                    {SelectControl({ ...props, className: [], disabled: statesLoading }, { id: props.id, options: states })}
                                    {statesLoading && <Spinner size="small" />}
                                </div>
                            )
                        }
                    },
                    {
                        id: 'zipCode',
                        label: 'Postal code',
                        type: 'text',
                        required: true,
                        columnWidth: 'col-2-12',
                    }
                ]
            },
            {
                gap: '32px',
                sections: [
                    { 
                        fields: [
                            {
                                id: 'usedForTowing',
                                label: 'Will the replacement vehicle be used for towing?',
                                type: 'radio',
                                options: [
                                    { label: 'Yes', value: 'yes' },
                                    { label: 'No', value: 'no' },
                                ],
                                required: true,
                                element: {
                                    control: {
                                        inline: true
                                    }
                                }
                            },
                        ]
                    },
                    {
                        dependentOn: 'usedForTowing',
                        dependentValue: 'yes',
                        altStyle: 'alt-blue-no-hover',
                        columnWidth: 'col-4-12',
                        fields: [
                            {
                                label: 'Provide maximum combined towing weight of the trailer and load',
                                required: true,
                            },
                            {
                                fieldLayout: 'horizontal',
                                columnWidth: 'col-9-12',
                                fields: [
                                    {
                                        id: 'towingWeight',
                                        type: 'number',
                                        columnWidth: 'col-9-12',
                                        element: {
                                            control: {
                                                style: { maxWidth: '100%' }
                                            }
                                        }
                                    },
                                    {
                                        id: 'towingWeightUnit',
                                        type: 'select',
                                        columnWidth: 'col-3-12',
                                        options: [
                                            { label: 'lbs', value: 'lbs' },
                                            { label: 'kg', value: 'kg' },
                                        ],
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                header: 'Select Standard Item Number',
                subheader: 'In accordance with FMR 102-34.50, vehicle selections should be limited to the minimum vehicle and engine size that is mission essential, and achieve maximum fuel efficiency',
                element: {
                    header: {
                        className: 'select-sin-header',
                    },
                    subheader: {
                        style: { maxWidth: '1021px', marginBottom: '85px' }
                    }
                },
                fields: [
                    {
                        id: 'useSameSin',
                        label: 'Would you like to replace this vehicle with the same Standard Item Number (SIN)?',
                        type: 'radio',
                        options: [
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' },
                        ],
                        required: true,
                        element: {
                            label: {
                                style: {
                                    maxWidth: '1021px',
                                }
                            },
                            control: {
                                inline: true
                            }
                        }
                    }
                ]
            }
        ]
    }


    return {
        values,
        content
    }
}