import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { ReferralRequestStatus } from './utils/vehicle-referral-util';

const RequestStatusColors = {
  [ReferralRequestStatus.AGENCY_APPROVAL]: 'Info-Alt-Gray',
  [ReferralRequestStatus.AGENCY_APPROVED]: 'Info-Gray',
  [ReferralRequestStatus.APPROVED_AT_FSR]: 'Info-Gray',
  [ReferralRequestStatus.BUREAU_APPROVAL]: 'Info-Alt-Gray',
  [ReferralRequestStatus.FSR_APPROVAL]: 'Info-Gray',
  [ReferralRequestStatus.OFFICE_APPROVAL]: 'Info-Gray',
  [ReferralRequestStatus.PENDING_CUST_ACTION]: 'Info-Gray',
  [ReferralRequestStatus.REQUISITION_CREATED]: 'Info-Gray',
  [ReferralRequestStatus.RETURNED]: 'Warning-Gray',
};

export default function ReferralRequestStatusBadge({ requestStatus }) {
  const status = ReferralRequestStatus[requestStatus];
  return (
    <StatusBadge variant={RequestStatusColors[status]}>{status}</StatusBadge>
  );
}

ReferralRequestStatusBadge.propTypes = {
  requestStatus: PropTypes.string.isRequired,
};
