import { gql } from '@apollo/client';

export const GET_VEHICLE_REFERRAL_LIST = gql`
  query GetReferralRequestBasedOnFilters(
    $filters: [Filter!]
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getReferralRequestBasedOnFilters(
      filters: $filters
      limit: $limit
      offset: $offset
      order: $order
    ) {
      count
      rows {
        boac
        lawEnforcementDesignation
        mileage
        newSIN
        oldSIN
        referralRequestId
        referralStatus
        selectionType
        tagNumber
        assetId
      }
    }
  }
`;

export const CREATE_REFERRAL_REQUEST = gql`
  mutation CreateReferralRequest($input: RequestReferralInput!) {
    createReferralRequest(input: $input) {
      referralAction
      referralActionReason
      assetId
      newVehicleSin
      referralRequestStatusCode
      referralRequestActivities {
        referralRequestActivityLogId
        referralRequestReviewAction
        referralRequestComment
        createdByUser {
          fullName
        }
        referralRequestId
        referralRequestStatusCode
        createdAt
      }
      referralFiscalYear
      referralRequestComments {
        referralRequestId
        referralRequestComment
        referralRequestCommentId
      }
    }
  }
`;

export const GET_REFERRAL_REQUEST = gql`
  query GetReferralRequestById(
    $assetId: String!
    $agencyCode: String
    $bureauCode: String
    $officeCode: String
  ) {
    getReferralRequestById(
      assetId: $assetId
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      canReturnRequest
      approvalLevel
      referralFiscalYear
      referralRequestActivities {
        referralRequestActivityLogId
        referralRequestStatusCode
        referralRequestComment
        createdByUser {
          fullName
          email
        }
        referralRequestId
        referralRequestStatusCode
        createdAt
        activityTitle
      }
      referralRequestId
      assetId
      referralAction
      referralActionReason
      referralAdditionalInfo
      newVehicleSin
      referralRequestStatusCode
      approvalLevel
      previousOptions
      referralReplacement {
        referralReplacementId
        referralReplacementGarageAddress {
          addressLine1
          addressLine2
          city
          stateCode
          zipcode
          countryCode
        }
        towingWeight
        specificInstructions
        missionMakeModel
        acknowledged
        referralReplacementOptions {
          equipmentCode
          comment
        }
        referralReplacementAttachments {
          referralReplacementAttachmentId
          name
          metadataId
          replacementAttachmentTypeId
          description
          createdAt
        }
        requisitionId
        requisitionNumber
      }
    }
  }
`;

export const GET_AGENCY_DOCUMENT_BY_TYPE = gql`
  query GetAttachmentByCategory($docType: String!, $agencyCode: String!) {
    getAttachmentByCategory(docType: $docType, agencyCode: $agencyCode) {
      attachmentType {
        attachmentTypeId
      }
      contentURL
      description
      metadataId
      type
      name
      id
    }
  }
`;

export const GET_REFERRAL_VEHICLE_BY_FILTER = gql`
  query GetReferralVehicleByFilter($filters: [Filter!]!) {
    getVehicleByFilter(filters: $filters) {
      id
      tagNumber
      vin
      fmcId
      fleetManagementCenter {
        id
        zoneId
      }
      isTelematicsExempt
      standardItem {
        standardItemNumber
        id
        title
      }
      modelYear
      modelCode
      vehicleMake {
        makeName
      }
      vehicleModel {
        modelDescription: modelName
      }
      fuel {
        description
      }
      fsrUserEmail
      customer {
        customerId
        legacyCustomerNumber
        accountName
        boac
        customerAgency {
          id
          name
          prefixedName
        }
        customerBureau {
          id
          name
          prefixedName
        }
        customerPhysicalOffice {
          officeCode
          officeName
          prefixedName
        }
      }
      primaryPoC {
        fullName
        email
      }
      fsrUserDetails {
        email
      }
      newOdometerReading {
        odometer
      }
      ald {
        lawEnforcement
        garageAddress1
        garageAddress2
        garageAddress3
        garageCity
        garageStateCode
        garagePostalCode
        garageCountryCode
      }
    }
  }
`;

export const CREATE_REFERRAL_NOTE = gql`
  mutation createRequestReferralNote($input: ReferralRequestNoteInput!) {
    createRequestReferralNote(input: $input) {
      referralRequestCommentId
    }
  }
`;

export const CREATE_REFERRAL_REPLACEMENT = gql`
  mutation CreateReferralReplacement($input: ReferralReplacementInput!) {
    createReferralReplacement(input: $input) {
      referralRequestId
      referralRequestStatusCode
      referralReplacement {
        referralReplacementId
      }
    }
  }
`;

export const OVERRIDE_SIN = gql`
  mutation OverrideSin($input: OverrideSinInput!) {
    overrideSin(input: $input)
  }
`;

export const GET_STANDARD_ITEM_BY_SIN = gql`
  query GetStandardItem($filter: FilterType!) {
    getStandardItems(filters: $filter) {
      rows {
        id
        standardItemNumber
        vehicleType
        fedStandardCode
        status
        title
        year
        tags
        vehicleTypeCode {
          id
          parentCode
          parentCategory
        }
      }
    }
  }
`;

export const GET_VEHICLES_BY_PARTIAL_VIN_OR_TAG = gql`
  query GetVehiclesByPartialTagOrVin($vin: String, $tagNumber: String) {
    getVehiclesByPartialTagOrVin(vin: $vin, tagNumber: $tagNumber) {
      tagNumber
      modelYear
      makeName
      id: vin
      makeColorName
      stateTagNumber
    }
  }
`;

export const RETURN_REFERRAL_REQUEST = gql`
  mutation ReturnReferralRequest(
    $referralRequestId: String!
    $reOpen: Boolean
    $returnReason: String
  ) {
    returnReferralRequest(
      referralRequestId: $referralRequestId
      reOpen: $reOpen
      returnReason: $returnReason
    )
  }
`;
