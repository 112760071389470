import { RadioButton, Checkbox, Button, useModal } from '@gsa/afp-component-library';
import CommentModal from './comment-modal/comment-modal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
    updateCommentForSelectedOption,
    addSelectedOption,
    removeCommentFromSelectedOption,
    removeSelectedOption,
    changeSelectedOptionForCategory,
    updateSelectedOption
} from '../../../../../reducers/vehicle-referral';
import { useEffect } from 'react';

/**
 * multiple = true means multiple options are allowed to be selected thus presenting checkboxes
 * multiple = false means only one option can be selected thus presenting radio buttons
 */
export default function AdditionalOptionSelection({ option, multiple }) {
    const dispatch = useDispatch();
    const { selectedOptions } = useSelector((state) => state.vehicleReferralReducer);    
    const { isOpen, openModal, closeModal } = useModal();

    const selectedOptionsFromDb = selectedOptions.length > 0 ? selectedOptions.some((selectedOption) => !selectedOption.option) : false;

    const selected = selectedOptions.find((selectedOption) => {
        if (selectedOptionsFromDb) {
            return selectedOption.equipmentCode === option.equipmentCode.code
        } else {
            return selectedOption.option.equipmentCodeId === option.equipmentCodeId
        }
    });

    useEffect(() => {
        if (selectedOptionsFromDb && selected) {
            dispatch(updateSelectedOption({ option, comment: selected.comment }))
        }
    }, []);

    const comment = selected ? selected.comment : null;

    const handleSelection = (e) => {
        let value = !!selected;

        if (!multiple) {
            if (value) {
                // allow radios to be unselected
                dispatch(removeSelectedOption({ option }))
            } else {
                // when it's not possible to select multiple options, we are just
                // going to remove all other selected options in the same category
                // and just allow the one selected option to be selected
                dispatch(changeSelectedOptionForCategory({ option, comment: null }));
            }
        } else {
            if (value) {
                dispatch(removeSelectedOption({ option }))
            } else {
                dispatch(addSelectedOption({ option, comment: null }))
            }
        }
    }

    const handleSubmitComment = (data) => {
        dispatch(updateCommentForSelectedOption({
            option,
            comment: data.comment
        }))
    }

    const handleDeleteComment = () => {
        dispatch(removeCommentFromSelectedOption({
            option,
        }))
    }

    return (
        <div className='additional-option-selection'>
            { multiple ?
            (
                <Checkbox
                    name={option.equipmentCode.code}
                    label={`${option.equipmentCode.code}-${option.equipmentCode.title}`}
                    onClick={handleSelection}
                    onChange={handleSelection}
                    checked={!!selected}
                />
            )
            : (
                <RadioButton
                    name={option.equipmentCode.category}
                    label={`${option.equipmentCode.code}-${option.equipmentCode.title}`}
                    onClick={handleSelection}
                    onChange={handleSelection}
                    selected={!!selected}
                    checked={!!selected}
                />
            )}
            
            {!!selected && (
                <div className='additional-option-comment'>
                    <CommentModal
                        isOpen={isOpen}
                        onClose={closeModal}
                        values={{ comment }}
                        option={option}
                        onSubmit={handleSubmitComment}
                    />

                    {comment && (
                        <div className='comment'>
                            {comment}
                        </div>
                    )}

                    <div className='comment-actions'>
                        {comment ? (
                            <>
                                <Button
                                    label='Edit'
                                    variant='unstyled'
                                    leftIcon={{ name: 'edit' }}
                                    onClick={() => openModal()}
                                />

                                <Button
                                    label='Delete'
                                    variant='unstyled'
                                    leftIcon={{ name: 'delete' }}
                                    onClick={handleDeleteComment}
                                />
                            </>
                        ): (
                            <Button
                                label='Add comment'
                                variant='unstyled'
                                leftIcon={{ name: 'edit' }}
                                onClick={() => openModal()}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}