import { useState } from 'react';
import {
  TabSet,
  Button,
  Spinner,
  Alert,
  useModal,
} from '@gsa/afp-component-library';
import { useParams } from 'react-router-dom';
import {
  STANDARD_ITEM_PARENT_CATEGORY,
  VEHICLE_REFERRAL_STATUS,
  VEHICLE_REQUEST_TAB,
} from '../../utils/vehicle-referral-util';
import OptionSelection from './option-selection';
import {
  setCurrentReferralAction,
  changeGarageAddress,
  changeProjectedTowingWeight,
  setDoNotReplaceSelection,
  setSelectedStandardItem,
  setTurnInSelection,
  setUsedForTowing,
  setUseSameSin,
  setVehicleReferralActivities,
  setSelectedReplacementOptions,
  setCurrentStatusForVehicle,
  setVehicleDetails,
  setPreviousStandardItem,
  setReferralRequestId,
  setReferralReplacementId,
  setReplacementAttachments,
  changeReplacementStep,
  resetVehicleReferralState,
  setSupplementaryInformation,
  setApprovalLevel,
  setPreviousOptions,
  setRequisitionId,
  setRequisitionNumber,
  setCanReturnRequest,
  setLastSin,
  setReferralFiscalYear,
} from '../../../../reducers/vehicle-referral';
import { useDispatch } from 'react-redux';
import VehicleReferralActivities from '../activity/activity-comment';
import { useSelector } from 'react-redux';
import VehicleDetails from '../vehicle-details/vehicle-details';
import CommentModal from '../../../../components/CommentModal/CommentModal';
import {
  useCreateReferralNote,
  useGetReferralRequestLazy,
  useGetStandardItemBySin,
  useGetVehicleByFilterNow,
} from '../../../../requests/vehicle-referral';
import { OPTIONS } from '../../utils/vehicle-referral-util';
import './option-selection.scss';
import {
  setSelectedVehicleType,
  setSelectedStandardItem as setLeasingStandardItem,
} from '../../../../reducers/leasing';
import { REPLACE_VEHICLE_STEPS } from '../../replace-vehicle/replace-vehicle';
import CancelModal from './cancel-modal';
import useBackToList from '../../utils/use-back-to-list';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  StoreOperations,
  StoreSubjects,
} from '../../../../constants/constants';

export default function VehicleTabs({ setNotFound }) {
  const [tab, _setTab] = useState(VEHICLE_REQUEST_TAB.SELECTION_DETAILS);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [createReferralNote] = useCreateReferralNote();
  const { assetId } = useParams();
  const [error, setError] = useState(null);
  const [vehicleLoading, setVehicleLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(true);
  const [getStandardItemBySin] = useGetStandardItemBySin();
  const [getReferralRequestLazy] = useGetReferralRequestLazy();
  const cancelModal = useModal();
  const {
    currentReferralAction,
    garageAddress,
    vehicleDetails,
    referralRequestId,
    status,
  } = useSelector((state) => state.vehicleReferralReducer);
  const agencyCode = vehicleDetails?.customer?.customerAgency?.id;
  const bureauCode = vehicleDetails?.customer?.customerBureau?.id;
  const officeCode =
    vehicleDetails?.customer?.customerPhysicalOffice?.officeCode;
  const { backToList } = useBackToList();
  const ability = useAppAbility();
  const canManageReferrals = ability.can(
    StoreOperations.Manage,
    StoreSubjects.VehicleReferral,
  );

  const dispatch = useDispatch();

  const handleSelectedOption = (option) => {
    dispatch(setCurrentReferralAction(option));
  };

  const handleAddComment = async (comment) => {
    await createReferralNote({
      variables: {
        input: {
          referralRequestId,
          comment,
        },
      },
    });

    setShowAddCommentModal(false);
    getReferralRequestLazy({
      variables: {
        assetId,
        ...(agencyCode && { agencyCode }),
        ...(bureauCode && { bureauCode }),
        ...(officeCode && { officeCode }),
      },
    }).then(({ data }) => {
      const activities = data.referralRequestActivities;
      if (activities?.length) {
        dispatch(
          setVehicleReferralActivities(
            activities.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            ),
          ),
        );
      }
    });
  };

  useGetVehicleByFilterNow({
    skip: !assetId,
    variables: {
      filters: [
        {
          operator: '$and',
          conditions: [
            {
              key: 'id',
              operator: '$exact',
              value: assetId,
            },
          ],
        },
      ],
    },
  })
    .then(async ({ data: vehicle }) => {
      if (!vehicle) {
        setVehicleLoading(false);
        setNotFound(true);
        return;
      }

      dispatch(setVehicleDetails(vehicle));
      const { data: previousStandardItem } = await getStandardItemBySin({
        variables: {
          filter: {
            key: 'standardItemNumber',
            operator: 'EQ',
            value: vehicle?.standardItem?.standardItemNumber,
          },
        },
      });

      const tempPreviousStandardItem = { ...previousStandardItem };
      tempPreviousStandardItem.id = parseInt(tempPreviousStandardItem.id);
      dispatch(setPreviousStandardItem(tempPreviousStandardItem));

      if (garageAddress === null) {
        dispatch(
          changeGarageAddress({
            address1: vehicle?.ald?.garageAddress1,
            address2: vehicle?.ald?.garageAddress2,
            city: vehicle?.ald?.garageCity,
            country: vehicle?.ald?.garageCountryCode,
            state: vehicle?.ald?.garageStateCode,
            zip: vehicle?.ald?.garagePostalCode,
          }),
        );
      }

      const referralRequestPayload = { assetId };
      if (vehicle?.customer?.customerAgency?.id) {
        referralRequestPayload.agencyCode = vehicle.customer.customerAgency.id;
      }

      if (vehicle?.customer?.customerBureau?.id) {
        referralRequestPayload.bureauCode = vehicle.customer.customerBureau.id;
      }

      if (vehicle?.customer?.customerPhysicalOffice?.officeCode) {
        referralRequestPayload.officeCode =
          vehicle.customer.customerPhysicalOffice.officeCode;
      }

      await getReferralRequestLazy({
        variables: referralRequestPayload,
      })
        .then(async ({ data: request }) => {
          if (!request) {
            setRequestLoading(false);
            setNotFound(true);
            return;
          }

          dispatch(setReferralRequestId(request.referralRequestId));
          dispatch(setReferralFiscalYear(request.referralFiscalYear));
          dispatch(setApprovalLevel(request.approvalLevel));
          dispatch(setCanReturnRequest(request.canReturnRequest));
          const activities = request.referralRequestActivities;
          if (activities?.length) {
            dispatch(
              setVehicleReferralActivities(
                activities.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                ),
              ),
            );
          }
          dispatch(setPreviousOptions(request.previousOptions));
          dispatch(
            setCurrentStatusForVehicle(request.referralRequestStatusCode),
          );
          dispatch(setCurrentReferralAction(OPTIONS[request.referralAction]));

          if (OPTIONS[request.referralAction] === OPTIONS.REPLACE) {
            if (
              request.referralRequestStatusCode !==
                VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION &&
              request.referralRequestStatusCode !==
                VEHICLE_REFERRAL_STATUS.DRAFT &&
              request.referralRequestStatusCode !==
                VEHICLE_REFERRAL_STATUS.RETURNED
            ) {
              dispatch(
                changeReplacementStep(REPLACE_VEHICLE_STEPS.REVIEW_SUBMIT),
              );
            }

            dispatch(
              setReferralReplacementId(
                request.referralReplacement.referralReplacementId,
              ),
            );
            dispatch(
              setRequisitionId(request.referralReplacement.requisitionId),
            );
            dispatch(
              setRequisitionNumber(
                request.referralReplacement.requisitionNumber,
              ),
            );

            dispatch(
              setReplacementAttachments(
                request.referralReplacement.referralReplacementAttachments.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                ),
              ),
            );

            dispatch(
              changeGarageAddress({
                address1:
                  request.referralReplacement.referralReplacementGarageAddress
                    .addressLine1,
                address2:
                  request.referralReplacement.referralReplacementGarageAddress
                    .addressLine2,
                city: request.referralReplacement
                  .referralReplacementGarageAddress.city,
                country:
                  request.referralReplacement.referralReplacementGarageAddress
                    .countryCode,
                state:
                  request.referralReplacement.referralReplacementGarageAddress
                    .stateCode,
                zip: request.referralReplacement
                  .referralReplacementGarageAddress.zipcode,
              }),
            );

            dispatch(
              setSelectedReplacementOptions(
                request.referralReplacement.referralReplacementOptions,
              ),
            );

            const towingWeight = request.referralReplacement.towingWeight;
            const parsedTowingWeight = towingWeight.match(/(\d+)\s*(lbs|kg)?/);
            if (parsedTowingWeight) {
              dispatch(setUsedForTowing(true));
              dispatch(
                changeProjectedTowingWeight({
                  value: parseInt(parsedTowingWeight[1]),
                  unit: parsedTowingWeight[2] || 'lbs',
                }),
              );
            }

            let sin = null;

            const tempPreviousStandardItem = { ...previousStandardItem };
            tempPreviousStandardItem.id = parseInt(tempPreviousStandardItem.id);

            if (
              request.newVehicleSin ===
              tempPreviousStandardItem.standardItemNumber
            ) {
              dispatch(setUseSameSin(true));
              sin = tempPreviousStandardItem.standardItemNumber;
            } else if (request.newVehicleSin !== null) {
              sin = request.newVehicleSin;
              dispatch(setUseSameSin(false));
            } else if (tempPreviousStandardItem.standardItemNumber !== null) {
              dispatch(setUseSameSin(true));
              sin = tempPreviousStandardItem.standardItemNumber;
            }

            const { data: standardItem } = await getStandardItemBySin({
              variables: {
                filter: {
                  key: 'standardItemNumber',
                  operator: 'EQ',
                  value: sin,
                },
              },
            });

            const tempStandardItem = { ...standardItem };
            tempStandardItem.id = parseInt(standardItem.id);

            dispatch(setSelectedStandardItem(tempStandardItem));
            dispatch(setLeasingStandardItem(tempStandardItem));
            if (
              tempStandardItem.vehicleTypeCode.parentCategory ===
              STANDARD_ITEM_PARENT_CATEGORY.FED
            ) {
              dispatch(setSelectedVehicleType(tempStandardItem.vehicleType));
            } else {
              dispatch(
                setSelectedVehicleType(
                  tempStandardItem.vehicleTypeCode.parentCode,
                ),
              );
            }

            dispatch(setLastSin(tempStandardItem.standardItemNumber));

            dispatch(
              setSupplementaryInformation({
                specialInstructions:
                  request.referralReplacement.specificInstructions,
                missionMakeModel: request.referralReplacement.missionMakeModel,
                acknowledge: request.referralReplacement.acknowledged,
              }),
            );
          }

          if (OPTIONS[request.referralAction] === OPTIONS.TURNIN) {
            dispatch(
              setTurnInSelection({
                selectedOption: {
                  label: request.referralActionReason,
                  value: request.referralActionReason,
                },
                comment: request.referralAdditionalInfo,
              }),
            );
          }

          if (OPTIONS[request.referralAction] === OPTIONS.KEEP) {
            dispatch(
              setDoNotReplaceSelection({
                selectedOption: {
                  label: request.referralActionReason,
                  value: request.referralActionReason,
                },
                comment: request.referralAdditionalInfo,
              }),
            );
          }

          setRequestLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setError('Error fetching referral request.');
          setRequestLoading(false);
        });

      setVehicleLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setError('Error fetching vehicle details.');
      setVehicleLoading(false);
    });

  if (requestLoading || vehicleLoading) {
    return <Spinner size="large" />;
  }

  if (error) {
    return <Alert type="error">{error}</Alert>;
  }

  return (
    <>
      <CancelModal
        isOpen={cancelModal.isOpen}
        handleCancel={() => {
          dispatch(resetVehicleReferralState());
          backToList();
        }}
        handleClose={cancelModal.closeModal}
      />
      <h3 className="display-inline-block">Referred Vehicle</h3>
      <div className="display-inline-block padding-left-3">
        {canManageReferrals && (
          <Button
            type="button"
            variant="outline"
            label="Return to search"
            onClick={() => {
              if (
                status === VEHICLE_REFERRAL_STATUS.DRAFT ||
                status === VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION
              ) {
                cancelModal.openModal();
              } else {
                backToList();
              }
            }}
            data-testid="return-to-search"
            leftIcon={{
              name: 'ArrowBack',
            }}
          />
        )}
      </div>
      <div className="bg-primary-lightest padding-3">
        <VehicleDetails />
      </div>
      <div className="display-flex flex-column flex-align-end margin-top-5">
        <Button
          type="button"
          variant="outline"
          label="Post a comment"
          onClick={() => setShowAddCommentModal(true)}
          data-testid="post-comment"
        />
      </div>
      <CommentModal
        data-testid="add-comment-modal-referral"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={() => setShowAddCommentModal(false)}
        title="Post a comment"
        body=""
        isRequired
      />
      <TabSet
        tabs={[
          {
            heading: 'Selection details',
            tabSelectedWhenOpen: tab === VEHICLE_REQUEST_TAB.SELECTION_DETAILS,
            content: (
              <OptionSelection
                selectedOption={currentReferralAction}
                setSelectedOption={handleSelectedOption}
              />
            ),
          },
          {
            heading: 'Activity and comments',
            tabSelectedWhenOpen: tab === VEHICLE_REQUEST_TAB.ACTIVITY,
            content: <VehicleReferralActivities />,
          },
        ]}
      />
    </>
  );
}
