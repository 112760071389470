import { useSelector, useDispatch } from 'react-redux';
import { Spinner, useModal } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { UserRoles } from '../../../../constants';
import {
  setTurnInSelection,
  setDoNotReplaceSelection,
  setCurrentStatusForVehicle,
} from '../../../../reducers/vehicle-referral';
import OptionsRadioButton from '../vehicle-selection/options-radio-button';
import VehicleReferralButtons from '../vehicle-referral-buttons/vehicle-referral-buttons';
import {
  useCreateReferralRequest,
  useReturnReferralRequest,
} from '../../../../requests/vehicle-referral';
import { setVehicleReferralActivities } from '../../../../reducers/vehicle-referral';
import {
  VEHICLE_REFERRAL_STATUS,
  ReviewSelectedAction,
  getSubmitLabel,
  TURN_IN_OPTIONS,
  validateHiddenButtons,
  isExistInRoles
} from '../../utils/vehicle-referral-util';
import ReturnModal from '../return-modal/return-modal';
import useBackToList from '../../utils/use-back-to-list';

export default function TurnInVehicle({ section }) {
  const {
    turnInSelection,
    currentReferralAction,
    vehicleDetails,
    status,
    referralRequestId,
    approvalLevel,
    canReturnRequest
  } = useSelector((state) => state.vehicleReferralReducer);
  const [createReferralRequest, { loading }] = useCreateReferralRequest();
  const [returnReferralRequest] = useReturnReferralRequest();
  const { currentUser } = useCurrentUser();
  const roles = currentUser?.roles || [];
  let isReOpen = false;

  // Internal/GSA users
  const isOrderingAdmin = isExistInRoles(roles, UserRoles.ORDERING_ADMIN);
  const isFsr = isExistInRoles(roles, UserRoles.FSR);
  const isSiteAdmin = isExistInRoles(roles, UserRoles.SITE_ADMIN);
  const reOpenUsers = isOrderingAdmin || isFsr || isSiteAdmin;

  // Customer type users
  const isCustomerUser =
    isExistInRoles(roles, UserRoles.CUSTOMER_ADMINISTRATOR) ||
    isExistInRoles(roles, UserRoles.CUSTOMER_FLEET_MANAGER);
  const agencyLevelApprover = isCustomerUser && isExistInRoles(roles, UserRoles.AGENCY_APPROVER);
  const bureauLevelApprover = isCustomerUser && isExistInRoles(roles, UserRoles.BUREAU_APPROVER);
  const designatedLevelUsers = agencyLevelApprover || bureauLevelApprover;
  
  const dispatch = useDispatch();
  const { backToList } = useBackToList();

  const { isOpen, closeModal, openModal } = useModal();

  const isEditable =
    status === VEHICLE_REFERRAL_STATUS.PENDING_CUST_ACTION ||
    status === VEHICLE_REFERRAL_STATUS.DRAFT ||
    status === VEHICLE_REFERRAL_STATUS.RETURNED;
    
  const validateReOpenStatus =
  status === VEHICLE_REFERRAL_STATUS.AGENCY_APPROVED ||
  status === VEHICLE_REFERRAL_STATUS.APPROVED_AT_FSR; 

  const hideButtons = validateHiddenButtons(
    approvalLevel,
    isEditable,
    reOpenUsers,
    designatedLevelUsers,
  );
  if (!canReturnRequest) {
    hideButtons.push('returnToCustomer');
  }
  hideButtons.push('overrideSIN');
  const submitLabel = approvalLevel
    ? getSubmitLabel(approvalLevel)
    : getSubmitLabel(status);

  if (reOpenUsers && validateReOpenStatus) {
    isReOpen = true;
  }

  const handleComment = (val) => {
    dispatch(setTurnInSelection({ ...turnInSelection, comment: val }));
  };

  const handleOptions = (val) => {
    dispatch(setTurnInSelection({ ...turnInSelection, selectedOption: val }));
    dispatch(setDoNotReplaceSelection({}));
  };

  const props = {
    options: TURN_IN_OPTIONS,
    section,
    defaultOption: turnInSelection.selectedOption || '',
    handleOptions,
    handleComment,
    comment: turnInSelection.comment,
  };

  const handleActivities = (data) => {
    const activities = data.referralRequestActivities;
    if (activities?.length) {
      dispatch(
        setVehicleReferralActivities(
          activities.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
          ),
        ),
      );
    }
  };

  const handleSave = () => {
    createReferralRequest({
      variables: {
        input: {
          referralRequestId,
          assetId: '',
          referralAction: currentReferralAction,
          referralActionReason: turnInSelection?.selectedOption?.value,
          referralAdditionalInfo: turnInSelection?.comment,
          referralStatus: VEHICLE_REFERRAL_STATUS.DRAFT,
        },
      },
    }).then(({ data }) => {
      handleActivities(data);
      dispatch(setCurrentStatusForVehicle(data?.referralRequestStatusCode))
    });
  };

  const handleSubmit = () => {
    const agencyCode = vehicleDetails?.customer?.customerAgency?.id;
    const bureauCode = vehicleDetails?.customer?.customerBureau?.id;
    const officeCode =
      vehicleDetails?.customer?.customerPhysicalOffice?.officeCode;
    createReferralRequest({
      variables: {
        input: {
          referralRequestId,
          assetId: '',
          referralAction: currentReferralAction,
          referralActionReason: turnInSelection?.selectedOption?.value,
          referralAdditionalInfo: turnInSelection?.comment,
          referralStatus: VEHICLE_REFERRAL_STATUS.SUBMIT,
          ...(agencyCode && { agencyCode }),
          ...(bureauCode && { bureauCode }),
          ...(officeCode && { officeCode }),
        },
      },
    }).then(({ data }) => {
      handleActivities(data);
      backToList({
        submitted: true,
        tagNumber: vehicleDetails?.tagNumber,
        status: data?.referralRequestStatusCode,
      });
    });
  };

  const returnRequest = async ({ comment }) => {
    await returnReferralRequest({
      variables: {
        referralRequestId,
        returnReason: comment,
        reOpen: isReOpen,
      },
    });
    closeModal();
    backToList({
      submitted: true,
      tagNumber: vehicleDetails?.tagNumber,
      status: isReOpen
        ? VEHICLE_REFERRAL_STATUS.RE_OPENED
        : VEHICLE_REFERRAL_STATUS.RETURNED,
    });
  };

  if (loading) {
    return <Spinner size="large" />;
  }

  return (
    <>
      {isEditable ? (
        <OptionsRadioButton {...props} />
      ) : (
        <ReviewSelectedAction
          selectedInfo={turnInSelection}
          status={currentReferralAction}
        />
      )}

      <VehicleReferralButtons
        saveButton={{
          onClick: handleSave,
        }}
        submitButton={{
          onClick: handleSubmit,
          label: submitLabel,
        }}
        returnToCustomer={{
          onClick: openModal,
        }}
        disable={[!turnInSelection?.selectedOption && 'submit']}
        hideButtons={hideButtons}
        isReOpen={isReOpen}
      />

      <ReturnModal
        isOpen={isOpen}
        handleCancel={closeModal}
        tagNumber={vehicleDetails?.tagNumber}
        handleReturn={returnRequest}
        isReOpen={isReOpen}
      />
    </>
  );
}
