import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FilterPanel } from '@gsa/afp-component-library';
import useMyOrdersState from '../useMyOrdersState';
import { useOrderFilter } from '../providers/OrderFilterProvider';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  OrderStatusFilters,
  formatOrderStatusName,
  OrderStatus,
  formatRequisitionTypeLabel,
} from './OrderFilterHelpers';
import AgencyFilterItem from './AgencyFilterItem.jsx';
import BureauFilterItem from './BureauFilterItem.jsx';
import OfficeFilterItem from './OfficeFilterItem.jsx';
import LeasingAgencyFilterItem from './LeasingAgencyFilterItem.jsx'
import LeasingBureauFilterItem from './LeasingBureauFilterItem.jsx'
import LeasingOfficeFilterItem from './LeasingOfficeFilterItem.jsx'
import ZonesFilterItem from './ZonesFilterItem.jsx'
import FMCFilterItem from './FMCFilterItem.jsx'
import {
  AGENCY_AND_BUREAU_AND_OFFICE,
  AGENCY_FILTER,
  BUREAU_FILTER,
  OFFICE_FILTER,
  LEASING_AGENCY_FILTER,
  LEASING_BUREAU_FILTER,
  LEASING_OFFICE_FILTER,
  LEASING_AGENCY_BUREAU_AND_OFFICE,
  ZONE_AND_FMC,
  ZONE,
  FMC
} from './filter-item-constants';
import { USDateStrToUTC } from '../../../utilities/commonUtils';
import { requisitionTypeDescriptor } from '../../non-standard-purchase/constants';
import { UserTypes } from '../../../constants';
import './OrdersFilters.scss';
import {orderInProgress} from "../../../reducers/order.jsx";

const MIN_VALUE = {
  caseNumber: 4,
  vin: 10,
};

const OrdersFilters = () => {
  const { currentUser } = useCurrentUser();
  const internalUser = currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE;
  const allProgramTypes = Object.entries(requisitionTypeDescriptor).filter(
    (x) => x[0] !== '32', // No Order on behalf...
  );
  const dispatch = useDispatch();

  const lastFilters = useRef();
  const initialFilterStructure = [
    {
      title: 'Order number',
      key: 'case_number',
      type: 'text',
      label: 'Order number',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 4,
    },
    {
      title: 'Vehicle identification number',
      key: 'vin',
      type: 'text',
      label: 'VIN',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 10,
    },
    {
      title: 'Order status',
      key: 'order_status',
      id: 'filter-order-status',
      type: 'multiselect',
      value: [OrderStatus.AWARDED, OrderStatus.IN_PROCESS],
      options: OrderStatusFilters.map((status) => ({
        value: status,
        label: formatOrderStatusName(status),
      })),
      expanded: true,
      operator: '$in',
      hideClear: true,
    },
    {
      title: 'Agency',
      key: AGENCY_AND_BUREAU_AND_OFFICE,
      filters: [
        {
          key: AGENCY_FILTER,
          title: 'Agency Item',
          component: AgencyFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
        },
        {
          key: BUREAU_FILTER,
          title: 'Bureau Item',
          component: BureauFilterItem,
          permanent: false,
          operator: '$in',
          placeholder: '- Select bureau -',
        },
        {
          key: OFFICE_FILTER,
          title: 'Group Item',
          component: OfficeFilterItem,
          permanent: false,
          operator: '$in',
          value: '',
          placeholder: '- Select group -',
        },
      ],
    },
    {
      title: 'Date placed',
      key: 'submitted_to_vendor_at',
      id: 'filter-order-by-date',
      type: 'daterange',
      operator: '$between',
      options: {
        startDate: {
          key: 'beginning',
          label: 'Start Date',
          hint: 'mm/dd/yyy',
          maxDate: new Date().toISOString(),
        },
        endDate: {
          key: 'end',
          label: 'End Date',
          hint: 'mm/dd/yyy',
          maxDate: new Date().toISOString(),
        },
      },
      value: {
        beginning: '',
        end: '',
      },
      hideClear: true,
    },
    {
      title: 'Requisition number',
      key: 'requisition_number',
      type: 'text',
      label: 'Requisition number',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 5,
    },
    {
      title: 'Agency order number',
      key: 'customer_assigned_number',
      type: 'text',
      label: 'Agency order number',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 4,
    },
    {
      title: 'Program type',
      key: 'requisition_type',
      id: 'filter-program-type',
      type: 'multiselect',
      value: [],
      options: allProgramTypes.map((type) => ({
        label: formatRequisitionTypeLabel(type[1]),
        value: type[0],
      })),
      expanded: false,
      operator: '$in',
      hideClear: true,
    }
  ];

  const leasingFilters = [
    {
      title: 'Leasing Details',
      type: 'label',
    },
    {
      title: 'Zone and FMC',
      key: ZONE_AND_FMC,
      filters: [
        {
          key: ZONE,
          label: 'Zone',
          component: ZonesFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
          placeholder: '- Select -',
        },
        {
          key: FMC,
          label: 'FMC Name',
          component: FMCFilterItem,
          permanent: false,
          operator: '$exact',
          placeholder: '- Select -',
        },
      ],
    },
    {
      title: 'FSR Email',
      key: 'fsr_user_email_address',
      type: 'text',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 4,
      showSearchButton: true,
      permanent: false,
      position: 'top',
    },
    {
      title: 'Customer BOAC',
      key: 'boac',
      type: 'text',
      value: '',
      operator: '$like',
      hideClear: false,
      minSearchLength: 4,
      showSearchButton: true,
      permanent: false,
      position: 'top',
    },
    {
      title: 'Leasing Organization',
      key: LEASING_AGENCY_BUREAU_AND_OFFICE,
      filters: [
        {
          key: LEASING_AGENCY_FILTER,
          title: 'Leasing Agency',
          component: LeasingAgencyFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
          placeholder: '- Select agency-',
        },
        {
          key: LEASING_BUREAU_FILTER,
          title: 'Leasing bureau',
          component: LeasingBureauFilterItem,
          permanent: false,
          operator: '$in',
          placeholder: '- Select bureau -',
        },
        {
          key: LEASING_OFFICE_FILTER,
          title: 'Leasing office',
          component: LeasingOfficeFilterItem,
          permanent: false,
          operator: '$in',
          value: '',
          placeholder: '- Select group-',
        },
      ],
    },
  ];

  const { allVehicleTypes, setPaginationState } = useMyOrdersState();

  const { setFilters } = useOrderFilter();

  const [filterStructure, setFilterStructure] = useState(
    internalUser ? [...initialFilterStructure, ...leasingFilters] : initialFilterStructure
  );
  const handleFilters = (filters) => {
    const filtersToApply = [];
    dispatch(orderInProgress(true));
    Object.keys(filters).forEach((key) => {
      const valueObj = filters[key];
      const selectedKey = valueObj?.filter?.key;
      switch (selectedKey) {
        case 'submitted_to_vendor_at':
          // eslint-disable-next-line no-case-declarations
          const startDate = USDateStrToUTC(valueObj?.filter.value?.beginning);
          // eslint-disable-next-line no-case-declarations
          const endDate = USDateStrToUTC(valueObj?.filter.value?.end, true);
          if (startDate && endDate) {
            filtersToApply.push(
              { key: 'submitted_to_vendor_at', operator: '$gte', value: startDate },
              { key: 'submitted_to_vendor_at', operator: '$lte', value: endDate },
            );
          }
          break;
        default:
          filtersToApply.push({
            key: selectedKey,
            operator: valueObj.filter.operator,
            value: valueObj.filter.value,
          });
          break;
      }
    });

    const filteredMinFilters = filtersToApply?.filter((fil) => {
      if (Object.keys(MIN_VALUE).includes(fil.key)) {
        if (fil?.value?.length >= MIN_VALUE[fil.key]) {
          return fil;
        }
      } else {
        return fil;
      }
      return null;
    });

    if (lastFilters.current === JSON.stringify(filteredMinFilters)) {
      return;
    }

    lastFilters.current = JSON.stringify(filteredMinFilters);
    setFilters(filteredMinFilters.length > 0 ? filteredMinFilters : []);
    setPaginationState({
      limit: 10,
      offset: 0,
      currentPage: 1,
      isReset: false,
    });
  };
  const addOptions = (id, options) => {
    return filterStructure.map((f) => {
      const filter = f;
      if (filter.id === id) {
        filter.options = [...options]; // no default option for multiselect
      }
      return filter;
    });
  };

  useEffect(() => {
    setFilterStructure(
      addOptions(
        'filter-vehicle-types',
        allVehicleTypes.map((type) => ({
          value: type?.standardItemSubCategoryCode,
          label: type?.standardItemSubCategoryName,
        })),
      ),
    );
  }, [allVehicleTypes]);
  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={() => {}}
        clearButtonLabel="Reset All"
        showClearIcon
        showSearchIcon
        updateAppliedFilters={(filters) => {
          handleFilters(filters);
        }}
      />
    );
  }
  return null;
};

export default OrdersFilters;
