import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_BUREAUS_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';
import {
  LEASING_AGENCY_FILTER,
  LEASING_BUREAU_FILTER,
} from './filter-item-constants';

const placeholderOption = {
  value: '',
  label: '-Select-',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const LeasingBureauFilterItem = ({
  filter,
  subject,
  operation,
  agencyFilterPath,
  bureauFilterPath,
  type = 'multiselectdropdown',
}) => {
  const isSelectDropdown = type === 'select';
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState();
  const [options, setOptions] = useState(
    isSelectDropdown ? [placeholderOption] : [],
  );
  const [isAgencyCodeRetained, setIsAgencyCodeRetained] = useState(true);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getBureaus, { data: bureauOptions, loading: bureauOptionsLoading }] =
    useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    const agencyFilter = agencyFilterPath
      ? getFilterFromState(agencyFilterPath)
      : getFilterFromState(LEASING_AGENCY_FILTER) || {};
    setAgencyCode(agencyFilter.value);
    setIsAgencyCodeRetained(agencyFilter.retained);
    const bureauFilter = bureauFilterPath
      ? getFilterFromState(bureauFilterPath)
      : getFilterFromState(LEASING_BUREAU_FILTER) || {};

    setBureauCode(bureauFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    if (!isAgencyCodeRetained) {
      clearOneFilter(filter);
      setOptions(isSelectDropdown ? [placeholderOption] : []);
    }

    if (agencyCode) {
      getBureaus({
        variables: {
          agencyCode,
          operation: operation || 'view',
          subject: subject || StoreSubjects.Order,
          order: [['bureauCode', 'ASC']],
        },
      });
    }
  }, [agencyCode, isAgencyCodeRetained]);

  useEffect(() => {
    if (!bureauOptions?.getBureausByPermission) {
      return;
    }

    const formattedBureauOptions = bureauOptions.getBureausByPermission.map(
      (bureauDetails) => ({
        value: bureauDetails.id,
        label: bureauDetails.name,
      }),
    );
    setOptions(
      isSelectDropdown
        ? [placeholderOption, ...formattedBureauOptions]
        : formattedBureauOptions,
    );
  }, [bureauOptions]);

  if (bureauOptionsLoading) {
    return <Spinner />;
  }

  return (
    <div id="leasing-bureau-filter-item" className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: type,
          options,
          value: bureauCode || [],
        }}
      />
    </div>
  );
};

LeasingBureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LeasingBureauFilterItem;
