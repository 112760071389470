export const emDashUnicode = '\u2014';

export const StoreSubjects = {
  All: 'all',
  Requisition: 'Requisition',
  Order: 'Order',
  OrderVehicle: 'OrderVehicle',
  OrderLine: 'OrderLine',
  OrderActivityComment: 'OrderActivityComment',
  OrderActivityAttachment: 'OrderActivityAttachment',
  OrderActivityCustomerAttachment: 'OrderActivityCustomerAttachment',
  OrderActivityEvent: 'OrderActivityEvent',
  OrderActivityVendorAttachment: 'OrderActivityVendorAttachment',
  OrderActivityVendorComment: 'OrderActivityVendorComment',
  OrderStatusSubject: 'OrderStatusSubject',
  RequisitionActivityComment: 'RequisitionActivityComment',
  RequisitionActivityEvent: 'RequisitionActivityEvent',
  Solicitation: 'Solicitation',
  VehicleStatus: 'VehicleStatus',
  BidReport: 'BidReport',
  Vendor: 'Vendor',
  StandardItem: 'StandardItem',
  EquipmentCodes: 'EquipmentCodes',
  CatalogCodes: 'CatalogCodes',
  FederalVehicleStandards: 'FederalVehicleStandards',
  Requirements: 'Requirements',
  PSOQuestionnaire: 'PSOQuestionnaire',
  FVSComments: 'FVSComments',
  MakeModel: 'MakeModel',
  VendorAssociatedLocation: 'VendorAssociatedLocation',
  Rental: 'Rental',
  Lease: 'Lease',
  LeasingRequisition: 'LeasingRequisition',
  LeasingNonStdReq: 'LeasingNonStdReq',
  OrderModificationOptions: 'OrderModificationOptions',
  VehicleReferral: 'VehicleReferral',
};

export const VMSSubjects = {
  VEHICLE: 'Vehicle',
};

export const VMSOperations = {
  VIEW_VEHICLE_GF: 'viewGF',
};

export const StoreOperations = {
  View: 'view',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Cancel: 'cancel',
  Approve: 'approve',
  Manage: 'manage',
  EngineerReview: 'engineerReview',
  updateStatus: 'updateStatus',
  updateReceiptDate: 'updateReceiptDate',
  onBehalfOrder: 'onBehalfOrder',
};

export const IN_REQ_TAG = 'IN_REQ';
export const PAINT_OPT = 'PAINT_OPT';
export const ENGINEER_TAG = 'REVIEWREQ';

export const TagsToWatch = [ENGINEER_TAG];
export const VEHICLE_GROUP_TAG_STORE = 'STORE';

export const RequisitionTransactionType = {
  VEHICLE_SALE: 'VEHICLE_SALE',
  VEHICLE_LEASE: 'VEHICLE_LEASE',
};

export const RequisitionType = Object.freeze({
  STANDARD_ORDER: 1,
  AREQ: 2,
  URGENT_REQUIREMENT: 4,
  NON_STANDARD_VEHICLE: 8,
  MULTIPLE_AWARD_SCHEDULES: 16,
  ORDER_ON_BEHALF: 32,
});

export const ORDER_TELEMATICS = {
  EXEMPT: 'EXEMPT',
  OEM_DEVICE_AVAILABLE: 'OEM_DEVICE_AVAILABLE',
  ORDERED: 'ORDERED',
  INELIGIBLE: 'INELIGIBLE',
};

export const OrderTelematicsStatus = {
  EXEMPT: 'Exempt',
  ORDERED: 'Ordered',
  OEM_DEVICE_AVAILABLE: 'OEM device available',
  INELIGIBLE: 'Not eligible',
};

export const UserType = {
  Customer: '1',
  Vendor: '2',
  GSA_Employee: '3',
  Public: '4',
}