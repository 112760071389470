import {useTitle} from "@gsa/afp-shared-ui-utils";
import { useModal, connectModal, Modal, Button } from '@gsa/afp-component-library';
import {useDispatch, useSelector} from "react-redux";
import useLeasingNavigation from "@/hooks/useLeasingNavigation.jsx";
import LeasingNavigation from "../../components/navigation.jsx";
import ShipmentOptions from "./shipment-options/shipment-options.jsx";
import AvailableVehicles from "./available-vehicles/available-vehicles.jsx";
import {setClearVehicleTypeSelection} from "@/reducers/leasing.jsx";
import React from "react";
import './destination-and-vehicle-types.scss';
import { LeasingSteps } from "../../leasing-consts.js";

const PreviousModal = connectModal(({confirmNavigation, handleClose}) => {
    return (
        <Modal
            data-testid="go-to-previous-modal"
            title={<div className="modal-title">Confirm  action</div>}
            onClose={() => {
                handleClose();
            }}
            actions={
                <div className="areq-print-preview-button-row">
                    <Button
                        type="button"
                        variant="unstyled"
                        data-testid="go-to-previous-modal-cancel-button"
                        onClick={handleClose}
                        label="Cancel"
                    />
                    <Button
                        type="submit"
                        variant="primary"
                        data-testid="go-to-previous-modal-confirm-button"
                        label="Confirm"
                        onClick={confirmNavigation}
                    />
                </div>
            }
        >
            <div className="modal-instructions">
                Are you sure you want to go back to the previous step? Doing so would clear the the current vehicle selection
            </div>
        </Modal>
    );
})


const DestinationAndVehicleTypes = () => {
    useTitle('Select Destination and Vehicle Type | Leasing');
    const { handleLeasingNavigation } = useLeasingNavigation();
    const dispatch = useDispatch();
    const {showAvailableVehicles, referralReplacement, selectedStandardItem} = useSelector(state => state.leasingReducer);
    const displayGoToPreviousConfirmModal = useModal();
    const referralReplacementId = referralReplacement?.referralRequestId;
    const hideButtons = ['submit', 'reject']

  const handlePrevious = () => {
    displayGoToPreviousConfirmModal.openModal();
  }

  const confirmNavigation = () => {
      dispatch(setClearVehicleTypeSelection());
      handleLeasingNavigation(LeasingSteps.REPLACEMENT_VEHICLES);
  }

  const handleNext = () => {
    handleLeasingNavigation(LeasingSteps.COMPARE_AND_SELECT);
  };

  if(!referralReplacementId && !selectedStandardItem?.id){
     hideButtons.push('next');
  }
  
  return (
    <div className='destination-and-vehicle-types'>
        <div>
        <ShipmentOptions />
            {
                showAvailableVehicles && (
                    <AvailableVehicles />
                )
            }
            <PreviousModal
                isOpen={displayGoToPreviousConfirmModal.isOpen}
                handleClose={displayGoToPreviousConfirmModal.closeModal}
                confirmNavigation={confirmNavigation}
            />
        </div>
        <LeasingNavigation
            hide={hideButtons}
            onNext={handleNext}
            onPrevious={handlePrevious}
        />
    </div>
  );
}

export default DestinationAndVehicleTypes;