import { useSelector } from "react-redux";
import RequisitionLayout from "../../components/requisition-layout/requisition-layout";
import VehicleInformation from "../../components/vehicle-information/vehicle-information";
import ColorSelection from "./color-selection/color-selection";

export default function PaintAndGraphics() {
    const {
        selectedStandardItem,
        vehicleQuantity,
        compareSelect: {
            addOptions,
            selectedContract,
            calculatedPriceBreakdown,
            selectedEngine,
        }
    } = useSelector((state) => state.leasingReducer);  

    return (
        <RequisitionLayout
            left={
                <VehicleInformation
                    contract={selectedContract}
                    options={addOptions}
                    quantity={vehicleQuantity}
                    standardItem={selectedStandardItem}
                    selectedContractCostBreakdown={calculatedPriceBreakdown?.find(modelCost => modelCost.contractLineId === selectedContract?.contractLineId)}
                    engine={selectedEngine}
                />
            }

            right={
                <ColorSelection />
            }
        />
    )
}