import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ReferralReplacementLink() {
    const { account, referralReplacement } = useSelector(state => state.leasingReducer);

    if (!referralReplacement?.referralRequestId) return null;

    return (
        <Link
            to={`/lease/vehicle-referral/${account.id}`}
            className='usa-button usa-button--primary usa-button--medium'
            style={{ display: 'inline-flex', alignItems: 'center', gap: '12px', paddingTop: '10px', paddingBottom: '12px'}}
            target="_blank"
            rel="noopener noreferrer"
        >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.53903 6.0638C7.53903 6.91374 6.85001 7.60276 6.00006 7.60276C5.15012 7.60276 4.4611 6.91374 4.4611 6.0638C4.4611 5.21385 5.15012 4.52484 6.00006 4.52484C6.85001 4.52484 7.53903 5.21385 7.53903 6.0638Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.8536 5.71214C10.7379 3.53532 8.52923 2.0625 6.0002 2.0625C3.47116 2.0625 1.26183 3.53635 0.146778 5.71235C0.0513344 5.90115 0.0513344 6.12407 0.146778 6.31287C1.26245 8.4897 3.47116 9.96251 6.0002 9.96251C8.52923 9.96251 10.7386 8.48867 11.8536 6.31267C11.9491 6.12387 11.9491 5.90094 11.8536 5.71214ZM6.00001 8.83393C7.57241 8.83393 8.84709 7.55925 8.84709 5.98685C8.84709 4.41445 7.57241 3.13977 6.00001 3.13977C4.42761 3.13977 3.15293 4.41445 3.15293 5.98685C3.15293 7.55925 4.42761 8.83393 6.00001 8.83393Z"
                    fill="white" />
            </svg>
            
            <span>
                View customer request record
            </span>
        </Link>
    )
}