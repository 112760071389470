import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';

import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_ZONES_CENTERS } from '../../../services/data-layer';
import { ZONE } from './filter-item-constants';

const placeholderOption = {
  value: '',
  label: '- Select -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const FMCFilterItem = ({ filter }) => {
  const [zoneValue, setZoneValue] = useState();
  const [fmcWithZoneAndRegion, setFmcWithZoneAndRegion] = useState({});
  const [options, setOptions] = useState([]);
  const [fmcValue, setFmcValue] = useState('');
  const [isZoneRetained, setIsZoneRetained] = useState(true);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getZones, { loading: fmcLoading}] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const fmcOptions = {};
        responseData?.getZones.forEach((zone) =>
          zone.centers.forEach((center) => {
            const option = {
              value: center.id,
              label: center.name,
            };
            if (!fmcOptions[zone.id]) fmcOptions[zone.id] = [];
            fmcOptions[zone.id].push(option);
            fmcOptions[zone.id].sort((a, b) => a.label.localeCompare(b.label));
          }),
        );
        setFmcWithZoneAndRegion(fmcOptions);
        if (zoneValue) {
          setOptions([placeholderOption, ...fmcOptions[zoneValue]]);
        }
      }
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  useEffect(() => {
    const zoneFilter = getFilterFromState(ZONE) || {};
    setZoneValue(zoneFilter.value);
    setFmcValue(filter.value);
    setIsZoneRetained(zoneFilter.retained);
  }, [mergedFilters]);

  useEffect(() => {
    if (!isZoneRetained) {
      clearOneFilter(filter);
      setOptions([]);
    }
    if (zoneValue && !fmcWithZoneAndRegion) {
      setOptions([placeholderOption, ...fmcWithZoneAndRegion[zoneValue]]);
    } else {
      setOptions([placeholderOption]);
      getZones();
    }
  }, [zoneValue, isZoneRetained]);

  if (fmcLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
        value: fmcValue || [],
      }}
    />
  );
};

FMCFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default FMCFilterItem;
