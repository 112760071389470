import { Button, connectModal, Modal, Alert } from '@gsa/afp-component-library';

export default connectModal(function DeleteDocumentationModal({
  isOpen,
  handleClose,
  handleDelete,
  selectedFile,
  deleting,
  error,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      handleClose={handleClose}
      title={
        <h2 style={{ fontSize: '24px', marginBottom: '17px' }}>Delete file</h2>
      }
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={handleClose}
            label="Cancel"
            disabled={deleting}
          />
          <Button
            type="button"
            variant="secondary"
            onClick={handleDelete}
            label="Delete file"
            disabled={deleting}
          />
        </>
      }
    >
      {error && (
        <Alert className="margin-bottom-2" type="error">
          {error}
        </Alert>
      )}

      <p style={{ fontSize: '16px' }}>
        Are you sure you want to delete <strong>{selectedFile.name}</strong>?
        This action cannot be undone.
      </p>
    </Modal>
  );
});
