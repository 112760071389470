import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_AGENCIES_BY_PERMISSION } from '../../../services/data-layer';
import { StoreSubjects } from '../../../constants/constants';

const placeholderOption = {
  value: '',
  label: '- Select agency -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const AgencyFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);

  const [
    fetchAgencyFilterOptions,
    { data: agencyOptions, loading: agencyOptionsLoading },
  ] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAgencyFilterOptions({
      variables: {
        operation: 'view',
        subject: StoreSubjects.Order,
        order: [['agencycode', 'ASC']],
      },
    });
  }, []);

  useEffect(() => {
    if (agencyOptions?.getAgenciesByPermission) {
      const formattedAgencyOptions = agencyOptions.getAgenciesByPermission.map(
        (agencyDetails) => ({
          value: agencyDetails.id,
          label: agencyDetails.name,
        }),
      );
      setOptions(
        formattedAgencyOptions
          ? [placeholderOption, ...formattedAgencyOptions]
          : [placeholderOption],
      );
    }
  }, [agencyOptions]);

  if (agencyOptionsLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

AgencyFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default AgencyFilterItem;
