import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  TextInput,
  ErrorMessage,
  SelectDropdown,
  Label,
  Spinner,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import {
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_LABELS,
  DOCUMENTATION_OPTIONS,
} from '../../constants';
import { toBeRounded } from './UrgReqCommonUtils';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import {
  FETCH_SIGNED_URL_REQUISITIONS,
  UPDATE_ATTACHMENT_DESCRIPTION,
} from '../../../../services/data-layer';
import './UrgentReqFileUploadModal.scss';
import {validateUploadedFile} from "../../../../utilities/commonUtils.jsx";

const UrgentReqUploadModal = ({ handleClose, selectedFile }) => {
  const { dispatch, state, updateDraftRequisition } = useContext(
    VehicleRequisitionContext,
  );
  const { urgentReqJustification, draftRequisition } = state;
  const [fileAddErrors, setFileAddErrors] = useState(null);
  const [file, setFile] = useState();
  const [documentCategory, setDocumentCategory] = useState('-select-');
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [note, setNote] = useState('');
  const [selectedFileState, setSelectedFileState] = useState(selectedFile);
  const uploadFiles = urgentReqJustification?.uploadedDocumentation;
  const [returnSignedUrl] = useMutation(FETCH_SIGNED_URL_REQUISITIONS);
  const [updateAttachmentDescription] = useMutation(
    UPDATE_ATTACHMENT_DESCRIPTION,
  );
  const [saving, setSaving] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);

  const handleFileChange = (e) => {
    const {fileError, isValidFormat} = validateUploadedFile(e);
    setFileAddErrors({
      ...fileAddErrors,
      file: fileError
    });
    setDisableUpload(!isValidFormat);
    setFile(e);
    setSelectedFileState({});
  };

  useEffect(() => {
    if (file && documentCategory !== '-select-') {
      setIsFileAttached(true);
    } else {
      setIsFileAttached(false);
    }
  }, [documentCategory, file]);

  useEffect(() => {
    if (selectedFile?.filename) {
      setFile(selectedFile?.file);
      setDocumentCategory(selectedFile?.document);
      setNote(selectedFile?.note);
    }
  }, [selectedFile?.filename]);

  useEffect(() => {
    const foundAttachment =
      selectedFileState.id &&
      uploadFiles?.find((li) => selectedFileState.id === li.id);
    if (foundAttachment) {
      setFile(foundAttachment.file);
      setDocumentCategory(foundAttachment.document);
      setNote(foundAttachment.note);
    }
  }, [selectedFileState, uploadFiles]);

  const handleSave = async () => {
    setSaving(true);
    const { appURLs } = window.AFP_CONFIG;
    const justificationState = urgentReqJustification;
    let returnSignedUrlData = {};
    if (selectedFileState.filename) {
      const selectedFileIndex = uploadFiles?.findIndex(
        ({ metadataId }) => selectedFile?.metadataId === metadataId,
      );
      if (selectedFileIndex !== -1) {
        const updatedSelectedFile = { ...uploadFiles[selectedFileIndex] };
        updatedSelectedFile.note = note;
        updatedSelectedFile.document = documentCategory;
        uploadFiles.splice(selectedFileIndex, 1, updatedSelectedFile);
      }

      justificationState.uploadedDocumentation = [...uploadFiles];
      if (selectedFileState?.attachmentId) {
        updateAttachmentDescription({
          variables: {
            attachmentId: selectedFileState?.attachmentId,
            description: note,
          },
        });
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
        payload: justificationState,
      });
      updateDraftRequisition(draftRequisition?.requisitionId);
    } else {
      const arr = uploadFiles ? [...uploadFiles] : [];
      try {
        returnSignedUrlData = await returnSignedUrl({
          variables: {
            input: {
              requisitionId: draftRequisition.requisitionId,
              name: file.name,
              status: 0,
              attachmentTypeId: '5936fe9e-be86-4cf4-8fed-18b81d64ee9b',
              description: note,
              docStoreUri: appURLs.store,
              size: toBeRounded(file.size),
              fileMimeType: 'application/pdf',
            },
          },
        });

        await axios.put(
          returnSignedUrlData?.data?.generateSignedUrl?.signedUrl,
          file,
          {
            headers: {
              'Content-Type': file.type,
            },
          },
        );
      } catch {
        // console.log(e);
        setSaving(false);
      }

      arr.push({
        id: arr.length,
        filename: file?.name,
        document: documentCategory,
        metadataId: returnSignedUrlData?.data?.generateSignedUrl?.metadataId,
        signedUrl: returnSignedUrlData?.data?.generateSignedUrl?.signedUrl,
        note,
        file,
      });
      justificationState.uploadedDocumentation = arr;
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URG_REQ_JUSTIFICATION,
      payload: justificationState,
    });
    handleClose();
    setSaving(false);
  };

  if (saving) {
    return <Spinner size="large" className="margin-y-8" />;
  }

  return (
    <div className="modalContainer non-sop-modal">
      <Modal
        title={<h2>Upload new file</h2>}
        onClose={handleClose}
        className="urg-req-attachment-modal"
        variant="large"
        actions={
          <div>
            <Button
              type="button"
              variant="unstyled"
              data-testid="urg-req-upload-cancel-button"
              onClick={handleClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="primary"
              disabled={!isFileAttached || disableUpload}
              data-testid="urg-req-upload-submit-button"
              onClick={() => handleSave()}
              label="Save"
            />
          </div>
        }
      >
        <div className="urg-req-upload">
          {selectedFileState.filename ? (
            <div
              role="button"
              aria-label="Selected file"
              tabIndex="0"
              className="urg-req-edit-uploaded-file"
            >
              <FileUpload
                acceptableFiles={ACCEPT_FILE_TYPES}
                data-testid="urg-req-attachment-upload-file-edit"
                acceptableFilesLabel={ACCEPT_FILE_LABELS}
                defaultValue={{ name: selectedFileState.filename }}
                onChange={(e) => handleFileChange(e)}
              />
            </div>
          ) : (
            <div role="button" tabIndex="0">
              <FileUpload
                acceptableFiles={ACCEPT_FILE_TYPES}
                data-testid="urg-req-attachment-upload-file"
                acceptableFilesLabel={ACCEPT_FILE_LABELS}
                aria-label="Selected file"
                onChange={(e) => handleFileChange(e)}
              />
            </div>
          )}
          {fileAddErrors?.file && (
            <ErrorMessage id="file-error">{fileAddErrors?.file}</ErrorMessage>
          )}

          <div className="top-padding">
            <SelectDropdown
              data-testid="document-category-select"
              name="documentCategory"
              label="Document Category"
              required
              value={documentCategory}
              onChange={(e) => {
                setDocumentCategory(e.target.value);
              }}
              options={DOCUMENTATION_OPTIONS}
            />
          </div>

          <Alert type="warning" className="alert-msg">
            Help prevent a privacy incident by ensuring that any supporting
            document uploaded here does not contain personally identifiable
            information (PII).
          </Alert>

          <div>
            <div className="upload-note">
              <Label>Note</Label>
            </div>
            <TextInput
              type="textarea"
              aria-label="note textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              data-testid="urg-req-attachment-upload-description"
              maxLength={100}
              help="A few words to help identify this document"
              hint={`${100} characters allowed`}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

UrgentReqUploadModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedFile: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
  }).isRequired,
};

export default UrgentReqUploadModal;
