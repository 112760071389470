import { useCreateLeasingDraft } from '@/requests/leasing';
import { useSelector } from 'react-redux';
import {
  RequisitionTransactionType,
  RequisitionType,
} from '@/constants/constants';

export default function useSaveDraft() {
  const [createLeasingDraft, hooks] = useCreateLeasingDraft();
  const { account, selectedVehicles } = useSelector(
    (state) => state.leasingReducer,
  );

  return [
    ({ requisitionName }) =>
      createLeasingDraft({
        variables: {
          createLeasingCustomerAccountInput: {
            customerAccountId: account.customer.customerId,
            customerAccountName: account.customer.accountName,
            agencyCode: account?.customer?.customerAgency?.id,
            bureauCode: account?.customer?.customerBureau?.id,
            vehicleReplacements: selectedVehicles.map((vehicle) => ({
              assetId: vehicle.assetId,
              tagNumber: vehicle.tagNumber,
              serialNumberVin: vehicle.vin,
              standardItemNumber: vehicle.standardItemNumber,
              justification: vehicle.justification,
            })),
          },
          createInput: {
            transactionType: RequisitionTransactionType.VEHICLE_LEASE,
            requisitionType: RequisitionType.STANDARD_ORDER,
            agencyCode: '047',
            bureauCode: '09',
            friendlyName: requisitionName,
            zoneId: account?.fleetManagementCenter?.zoneId,
            fmcId: account?.fleetManagementCenter?.id,
            regionId: account?.customer?.regionId,
            fsrContact: {
              firstName: account?.customer?.fsrUser?.firstName,
              lastName: account?.customer?.fsrUser?.lastName,
              email: account?.customer?.fsrUser?.email,
              phoneNumber: account?.customer?.fsrUser?.phoneNumber,
              phoneCountryCode: account?.customer?.fsrUser?.phoneCountryCode,
            },
            quantity: selectedVehicles.length || 1,
          },
        },
      }),
    hooks,
  ];
}
