import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  FileUpload,
  Modal,
  TextInput,
  ErrorMessage,
  SelectDropdown,
  Spinner,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import { FETCH_SIGNED_URL_REQUISITIONS } from '../../../services/data-layer';
import {
  ACCEPT_FILE_TYPES,
  ACCEPT_FILE_LABELS,
} from '../../../pages/non-standard-purchase/constants';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import './UploadNewFileModal.scss';
import {validateUploadedFile} from "../../../utilities/commonUtils.jsx";

const toBeRounded = (bytes) => {
  const converted = bytes / (1024 * 1024);
  const size = converted.toFixed(2);
  return Number(size);
};

const UploadNewFileModal = ({
  handleClose,
  append,
  acceptableFiles,
  acceptableFilesLabel,
  reqType,
  attachmentTypes,
  draftId,
}) => {
  const [fileAddErrors, setFileAddErrors] = useState(null);
  const [saving, setSaving] = useState(false);
  const [returnSignedUrl] = useMutation(FETCH_SIGNED_URL_REQUISITIONS);
  const [disableUpload, setDisableUpload] = useState(false);

  const [values, setValues] = useState({
    isInternalAttachment: false,
    fileType: -1,
    file: null,
    description: '',
  });

  const updateValue = (e, type) => {
    if (type === 'fileType' && fileAddErrors?.groupAssignment) {
      setFileAddErrors({
        ...fileAddErrors,
        groupAssignment: null,
      });
    }
    const updatedValues = { ...values };
    updatedValues[type] = e.target.value;
    setValues(updatedValues);
  };

  const {
    state: { draftRequisition },
  } = useContext(VehicleRequisitionContext);

  const foundAttachmentType = useMemo(() => {
    if (
      reqType === 'URGENT_REQ_CUSTOMER_SUPPORTING_DOC' &&
      attachmentTypes?.getRequisitionAttachmentTypes?.length
    ) {
      return attachmentTypes.getRequisitionAttachmentTypes.find(
        (a) => a.attachmentType === 'req_vendor_quote',
      );
    }
    return {};
  }, [reqType, attachmentTypes]);

  const handleFileChange = (e) => {
    const {fileError, isValidFormat} = validateUploadedFile(e);
    setFileAddErrors({
        ...fileAddErrors,
        file: fileError
    });
    setDisableUpload(!isValidFormat);
    setValues({ ...values, file: e });
  };

  const handleSubmitAttachmentForm = async () => {
    setSaving(true);
    const { appURLs } = window.AFP_CONFIG;
    let errorList = {};

    try {
      setFileAddErrors(null);

      if (!values.file) {
        errorList = {
          ...errorList,
          file: 'Please add a Supporting file for upload',
        };
      }

      if ((reqType === 'MAS' || reqType === 'AREQ') && values.fileType === -1) {
        errorList = {
          ...errorList,
          groupAssignment: 'Please choose a valid File category',
        };
      }
      if (Object.keys(errorList).length > 0) {
        setFileAddErrors(errorList);
        return;
      }

      const data = {
        variables: {
          input: {
            requisitionId: draftRequisition?.requisitionId || draftId,
            name: values?.file?.name,
            status: 0,
            attachmentTypeId:
              reqType === 'MAS' ? values?.fileType : values?.file?.type,
            docStoreUri: appURLs.store,
            size: toBeRounded(values?.file?.size),
            fileMimeType: values?.file?.type,
            description: values?.description,
          },
        },
      };

      const returnSignedUrlData = await returnSignedUrl(data);

      await axios.put(
        returnSignedUrlData?.data?.generateSignedUrl?.signedUrl,
        values?.file,
        {
          headers: {
            'Content-Type': values?.file.type,
          },
        },
      );
      const fileData = {
        description: values?.description,
        name: values?.file?.name,
        signedUrl: returnSignedUrlData?.data?.generateSignedUrl?.signedUrl,
        metadataId: returnSignedUrlData?.data?.generateSignedUrl?.metadataId,
      };

      if (!foundAttachmentType?.requisitionAttachmentTypeId) {
        fileData.attachmentTypeId = values?.fileType;
      }

      setFileAddErrors(null);
      append({ ...fileData });
      handleClose();
    } catch (e) {
      console.log('ERROR', e);
      errorList = {
        ...errorList,
        fileAddError:
          'Uploading file failed. Please try again or contact support.',
      };
      if (Object.keys(errorList).length > 0) {
        setFileAddErrors(errorList);
      }
      //  throw new Error(e);
    } finally {
      setSaving(false);
    }
  };

  if (saving) {
    return <Spinner size="large" className="margin-y-8" />;
  }

  return (
    <div className="upload-new-additional-req-file">
      <Modal
        title="Upload new file"
        onClose={handleClose}
        className="upload-attachment-modal"
        variant="large"
        actions={
          <div>
            <Button
              type="button"
              variant="unstyled"
              // className="modal-cancel-button"
              data-testid="order-attachment-upload-cancel-button"
              onClick={handleClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="primary"
              // className="modal-close-button"
              disabled={
                !values?.file?.name ||
                (attachmentTypes.length && values?.fileType === -1)
                  || disableUpload
              }
              data-testid="order-attachment-upload-submit-button"
              onClick={handleSubmitAttachmentForm}
              label="Save"
            />
          </div>
        }
      >
        {fileAddErrors?.groupAssignment && (
          <ErrorMessage id="file-error">
            {fileAddErrors?.groupAssignment}
          </ErrorMessage>
        )}
        {attachmentTypes.length > 0 && (
          <div className="requirement-dropdown">
            <div className="note">Select requirement type</div>
            <SelectDropdown
              id="requirementDropdown"
              onChange={(e) => updateValue(e, 'fileType')}
              value={values.fileType}
              label="File category"
              data-testid="requisition-attachment-upload-file-type"
              required
              options={attachmentTypes}
            />
          </div>
        )}
        <FileUpload
          acceptableFiles={acceptableFiles || ACCEPT_FILE_TYPES}
          data-testid="order-attachment-upload-file"
          acceptableFilesLabel={acceptableFilesLabel || ACCEPT_FILE_LABELS}
          onChange={handleFileChange}
          fileSizeLimit={50}
        />
        {fileAddErrors?.file && (
          <ErrorMessage id="file-error">{fileAddErrors?.file}</ErrorMessage>
        )}
        <Alert type="warning" className="alert-msg">
          Help prevent a privacy incident by ensuring that any supporting
          document uploaded here does not contain personally identifiable
          information (PII).
        </Alert>
        {reqType !== 'URGENT_REQ_VENDOR_QUOTES' && (
          <>
            <div className="note">Note</div>
            <TextInput
              type="textarea"
              aria-label="note textarea"
              value={values.description}
              onChange={(e) => updateValue(e, 'description')}
              data-testid="additional-req-attachment-upload-description"
              help="A few words to help identify this document"
              characterLimit={100}
            />
          </>
        )}

        {fileAddErrors?.fileAddError && (
          <ErrorMessage id="file-error">
            {fileAddErrors?.fileAddError}
          </ErrorMessage>
        )}
      </Modal>
    </div>
  );
};

export default UploadNewFileModal;

UploadNewFileModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  acceptableFiles: PropTypes.instanceOf(Object),
  acceptableFilesLabel: PropTypes.string,
  reqType: PropTypes.string,
  attachmentTypes: PropTypes.instanceOf(Array),
  draftId: PropTypes.string,
};

UploadNewFileModal.defaultProps = {
  acceptableFiles: null,
  acceptableFilesLabel: null,
  reqType: null,
  attachmentTypes: [],
  draftId: '',
};
